import { formatNum, getGridNumberFormatter } from "utils/transformUtils";
import { CountryFlag } from "components/CountryFlag/CountryFlag";
import {
	Loop as LoopIcon,
	Calculate as CalculateIcon,
} from '@mui/icons-material'
import { Tooltip } from "@mui/material";
import { TrafficCapColumn } from "components/TrafficCapColumn/TrafficCapColumn";
import { EditButton } from "./editButton";
import { CopyButton } from "./copyButton";
import { getCampaignLink } from "utils/campaignUtils";
import { offerStagesMap } from "utils/autoComplete";
import { CampaignsColumn } from "./campaignsColumn";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";

function secondsToHM(seconds) {
	if (!seconds) {return ''}
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
  
	// Format the hours and minutes to always be two digits
	const formattedHours = String(hours).padStart(2, '0');
	const formattedMinutes = String(minutes).padStart(2, '0');
  
	return `${formattedHours}:${formattedMinutes}`;
}
  
export function getColumns(groupBy1, globalSettings) {
	const allColumns: any[] = [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			global: true, skipDownload: true,
			width: 30,
			minWidth: 30,
		},
		// global start
		{ field: 'profitability', skipDownload: true, global: true, headerName: '', align: 'center', minWidth: 16, width: 16, valueGetter: (_val, row) => row.profit > 0 ? '+' : row.profit < 0 ? '-' : '•'},
		{ field: 'edit', skipDownload: true, global: true, headerName: '', align: 'center', minWidth: 20, width: 20, sortable: false, renderCell: ({row}) => row.id !== '_totals' && <EditButton entity={groupBy1} row={row} />},
		// campaign
		{ field: 'copyUrl', forGroups: ['campaign'], headerName: '', align: 'center', minWidth: 16, width: 16, sortable: false, renderCell: ({row}) => row.campaign && <CopyButton text={getCampaignLink(row.campaign, row.trafficSource, globalSettings)} />},
		// campaign
		{ field: 'campaign', forGroups: ['campaign'], headerName: 'Campaign', minWidth: 180, flex: 1, valueGetter: (_val, row) => row.campaign?.name},
		{ field: 'campaignOrder', forGroups: ['campaign'], headerName: 'Daily', minWidth: 110, valueGetter: (_val, row) => row.campaign?.order},
		// trafficSource
		{ field: 'trafficSource', forGroups: ['trafficSource', 'campaign'], headerName: 'Traffic Source', minWidth: 100, width: 150, valueGetter: (_val, row) => row.trafficSource?.name},
		// offer
		{ field: 'offerName', forGroups: ['offer'], headerName: 'Offer', minWidth: 150, flex: 1},
		{ field: 'offerOrder', forGroups: ['offer'], headerName: 'Daily', minWidth: 110, align: 'right', renderCell: ({row}) => row.offer && <TrafficCapColumn trafficCapLimit={row.offer?.trafficCapLimit} activityStatus={row.offer?.activityStatus} />},
		{ field: 'offerBU', forGroups: ['offer'], headerName: 'V/CL', description: 'Yesterday Visits / Cap Limit', width: 50, align: 'right', valueGetter: (_, row) => {
			if (row.id === '_totals') {return ''}
			if (!row.offer || !row.offer.trafficCapLimit) {return -1};
			return formatNum((row.offer.activityStatus?.yesterday?.visits || 0) / row.offer.trafficCapLimit * 100, 0) + '%'
		}},
		{ field: 'offerDate', forGroups: ['offer'], headerName: 'Date', minWidth: 110, align: 'right', valueFormatter: (value) => value?.slice(0, 10)},
		{ field: 'offerStage', forGroups: ['offer'], headerName: 'Stage', minWidth: 70, width: 70, sortable: false, valueFormatter: (value) => offerStagesMap[value]?.name},
		// { field: 'campaigns', forGroups: ['offer'], headerName: 'Cmp', minWidth: 130, renderCell: ({row}) => (<CampaignsColumn campaigns={row?.campaigns} />)},
		{ field: 'offerCurrentCampaigns', forGroups: ['offer'], headerName: 'Campaign', minWidth: 170, renderCell: ({row}) => (<CampaignsColumn offerId={row.offer?.id} campaigns={row?.offerCurrentCampaigns} />)},
		{ field: 'trafficSources', forGroups: ['offer'], headerName: 'TS', minWidth: 130, valueGetter: (_val, row) => row.trafficSources?.map(ts => ts?.name).join(', ')},
		// network
		{ field: 'networkName', forGroups: ['network', 'offer'], headerName: 'Network', minWidth: 100, valueGetter: (_val, row) => row.networkName},
		// site, offer
		{ field: 'siteName', forGroups: ['site', 'offer'], headerName: 'Site', minWidth: 100, valueGetter: (_val, row) => row.siteName},
		// campaign
		{ field: 'campaignGeo', forGroups: ['campaign'], headerName: 'Geo', minWidth: 60, width: 60, sortable: true, renderCell: ({value}) => (<CountryFlag country={value} />)},
		// offer
		{ field: 'offerGeo', forGroups: ['offer'], headerName: 'Geo', minWidth: 60, width: 60, sortable: false, renderCell: ({value}) => (<CountryFlag country={value} />)},
		// geo
		{ field: 'geo', forGroups: ['geo'], headerName: 'Geo', minWidth: 60, width: 60, sortable: false, renderCell: ({value}) => (<CountryFlag country={value} />)},
		// global end
		{ field: 'roi', global: true, headerName: 'ROI', minWidth: 60, width: 80, ...getGridNumberFormatter({suffix: '%'})},
		{ field: 'visits', global: true, headerName: 'Visits', minWidth: 60, width: 80, ...getGridNumberFormatter()},
		{ field: 'defaultConversions', global: true, headerName: 'Conversions', minWidth: 60, width: 80, ...getGridNumberFormatter()},
		{ field: 'revenue', global: true, headerName: 'Rev', minWidth: 60, width: 80, ...getGridNumberFormatter()},
		{ field: 'cost', global: true, headerName: 'Cost', minWidth: 60, width: 80, ...getGridNumberFormatter()},
		{ field: 'costSources', global: true, headerName: 'Cost Src', minWidth: 80, width: 80, sortable: false, renderCell: ({value}) => {
			return value?.sort().map((cs, i) => {
				switch (cs) {
				case 'auto':
					return <Tooltip key={i} title="Estimated"><CalculateIcon fontSize="small" /></Tooltip>
				case 'sync':
					return <Tooltip key={i} title="Synced from TS"><LoopIcon fontSize="small" /></Tooltip>
				default:
					return cs
				}
			})
		} },
		{ field: 'profit', global: true, headerName: 'Profit', minWidth: 60, width: 80, ...getGridNumberFormatter()},
		{ field: 'cvr', global: true, headerName: 'CVR', minWidth: 60, width: 80, ...getGridNumberFormatter()},
		{ field: 'mttc', global: true, headerName: 'MTTC', description: 'Mean Time To Convert', minWidth: 60, width: 80, valueFormatter: secondsToHM},
		{ field: 'rejectionConversions', global: true, headerName: 'Rejections', minWidth: 60, width: 80, ...getGridNumberFormatter()},
		{ field: 'rejectionRevenue', global: true, headerName: 'Rejections Rev', minWidth: 60, width: 80, ...getGridNumberFormatter()},
	]
	return allColumns.filter(column => column.global || column.forGroups.includes(groupBy1))
}