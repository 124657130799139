import { formatDate, getGridNumberFormatter } from "utils/transformUtils";
import { CountryFlag } from "components/CountryFlag/CountryFlag";
import {
	Loop as LoopIcon,
	Calculate as CalculateIcon,
} from '@mui/icons-material'

export function getColumns() {
	const allColumns: any[] = [
		{ field: 'type', headerName: 'Type', width: 60, minWidth: 60},
		{ field: 'visitDate', headerName: 'Visit Date', width: 150, valueFormatter: (value) => formatDate(value, 'yyyy-MM-dd HH:mm:ss')},
		{ field: 'postbackDate', headerName: 'Postback Date', width: 150, valueFormatter: (value) => formatDate(value, 'yyyy-MM-dd HH:mm:ss')},
		{ field: 'clickId', headerName: 'Click Id', width: 190},
		{ field: 'transactionId', headerName: 'Trx Id', width: 100},
		{ field: 'workspaceId', headerName: 'WS', minWidth: 70, width: 70, valueGetter: (_val, row) => row.workspace?.name},
		{ field: 'campaignId', headerName: 'Campaign', minWidth: 180, valueGetter: (_val, row) => row.campaign?.name},
		{ field: 'offerId', headerName: 'Offer', minWidth: 190, valueGetter: (_val, row) => row.offer?.name},
		{ field: 'siteId', headerName: 'Site', minWidth: 110, valueGetter: (_val, row) => row.site?.name},
		{ field: 'networkId', headerName: 'Network', minWidth: 110, valueGetter: (_val, row) => row.network?.name},
		{ field: 'revenue', headerName: 'Rev', minWidth: 60, width: 80, ...getGridNumberFormatter()},
		{ field: 'originalRevenue', headerName: 'Orig Rev', minWidth: 60, width: 80, ...getGridNumberFormatter()},
		{ field: 'originalRevenueCurrency', headerName: 'Orig Currency', minWidth: 60, width: 80, ...getGridNumberFormatter()},
		{ field: 'outPostbackUrl', headerName: 'Outgoing PB'},
		{ field: 'inPostbackUrl', headerName: 'Incoming PB'},
		{ field: 'geo', headerName: 'Geo', minWidth: 60, width: 60, sortable: false, renderCell: ({value}) => (<CountryFlag country={value} />)},
		{ field: 'v1', headerName: 'V1'},
		{ field: 'v2', headerName: 'V2'},
		{ field: 'v3', headerName: 'V3'},
		{ field: 'v4', headerName: 'V4'},
		{ field: 'v5', headerName: 'V5'},
		{ field: 'v6', headerName: 'V6'},
		{ field: 'v7', headerName: 'V7'},
		{ field: 'v8', headerName: 'V8'},
		{ field: 'v9', headerName: 'V9'},
		{ field: 'v10', headerName: 'V10'},
	]
	return allColumns
}