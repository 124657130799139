import _ from 'lodash';
import {ActionTypes} from './actions';
import { mergeBy, setLoader } from 'utils/reduxUtils';

const initialValue = {
	filter: {showArchived: false},
}

const formatError = (payload) => {
	const errorCode = _.get(payload, 'response.data.key');
	switch (errorCode) {
	default:
		return 'Oops. Request failed';
	}
}
const adsReducer = (state = initialValue, action) => {
	let nextState;
	const {type, payload} = action;
	switch (type) {
	case ActionTypes.FETCH_ADS_PERFORMANCE_REQUEST:
		nextState = {...state, ads: [], ...setLoader(state, 'loading', true)};
		break;
	case ActionTypes.FETCH_ADS_PERFORMANCE_SUCCESS:
		nextState = {...state, ads: payload.items, adsTotal: payload.pagination.total, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.FETCH_ADS_PERFORMANCE_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.AD_FORM_SUBMIT_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false, submitError: null};
		break;
	case ActionTypes.AD_FORM_SUBMIT_SUCCESS:
		nextState = {...state, ...setLoader(state, 'submitting', false), justSubmitted: true, ads: mergeBy(state.ads, payload), selectedAd: payload};
		break;
	case ActionTypes.AD_FORM_SUBMIT_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	default:
		nextState = state;
	}
	return nextState;
};

export default adsReducer;
