import axios from 'services/axios';
import {FetchTrafficSourcesProps, UpsertTrafficSourcePayload} from './api.interface';
import queryString from 'query-string';

export async function fetchTrafficSources({pagination, filter, sortModel, workspaceIds}: FetchTrafficSourcesProps) {
	const pageSize = pagination?.pageSize ?? -1;
	const page = pagination?.page ?? 0;
	const offset = pageSize * page;
	const payload = {query: {trafficSource: {...filter.trafficSource}, search: filter.text}};

	if (workspaceIds?.length) {
		payload.query.trafficSource.workspaceId = {op: 'in', value: workspaceIds};
	}

	const orderBy = sortModel?.length ? `trafficSource.${sortModel[0].field}` : '';
	const order = sortModel?.length ? sortModel[0].sort : '';
	const query = {
		limit: pageSize,
		offset,
		orderBy,
		order,
	}
	const queryStr = queryString.stringify(query);
	const url = `/trafficSources/query?${queryStr}`;
	const resp = await axios.post(url, payload);

	return resp.data;
}

export async function fetchSingleTrafficSource(id: string) {
	const url = `trafficSources/${id}`;
	const {data} = await axios.get(url);   
	return data;
}

export async function upsertTrafficSource({trafficSource}: UpsertTrafficSourcePayload) {
	const method = trafficSource.id ? axios.patch : axios.post;
	const url = trafficSource.id ? `trafficSources/${trafficSource.id}` : `trafficSources`;
	const {data} = await method(url, trafficSource);   
	return data;
}


export async function deleteTrafficSource({id}) {
	const url = `trafficSources/${id}`;
	const {data} = await axios.delete(url);
	return data;
}

export const formatError = (error) => {
	switch (error?.response?.data?.key) {
	case 'nameExists':
		return 'TrafficSource name already exists';
	default:
		return 'Oops. Request failed';
	}
}
