import TooltipButton from "components/TooltipButton/TooltipButton";
import {
	Link as LinkIcon,
} from '@mui/icons-material'

export function CopyButton({text}) {
	if (!text) return null;
	return (
		<TooltipButton
			title={`Copy`}
			size="small"
			icon={LinkIcon}
			onClick={() => navigator.clipboard.writeText(text)}
			iconProps={{sx: {fontSize: 18} }}
		/>
	)
}