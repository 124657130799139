import _ from 'lodash'

// components
import { EnhancedDataGrid } from 'components/DataGrid/EnhancedDataGrid';
import { Box, Paper, Typography } from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import { UseQueryResult } from '@tanstack/react-query';
import { useAvailableWindowHeight } from 'utils/hooks';
import { useState } from 'react';
import { Flex } from 'components/Flex/Flex';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import {
	OpenInFull as OpenInFullIcon,
	CloseFullscreen as CloseFullscreenIcon,
} from '@mui/icons-material';

export interface AlertGridProps<Res, Err> {
	title: string,
	columns: GridColDef[],
	query: UseQueryResult<Res[], Err>
	getRowId: (row: any) => string
}
export function AlertGrid<Res extends GridValidRowModel, Err>({title, columns, query, getRowId}: AlertGridProps<Res, Err>) {
	const [isExpanded, setIsExpanded] = useState(false);

	const availableHeight = useAvailableWindowHeight();
	return (
		<Paper sx={{p: 1}}>
			<Flex justify='space-between'>
				<Typography sx={{fontWeight: 'bold'}}>{title}</Typography>
				<TooltipButton
					size="small"
					iconProps={{sx: {fontSize: 14} }}
					icon={isExpanded ? CloseFullscreenIcon : OpenInFullIcon} title="Toggle expanded"
					onClick={() => setIsExpanded(!isExpanded)}
				/>
			</Flex>
			<Box sx={{height: isExpanded ? availableHeight : 200, mt: 0.5}}>
				<EnhancedDataGrid<Res>
					items={query.data}
					loading={query.isLoading}
					noToolbar
					hideFooter
					pagination={false}
					getRowId={getRowId}
					density='compact'
					pluralName={title}
					columns={columns}
				/>
			</Box>
		</Paper>
	)
}
