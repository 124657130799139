import { EnhancedDataGrid } from "components/DataGrid/EnhancedDataGrid";
import { ResponsivePaper } from "components/ResponsivePaper/ResponsivePaper";
import { fetchTrafficSources } from "modules/trafficSources/api";
import { useWrappedQuery } from "utils/reactQueryHooks";
import { GridColDef } from '@mui/x-data-grid-pro';
import { getGridNumberFormatter } from "utils/transformUtils";
import { Box } from "@mui/material";
import { useState } from "react";

const columns: GridColDef[] = [
	{ field: `name`, headerName: `Traffic Source`, width: 100, flex: 1, type: 'string', headerAlign: 'left'},
	{ field: `balance`, headerName: `Balance`, width: 100, type: 'string', headerAlign: 'left', valueGetter: (_, row) => row.integrationInfo?.balance?.balance, ...getGridNumberFormatter()},
	{ field: `lastUpdate`, headerName: `Updated`, width: 134, type: 'string', headerAlign: 'left', valueGetter: (_, row) => row.integrationInfo?.balance?.lastRun?.slice(0, 16)?.replace('T', ' '), ...getGridNumberFormatter()},
]
const defaultSortModel = [{field: 'balance', sort: 'asc'}] as any
export function TrafficSourceBalancePanel({filter}) {
	const [sortModel, setSortModel] = useState(defaultSortModel)
	const {isFetching, data: resp, refetch} = useWrappedQuery({
		keepPreviousData: true,
		queryKey: ['fetchWorkspaceOverview', filter.workspaces],
		queryFn: () => fetchTrafficSources({filter: {trafficSource: {kind: {op: '!=', value: 'global'}}}, workspaceIds: filter.workspaces}),
		// initialData: {items: [], pagination: {total: 0}, totals: {}}
	});

	return (
		<ResponsivePaper sx={{height: '100%'}}>
			<Box sx={{fontSize: 24}}>Balance</Box>
			<EnhancedDataGrid
				items={resp?.items}
				loading={isFetching}
				pluralName='Traffic Sources'
				density='compact'
				rowHeight={50}
				autoHeight
				columns={columns}
				getRowClassName={({row}: any) => {
					if (['global', 'adsterra'].includes(row.kind)) {return ''}
					if (row.integrationInfo?.balance?.balance < 1000) {return 'lowBalance'}
					if (row.integrationInfo?.balance?.statusCode !== 'ok') {return 'errorBalance'}
					return '';
				}}
				sx={{
					"& .lowBalance, & .errorBalance": {
						backgroundColor: 'rgba(255, 0, 0, 0.1)',
					},
				}}
				hideFooter
				noToolbar
				sortModel={sortModel}
				onSortModelChange={setSortModel}
				pagination={false}
				paginationMode='client'
				slotProps={{
					toolbar: {
						noSearch: true,
					},
				}}
			/>
		</ResponsivePaper>
	)
}