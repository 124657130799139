import {put, takeLatest, call} from 'redux-saga/effects';
import {Actions} from 'modules/charts/actions';
import axios from 'services/axios';
import {ActionTypes as PagesActions} from 'pages/actions';
import _ from 'lodash';
import { toUTC } from 'utils/transformUtils';
import { endOfDay, startOfDay } from 'date-fns';
import {stringify as qsStringify} from 'query-string';


function* fetchEntityChart(action) {
	yield put(Actions.FETCH_ENTITY_CHART_REQUEST());
	try {
		const {filter, id, type} = action.payload;
		const params = {
			dateFrom: toUTC(startOfDay(new Date(filter.dateRange[0]))),
			dateTo: toUTC(endOfDay(new Date(filter.dateRange[1]))),
			groupBy: filter.groupBy,
		}

		const query = qsStringify(params, {arrayFormat: 'bracket', skipEmptyString: true, skipNull: true})
		const {data} = yield call(axios.get, `${type}s/${id}/chart?${query}`);
        
		yield put(Actions.FETCH_ENTITY_CHART_SUCCESS(data));
	} catch (err) {
		console.error(err);
		yield put(Actions.FETCH_ENTITY_CHART_FAILURE(err));
	}
}

const sagas = [
	takeLatest(PagesActions.ENTITY_CHART_FILTERING_CHANGED, fetchEntityChart),
]
export default sagas;
