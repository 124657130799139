import React, { useState } from "react";
import {
	Collapse,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import { Inbox as InboxIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";

// styles
import styles from "./styles";

// components
import Dot from "../Dot";

export default function SidebarLink({
	link,
	icon,
	label,
	children,
	location,
	isSidebarOpened,
	nested,
	type,
}) {

	// local
	var [isOpen, setIsOpen] = useState(false);
	var isLinkActive =
    link &&
    (location.pathname === link || location.pathname.indexOf(link) !== -1);

	if (type === "title")
		return (
			<Typography
				sx={{
					...styles.linkText,
					...styles.sectionTitle,
					...(isSidebarOpened ? styles.linkTextHidden : {}),
				}}
			>
				{label}
			</Typography>
		);

	if (type === "divider") return <Divider sx={styles.divider} />;

	if (!children)
		return (
			<ListItem
				component={link && Link}
				to={link}
				sx={{
					...styles.link,
					...(isLinkActive && !nested ? styles.linkActive : {}),
					...(nested ? styles.linkNested : {}),
					pl: 1,
				}}
			>
				<ListItemIcon
					sx={{
						...styles.linkIcon,
						...(isLinkActive ? styles.linkIconActive : {}),
					}}
				>
					{nested ? <Dot color={isLinkActive && "primary"} /> : icon}
				</ListItemIcon>
				<ListItemText
					sx={{
						...styles.linkText,
						...(isLinkActive ? styles.linkTextActive : {}),
						...(!isSidebarOpened ? styles.linkTextHidden : {}),
					}}
					primary={label}
				/>
			</ListItem>
		);

	return (
		<>
			<ListItem
				component={link && Link}
				onClick={toggleCollapse}
				sx={styles.link}
				to={link}
			>
				<ListItemIcon
					sx={{
						...styles.linkIcon,
						...(isLinkActive ? styles.linkIconActive : {}),
					}}
				>
					{icon ? icon : <InboxIcon />}
				</ListItemIcon>
				<ListItemText
					sx={{
						...styles.linkText,
						...(isLinkActive ? styles.linkTextActive : {}),
						...(isSidebarOpened ? styles.linkTextHidden : {}),
					}}
					primary={label}
				/>
			</ListItem>
			{children && (
				<Collapse
					in={isOpen && isSidebarOpened}
					timeout="auto"
					unmountOnExit
					sx={styles.nestedList}
				>
					<List component="div" disablePadding>
						{children.map(childrenLink => (
							<SidebarLink
								key={childrenLink && childrenLink.link}
								location={location}
								isSidebarOpened={isSidebarOpened}
								nested
								{...childrenLink}
							/>
						))}
					</List>
				</Collapse>
			)}
		</>
	);

	// ###########################################################

	function toggleCollapse(e) {
		if (isSidebarOpened) {
			e.preventDefault();
			setIsOpen(!isOpen);
		}
	}
}
