import _ from 'lodash'
import { Field } from 'formik';
import AsyncAutoComplete from './AsyncAutoComplete';

export function AsyncAutoCompleteFormik({name, ...props}) {
	return (
		<Field name={name}>
			{({field, meta, form}) => (
				<AsyncAutoComplete
					name={field.name}
					error={meta.touched && meta.error}
					{...props}
					{...field}
					onChange={(val, option) => {
						form.setFieldValue(field.name, val)
						if (props.onChange) {
							props.onChange(val, option)
						}
					}}
				/>
			)}
		</Field>
	);
}
