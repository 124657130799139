import React from "react";
import { Grid, Paper, Typography, Button } from '@mui/material';
import { Link } from "react-router-dom";

// styles
import styles from "./styles";

export default function Error() {
	return (
		<Grid container sx={styles.container}>
			<Paper sx={styles.paperRoot}>
				<Typography
					variant="h1"
					color="primary"
					sx={{...styles.textRow, ...styles.errorCode}}
				>
					404
				</Typography>
				<Typography variant="h5" color="primary" sx={styles.textRow}>
					Oops the page you were looking for doesn't exist
				</Typography>
				<Typography
					variant="h6"
					color="text"
					sx={{...styles.textRow}}
				>
					Nothing to worry about. Let's go back to home page
				</Typography>
				<Button
					variant="contained"
					color="primary"
					component={Link}
					to="/"
					size="large"
					sx={styles.backButton}
				>
					Back to homepage
				</Button>
			</Paper>
		</Grid>
	);
}
