import _ from 'lodash';
import { useCallback, useEffect } from "react";
import {DialogActions, DialogContent, Button, Box, Alert, FormControlLabel} from '@mui/material';
import Yup from 'services/yup';

// components
import { useModalState } from "utils/hooks";
import { Formik, Field } from 'formik';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import PromptIfDirty from 'components/PromptIfDirty/PromptIfDirty';
import {
	Add as AddIcon,

} from '@mui/icons-material';
import DraggableDialog from "components/DraggableDialog";
import FormikTextField from 'components/Form/FormikTextField';
import { CopyId } from 'components/CopyId';
import { useMutateArrayItemQuery, useWrappedQuery } from 'utils/reactQueryHooks';
import { fetchSingleTag, upsertTag, formatError } from 'modules/tags/api';
import AutoCompleteFormik from 'components/Form/AutoCompleteFormik';
import { fetchWorkspaces, tagEntities, tagKinds } from 'utils/autoComplete';
import { AsyncAutoCompleteFormik } from 'components/Form/AsyncAutoCompleteFormik';
import { publish } from 'hooks/pubsub';

function getSchema() {
	const schema = Yup.object().shape({
		name: Yup.string().trim().required(),
		workspaceId: Yup.string().trim().required(),
		color: Yup.string().trim(),
		kind: Yup.string().nullable().trim().required(),
		source: Yup.string().nullable().trim().required(),
		order: Yup.number().required(),
		config: Yup.object().shape({}),
	})
	return schema;
}

const initalValues = {
	name: '', workspaceId: null, color: '', kind: 'date', source: 'offer', order: 0,
	config: {},
}


export function CreateEditTag() {
	const {modalState, toggleModal} = useModalState('tag')

	const tagQuery = useWrappedQuery({
		enabled: !!modalState.id,
		queryFn: () => fetchSingleTag(modalState.id),
		queryKey: ['tag', modalState.id],
	})

	const rawTag = tagQuery.data;
	const tag = (rawTag && modalState.reason === 'Duplicate') ? _.omit(rawTag, ['id']) : rawTag;
	
	const closeModal = useCallback(function closeModal() {
		toggleModal(false);
	}, [toggleModal]);
	
	// useEffect(() => {
		
	// }, [modalState.open])
		
	const saveTagQuery = useMutateArrayItemQuery({
		kind: 'upsert',
		mutationFn: (data: any) => upsertTag({tag: data}),
		queryKey: modalState.id && ['tag', modalState.id],
		queryListKey: ['tagsList'],
		formatError,
	})
	const resetQuery = saveTagQuery.reset;
	const fieldsDisabled = tagQuery.isFetching || saveTagQuery.isLoading
	const submitError = saveTagQuery.formattedError;
		
	useEffect(() => {
		if (modalState.open) {
			resetQuery();
		}
		if (!modalState.open) {
			publish('tagModalClosed');
		}
	}, [modalState, resetQuery])
      
	function handleFormSubmit(values) {
		const castValues = getSchema().cast({
			...values,
		});
		saveTagQuery.mutateAsync(castValues)
			.then((newTag) => {
				const channel = values.id ? 'tagUpdated' : 'tagCreated';
				publish(channel, {id: newTag?.id})
				closeModal()
			})
	}

	const handleClose = (event, reason) => {
		if (reason === "backdropClick") 
			return;
		closeModal();
	}
	const title = 
		tagQuery.isFetching ?
			'Loading...' :
			modalState.id ? `${modalState.reason} Tag ${tag?.n || ''}` : "Create New Tag";
	return (
		<DraggableDialog
			open={modalState.open}
			onClose={handleClose}
			fullWidth
			title={title}
			titleId="tag-dialog-title"
			titleSx={{bgcolor: 'primary.main', color: t => t.palette.primary.contrastText}}
		>
			<Formik
				initialValues={_.merge({}, initalValues, tag)}
				enableReinitialize
				validationSchema={getSchema()}
				onSubmit={(values, { setSubmitting }) => {
					handleFormSubmit(values);
					setSubmitting(false);
				}}
			>
				{({ submitForm, values }) => (
					<>
						<DialogContent dividers>
							<CopyId id={tag?.id} sx={{mb: 1}} />
							<Box component="form" sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 600}}>
								<PromptIfDirty />
								<Field
									component={FormikTextField}
									name="name"
									type="text"
									label="Tag name"
									disabled={fieldsDisabled}
									size="small"
								/>
								<AsyncAutoCompleteFormik
									entityName='workspace'
									limit={15}
									getItems={fetchWorkspaces}
									label="Workspace"
									name="workspaceId"
									disabled={fieldsDisabled}
									sx={{flex: 1}}
									size="small"
								/>
								{/* <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
									<Field
										component={FormikTextField}
										name="color"
										type="text"
										label="Tag color"
										sxWrap={{flex: 1}}
										disabled={fieldsDisabled}
										size="small"
									/>
									<Box sx={{bgcolor: values.color, width: 35, height: 35, borderRadius: 50}} />
								</Box> */}
								<Field
									component={AutoCompleteFormik}
									options={tagKinds}
									copyIcon
									label="Kind"
									name="kind"
									disabled={fieldsDisabled}
									size="small"
								/>
								<Field
									component={AutoCompleteFormik}
									options={tagEntities}
									copyIcon
									label="Entity"
									name="source"
									disabled={fieldsDisabled}
									size="small"
								/>
								<Field
									component={FormikTextField}
									type="number"
									label="Order"
									name="order"
									disabled={fieldsDisabled}
									size="small"
								/>
								{submitError && <Alert severity="error">{submitError}</Alert>}
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={closeModal}>Cancel</Button>
							<ProgressButton
								variant="contained"
								color="primary"
								sxWrap={{alignSelf: 'flex-start'}}
								onClick={submitForm}
								disabled={fieldsDisabled}
								loading={saveTagQuery.isLoading}
							>
								Save
							</ProgressButton>
						</DialogActions>
					</>
				)}
			</Formik>
		</DraggableDialog>
	)
}
