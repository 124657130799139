import { Box, SxProps, Theme, Typography } from "@mui/material";
import {
	Policy as PolicyIcon, SvgIconComponent,
} from '@mui/icons-material';

interface EmptyStateProps {
	isLoading?: boolean,
	isEmpty?: boolean,
	icon?: SvgIconComponent,
	text: string,
	size?: 'medium' | 'large',
	sx?: {container?: SxProps<Theme>, icon?: SxProps<Theme>}
}
export function EmptyState({isLoading = false, isEmpty, icon, text, size, sx}: EmptyStateProps) {
	if (isLoading || !isEmpty) {return null;}
	const Icon = icon || PolicyIcon;
	let cmpSx: any = {}
	switch (size) {
	case 'medium': 
		cmpSx = {iconSize: 50, fontSize: 30, gap: 1};
		break;
	case 'large': 
		cmpSx = {iconSize: 60, fontSize: 40, gap: 1};
		break;
	default:
		break;
	}
	return (
		<Box sx={{
			display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
			gap: cmpSx.gap, ...sx?.container,
		}}>
			<Icon sx={{fontSize: cmpSx.iconSize, color: 'text.hint', ...sx?.icon}} />
			<Typography fontSize={cmpSx.fontSize} color="text.hint" textAlign="center">{text}</Typography>
		</Box>
	)
}
