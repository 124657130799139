import _ from 'lodash';
import { useCallback, useEffect } from "react";
import {DialogActions, DialogContent, Button, Box, Alert} from '@mui/material';
import Yup from 'services/yup';

// components
import { useModalState } from "utils/hooks";
import { Formik, Field } from 'formik';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import PromptIfDirty from 'components/PromptIfDirty/PromptIfDirty';
import {
	Add as AddIcon,

} from '@mui/icons-material';
import DraggableDialog from "components/DraggableDialog";
import FormikTextField from 'components/Form/FormikTextField';
import { CopyId } from 'components/CopyId';
import { useMutateArrayItemQuery, useWrappedQuery } from 'utils/reactQueryHooks';
import { fetchSingleSite, upsertSite, formatError } from 'modules/sites/api';
import {validations} from 'services/yup';
import { AsyncAutoCompleteFormik } from 'components/Form/AsyncAutoCompleteFormik';
import { fetchWorkspaces } from 'utils/autoComplete';

function getSchema() {
	let schema = Yup.object().shape({
		name: Yup.string().trim().required(),
		url: Yup.string().trim().required().concat(validations.siteUrl),
		workspaceId: Yup.string().trim().nullable().required(),
	})
	return schema;
}

const initalValues = {
	name: '', url: '', comments: '', workspaceId: null,
}


export function CreateEditSite() {
	const {modalState, toggleModal} = useModalState('site')

	const siteQuery = useWrappedQuery({
		enabled: !!modalState.id,
		queryFn: () => fetchSingleSite(modalState.id),
		queryKey: ['site', modalState.id],
	})

	const rawSite = siteQuery.data;
	const site = (rawSite && modalState.reason === 'Duplicate') ? _.omit(rawSite, ['id']) : rawSite;
	
	const closeModal = useCallback(function closeModal() {
		toggleModal(false);
	}, [toggleModal]);
	
	// useEffect(() => {
		
	// }, [modalState.open])
		
	const saveSiteQuery = useMutateArrayItemQuery({
		kind: 'upsert',
		mutationFn: (data: any) => upsertSite({site: data}),
		queryKey: modalState.id && ['site', modalState.id],
		queryListKey: ['sitesList'],
		formatError,
	})
	const resetQuery = saveSiteQuery.reset;
	const fieldsDisabled = siteQuery.isFetching || saveSiteQuery.isLoading
	const submitError = saveSiteQuery.formattedError;
		
	useEffect(() => {
		if (modalState.open) {
			resetQuery();
		}
	}, [modalState, resetQuery])
      
	function handleFormSubmit(values) {
		const castValues = getSchema().cast({
			...values,
		});
		saveSiteQuery.mutateAsync(castValues)
			.then(() => closeModal())
	}

	const handleClose = (event, reason) => {
		if (reason === "backdropClick") 
			return;
		closeModal();
	}
	const title = 
		siteQuery.isFetching ?
			'Loading...' :
			modalState.id ? `${modalState.reason} Site ${site?.n || ''}` : "Create New Site";
	return (
		<DraggableDialog
			open={modalState.open}
			onClose={handleClose}
			fullWidth
			title={title}
			titleId="site-dialog-title"
			titleSx={{bgcolor: 'primary.main', color: t => t.palette.primary.contrastText}}
		>
			<Formik
				initialValues={_.merge({}, initalValues, site)}
				enableReinitialize
				validationSchema={getSchema()}
				onSubmit={(values, { setSubmitting }) => {
					handleFormSubmit(values);
					setSubmitting(false);
				}}
			>
				{({ submitForm }) => (
					<>
						<DialogContent dividers>
							<CopyId id={site?.id} sx={{mb: 1}} />
							<Box component="form" sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 600}}>
								<PromptIfDirty />
								<Field
									component={FormikTextField}
									name="name"
									type="text"
									label="Site name"
									disabled={fieldsDisabled}
									size="small"
								/>
								<Field
									component={FormikTextField}
									name="url"
									type="text"
									label="Site url"
									disabled={fieldsDisabled}
									size="small"
								/>
								<AsyncAutoCompleteFormik
									entityName='workspace'
									limit={15}
									getItems={fetchWorkspaces}
									label="Workspace"
									name="workspaceId"
									disabled={fieldsDisabled}
									sx={{flex: 1}}
									size="small"
								/>
								{submitError && <Alert severity="error">{submitError}</Alert>}
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={closeModal}>Cancel</Button>
							<ProgressButton
								variant="contained"
								color="primary"
								sxWrap={{alignSelf: 'flex-start'}}
								onClick={submitForm}
								disabled={fieldsDisabled}
								loading={saveSiteQuery.isLoading}
							>
								Save
							</ProgressButton>
						</DialogActions>
					</>
				)}
			</Formik>
		</DraggableDialog>
	)
}
