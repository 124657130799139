import _ from 'lodash';
import { useCallback, useEffect, useState } from "react";
import { useMutateArrayItemQuery, useWrappedQuery } from "utils/reactQueryHooks";
import { EnhancedDataGrid } from 'components/DataGrid/EnhancedDataGrid';
import { ResponsivePaper } from 'components/ResponsivePaper/ResponsivePaper';
import { GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { fetchWorkspaceWeeklyPerformance, upsertWorkspaceWeeklyPerformance, formatError } from 'modules/workspacePerformance/api';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { DatePicker } from 'components/Form/DatePicker';
import {
	Add as AddIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Flex } from 'components/Flex/Flex';
import { formatDate, formatNum } from 'utils/transformUtils';
import { Chart } from 'react-chartjs-2';
import { addWeeks } from 'date-fns';


function getClosestThursday(date = new Date()) {
	const dayOfWeek = date.getDay(); // 0 (Sun) to 6 (Sat)
	const distanceToThursday = (4 - dayOfWeek + 7) % 7; // 4 is Thursday
	const closestThursday = new Date(date);
	closestThursday.setDate(date.getDate() + distanceToThursday);
	return closestThursday;
}
const thursday = getClosestThursday();
const thursdayStr = thursday.toISOString().slice(0, 10);
const defaultRange = [addWeeks(thursday, -10), addWeeks(thursday, 10)] as [Date, Date];
function getColumns(): GridColDef[] {
	return [
		{ field: `date`, headerName: `Date`, width: 120, type: 'string', valueFormatter: (value: any) => value.slice(0, 10) },
		{ field: `expectedAvgTotalOffers`, editable: true, headerName: `Exp Total Offers`, description: 'Avg Total Offers Sun-Wed', width: 140, type: 'number', align: 'left', headerAlign: 'left', valueFormatter: (value: any) => formatNum(value, 0)},
		{ field: `actualAvgTotalOffers`, headerName: `Act. Total Offers`, description: 'Avg Total Offers Sun-Wed', width: 140, type: 'number', align: 'left', headerAlign: 'left', valueFormatter: (value: any) => formatNum(value, 0)},
		{ field: `expectedActiveLiveOffers`, editable: true, headerName: `Exp Live Offers`, description: 'Accumulated Live on Wed', width: 120, type: 'number', align: 'left', headerAlign: 'left', valueFormatter: (value: any) => formatNum(value, 0)},
		{ field: `actualActiveLiveOffers`, headerName: `Act. Live Offers`, description: 'Accumulated Live on Wed', width: 120, type: 'number', align: 'left', headerAlign: 'left', valueFormatter: (value: any) => formatNum(value, 0)},
		{ field: `expectedAvgProfit`, headerName: `Exp. Profit`, width: 120, description: 'Avg Profit Sun-Wed', type: 'number', align: 'left', headerAlign: 'left', valueFormatter: (value: any) => formatNum(value, 0)},
		{ field: `actualAvgProfit`, headerName: `Act. Profit`, width: 120, description: 'Avg Profit Sun-Wed', type: 'number', align: 'left', headerAlign: 'left', valueFormatter: (value: any) => formatNum(value, 0)},
		{ field: `comments`, editable: true, headerName: `Comments`, flex: 1, type: 'string', align: 'left', headerAlign: 'left' },
	]
}
const sortModel: GridSortModel = [{ field: 'date', sort: 'desc' }];
const datasetsConf = [
	{label: 'Avg Total Offers', prop: 'AvgTotalOffers', color: '#95054c', axis: 'y'},
	{label: 'Live Offers', prop: 'ActiveLiveOffers', color: '#00810A', axis: 'y'},
	{label: 'Revenue', prop: 'AvgProfit', color: '#e28743', axis: 'y1'},
]
export function WeeklyGraph({stats}) {
	const [datasets, setDatasets] = useState<any>([]);
	const [hiddenColumns, setHiddenColumns] = useState<any>({0: true, 1: true});
	
	useEffect(() => {
		setDatasets(
			datasetsConf.flatMap((conf, index) => [
				{
					borderColor: conf.color,
					borderWidth: 2,
					backgroundColor: conf.color,
					label: `Expected ${conf.label}`,
					borderDash: [5, 5],
					data: stats.map(d => d[`expected${conf.prop}`]),
					yAxisID: conf.axis,
					spanGaps: true,
					hidden: !!hiddenColumns[index * 2],
					
				},
				{
					borderColor: conf.color,
					borderWidth: 2,
					backgroundColor: conf.color,
					label: 'Actual Avg Total Offers',
					data: stats.map(d => d[`actual${conf.prop}`]),
					yAxisID: 'y',
					spanGaps: true,
					hidden: !!hiddenColumns[index * 2 + 1],
					
				},
			]))
	}, [stats, hiddenColumns])

	function handleLegendClick(e, dataset) {
		setHiddenColumns({...hiddenColumns, [dataset.datasetIndex]: !dataset.hidden});
	}

	return (
		<Box sx={{flex: 0.5, maxWidth: '100%', width: 600, height: 600}}>
			<Chart
				type="line"
				options={{
					responsive: true,
					plugins: {
						title: {
							display: false,
							text: 'My Title',
						},
						tooltip: {
							mode: 'index',
						},
						legend: {
							display: true,
							position: 'top',
							onClick: handleLegendClick,
							labels: { pointStyle: 'dash', usePointStyle: false },
						},
					},
					scales: {
						x: {
							ticks: {
								color: (ctx) => {
									return stats[ctx.index].date.slice(0, 10) === thursdayStr ? '#95054c' : '#666';
								},
								font: {
									weight: (ctx) => {
										return stats[ctx.index].date.slice(0, 10) === thursdayStr ? 'bold' : 'normal';
									},
								},
							},
						},
						y: {
							type: 'linear',
							display: true,
							position: 'left',
							title: {
								display: true,
								text: 'Num Offers',
							},
						},
						y1: {
							type: 'linear',
							display: true,
							position: 'right',
							grid: {display: false},
							title: {
								display: true,
								text: 'Revenue',
							},
						},
					},
				}}
				data={{
					labels: stats.map(d => formatDate(d.date, 'dd.MM')),
					datasets,
				}}
			/>
		</Box>
	)
}
export function WorkspaceWeeklyOverview({ workspace }) {
	const [modal, setModal] = useState({ date: thursday, open: false })
	const [filter, setFilter] = useState({ dateRange: defaultRange })
	const [hiddenColumns, setIsHiddenColumns] = useState({})
	const { isFetching, data: stats, refetch } = useWrappedQuery({
		keepPreviousData: true,
		enabled: !!workspace?.id,
		queryKey: ['fetchWorkspaceWeeklyPerformance', workspace.id, filter.dateRange],
		queryFn: () => fetchWorkspaceWeeklyPerformance({ filter: { ...filter, workspaceWeeklyPerformance: { workspaceId: workspace.id } } }),
		// initialData: {items: [], pagination: {total: 0}, totals: {}}
	});
	const submitWorkspaceWeeklyPerformanceQuery = useMutateArrayItemQuery({
		kind: 'upsert',
		queryListKey: ['fetchWorkspaceWeeklyPerformance', workspace.id],
		mutationFn: (data) => upsertWorkspaceWeeklyPerformance({ performance: data }),
		formatError,
	})
	const snackbarRef = useSnackbar()

	const processRowUpdate = useCallback(async(newRow) => {
		const updates = _.pick(newRow, ['id', 'expectedAvgTotalOffers', 'expectedActiveLiveOffers', 'comments'])
		const response = await upsertWorkspaceWeeklyPerformance({ performance: updates });
		snackbarRef.enqueueSnackbar('Update successfully saved', { variant: 'success' })
		refetch();
		return { ...response, dailyCheck: newRow.dailyCheck };
	}, [snackbarRef, refetch]);

	const handleProcessRowUpdateError = useCallback((error) => {
		snackbarRef.enqueueSnackbar('Failed to save update', { variant: 'error' })
	}, [snackbarRef]);

	return (
		<ResponsivePaper sx={{ width: '100%' }}>
			<Flex gap={4} direction="column">
				<Box sx={{height: 307, flex: 1, alignSelf: 'stretch'}}>
					<EnhancedDataGrid
						items={stats?.items}
						loading={isFetching}
						pluralName='Stats'
						density='compact'
						rowHeight={50}
						getRowId={(row) => row.date}
						getRowClassName={(params) => params.row.date.slice(0, 10) === thursdayStr ? 'current' : ''}
						columns={getColumns()}
						hideFooter
						pagination={false}
						paginationMode='client'
						sortModel={sortModel}
						editMode='row'
						sx={{
							minHeight: 300,
							'& .MuiDataGrid-row.MuiDataGrid-row--editing .MuiDataGrid-cell:not(.MuiDataGrid-cell--editable)': {
								bgcolor: '#eeeeee',
							},
							'& .MuiDataGrid-row.MuiDataGrid-row--editing .MuiDataGrid-cell.MuiDataGrid-cell--editable': {
								bgcolor: 'white',
							},
							'& .MuiDataGrid-row.current:not(:hover)': {bgcolor: 'primary.shade'},
						}}
						processRowUpdate={processRowUpdate}
						onProcessRowUpdateError={handleProcessRowUpdateError}
						noSearch
						filters={(
							<>
								<Box sx={{ fontSize: 24 }}>{workspace.name}</Box>
							</>
						)}
						actions={[{
							icon: AddIcon,
							primary: true,
							hint: 'Add weekly performance',
							onClick: () => setModal({ ...modal, open: true }),
						}]}
					/>
				</Box>
				<WeeklyGraph stats={stats?.items || []} />
			</Flex>
			<ConfirmDialog
				{...modal}
				loading={submitWorkspaceWeeklyPerformanceQuery.isLoading}
				alert={submitWorkspaceWeeklyPerformanceQuery.formattedError}
				confirmText="Create"
				title="Create Weekly Performance"
				onConfirm={() => {
					submitWorkspaceWeeklyPerformanceQuery.mutateAsync({ date: modal.date, workspaceId: workspace.id })
						.then(() => {
							setModal({ ...modal, open: false })
							return refetch();
						})
						.catch(console.error)
				}}
				onCancel={() => setModal({ ...modal, open: false })}
				customContent={(
					<DatePicker
						views={['day']}
						label="Week"
						format="dd/MM/yyyy"
						shouldDisableDate={date => date.getDay() !== 4}
						value={modal.date}
						onChange={(newValue) => {
							setModal({ ...modal, date: newValue })
						}}
					/>
				)}
			/>
		</ResponsivePaper>
	);
}