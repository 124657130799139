import { useState } from "react";
import {Tooltip, IconButton, Paper, Box, Typography, Chip} from '@mui/material';
import {
	Add as AddIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Bookmark as BookmarkIcon,
} from '@mui/icons-material';
import _ from 'lodash';

// components
import PageTitle from "../../components/PageTitle";
import { useAvailableWindowHeight, useModalState, useQueryableFilter } from "utils/hooks";
import config from "config/config";
import { useMutateArrayItemQuery, useWrappedQuery } from "utils/reactQueryHooks";
import { deleteTag, fetchTags, formatError } from "modules/tags/api";
import ConfirmDialog from "components/ConfirmDialog";
import { EnhancedDataGrid } from "../../components/DataGrid/EnhancedDataGrid";
import { Tag } from "modules/tags/api.interface";
import { GridColDef } from "@mui/x-data-grid-pro";
import { tagEntitiesMap, tagKindsMap } from "utils/autoComplete";
import { useWorkspaces } from "context/WorkspaceContext";


function getColumns({renderActionsColumn}): GridColDef<Tag>[] {
	return [
		{ field: 'name', headerName: 'Name', minWidth: 350, flex: 1},
		{ field: 'kind', headerName: 'Kind', valueGetter: (_, row) => tagKindsMap[row.kind]?.name},
		{ field: 'source', headerName: 'Entity', valueGetter: (_, row) => tagEntitiesMap[row.source]?.name},
		// { field: 'color', headerName: "Color", type: 'string', renderCell: (params) => (
		// 	params.value && <Chip sx={{bgcolor: params.value, color: t => t.palette.getContrastText(params.value)}} label={params.value} size="small" />
		// )},
		{ field: 'order', headerName: 'Order', type: 'number', minWidth: 120},
		{ field: 'actions', headerName: 'Actions', sortable: false, filterable: false, renderCell: renderActionsColumn, width: 120},
	]
}

function ActionsColumn({row, onEditClick, onDeleteClick}) {
	return (
		<>
			<Tooltip arrow title="Edit Tag">
				<IconButton
					aria-label="Edit Tag"
					sx={{p: 0.5}}
					onClick={() => onEditClick(row)}
					size="medium">
					<EditIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
			<Tooltip arrow title="Delete Tag">
				<IconButton
					aria-label="Delete Tag"
					sx={{p: 0.5, color: 'red'}}
					onClick={() => onDeleteClick(row)}
					size="medium">
					<DeleteIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
		</>
	)
}

const defaultFilter = {text: ''};
const defaultSortModel = [{field: 'order', sort: 'asc'}]
export function TagsPage() {
	const {filter, pagination, sortModel, setFilter, setPagination, setSortModel} = useQueryableFilter({defaultFilter, defaultPageSize: config.paginationDefaultValue, defaultSortModel});
	const gridHeight = useAvailableWindowHeight()
	const [confirmation, setConfirmation] = useState<any>({open: false});
	const {setModal: setTagModal} = useModalState('tag')
	const {selectedWorkspaces} = useWorkspaces();

	const {isLoading: isTagsLoading, data: tagsResponse} = useWrappedQuery({
		queryKey: ['tagsList', filter, pagination, sortModel, selectedWorkspaces.ids],
		queryFn: () => fetchTags({
			pagination,
			sortModel: sortModel,
			filter: {text: filter.text},
			workspaceIds: selectedWorkspaces.ids,
		}),
	});

	const deleteTagQuery = useMutateArrayItemQuery({
		kind: 'delete',
		mutationFn: deleteTag,
		queryListKey: ['tagsList'],
		formatError: formatError,
	})

	function handleDeleteConfirm(tag) {
		deleteTagQuery.mutateAsync(tag)
			.then(() => setConfirmation({open: false}));
	}

	function openConfirmDelete(tag) {
		setConfirmation({
			open: true,
			title: `Delete - ${tag.name}`,
			subtitle: 'Are you sure you want to delete this tag?',
			confirmText: 'Delete',
			variant: 'error',
			onConfirm: () => handleDeleteConfirm(tag),
			onCancel: () => setConfirmation({open: false}),
		});
	}
	    
	function openTagModal(id?: string, reason?: string) {
		setTagModal({id, open: true, reason})
	}

	function renderActionsColumn(params) {
		return (
			<ActionsColumn row={params.row}
				onDeleteClick={openConfirmDelete}
				onEditClick={() => openTagModal(params.row.id, 'Update')}
			/>
		);
	}

	function handleDoubleCellClick(params) {
		if (params.field !== 'actions') {
			openTagModal(params.row.id, 'Update')
		}
	}

	return (
		<>
			<PageTitle title="Tags" icon={BookmarkIcon} />
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
				<Paper sx={{height: gridHeight}}>
					<EnhancedDataGrid<Tag>
						items={tagsResponse?.items}
						loading={isTagsLoading}
						columns={getColumns({renderActionsColumn})}
						rowCount={tagsResponse?._meta?.total}
						checkboxSelection
						pagination
						paginationMode="server"
						paginationModel={pagination}
						onPaginationModelChange={newModel => setPagination(newModel)}
						sortingMode="server"
						sortModel={sortModel}
						onSortModelChange={setSortModel}
						pageSizeOptions={config.paginationOptions}
						editMode='cell'
						onProcessRowUpdateError={console.log}
						pluralName="Tags"
						filter={filter}
						setFilter={setFilter}
						showHiddenToggle
						// actionSubmitted={tagJustSubmitted}
						// actionSubmitting={tagSubmitting}
						actions={[{
							icon: AddIcon,
							primary: true,
							hint: 'Add new tag',
							onClick: () => openTagModal(),
						}]}
						onCellDoubleClick={handleDoubleCellClick}
					/>
				</Paper>
				<ConfirmDialog
					{...confirmation}
					// loading={tagSubmitting}
					// alert={tagSubmitError}
				/>
			</Box>
		</>
	)
}
