import {put, takeLatest, call} from 'redux-saga/effects';
import {Actions} from 'modules/adGroups/actions';
import axios from 'services/axios';
import {ActionTypes as PagesActions} from 'pages/actions';
import _ from 'lodash';
import { toUTC } from 'utils/transformUtils';
import { differenceInDays, endOfDay, startOfDay, subDays } from 'date-fns';
import {stringify as qsStringify} from 'query-string';
import { createCSV } from 'utils/fileUtils';
import fileSaver from 'file-saver';

function filterStats(items) {
	return items?.filter(f => ![undefined, null, ''].includes(f.value))
}
async function fetchAdGroupsPerformanceLogic({pagination, filter, sortModel, campaignId, adGroupIds}) {
	const {text, dateRange} = filter;
		
	const offset = pagination ? pagination.pageSize * pagination.page : 0;
	const limit = pagination ? pagination.pageSize : -1;
	const dateFrom = toUTC(startOfDay(new Date(dateRange[0])));
	const dateTo = toUTC(endOfDay(new Date(dateRange[1])));
	const orderBy = sortModel?.length ? sortModel[0].field : '';
	const order = sortModel?.length ? sortModel[0].sort : '';
	const params = {
		orderBy, order,
		offset, limit,
	}

	const filterdStats = filterStats(filter.model?.items)
	
	const payload = {
		dateFrom,
		dateTo,
		search: text,
		trafficSourceId: filter.trafficSourceId,
		tsAccountIds: filter.tsAccountId?.length ? filter.tsAccountId : null,
		campaignIds: campaignId ? [campaignId] : null,
		adGroupIds: adGroupIds ? adGroupIds : null,
		adGroupFilter: filterdStats ? filterdStats.reduce((aggr, item) => {
			aggr[item.columnField] = {
				op: item.operatorValue,
				value: Array.isArray(item.value) ? item.value : Number(item.value),
			}
			return aggr;
		}, {}) : null,
	}

	const query = qsStringify(params, {arrayFormat: 'bracket', skipEmptyString: true, skipNull: true, sort: (a, b) => order.indexOf(a) - order.indexOf(b)})

	const res = await axios.post(`adGroups/performance/get?${query}`, payload)
	return res;
}

function* fetchAdGroupsTotals(action) {
	yield put(Actions.FETCH_AD_GROUPS_TOTALS_REQUEST());
	try {
		const {filter} = action.payload;
		const dateFrom = toUTC(startOfDay(new Date(filter.dateRange[0])));
		const dateTo = toUTC(endOfDay(new Date(filter.dateRange[1])));
		const params = {
			dateFrom,
			dateTo,
			trafficSourceId: filter.trafficSourceId,
			tsAccountIds: filter.tsAccountId?.length ? filter.tsAccountId : null,
		}
		const order = Object.keys(params);
		const query = qsStringify(params, {arrayFormat: 'bracket', skipEmptyString: true, skipNull: true, sort: (a, b) => order.indexOf(a) - order.indexOf(b)})

		const {data} = yield call(axios.get, `adGroups/totals?${query}`);
        
		yield put(Actions.FETCH_AD_GROUPS_TOTALS_SUCCESS(data));
	} catch (err) {
		console.error(err);
		yield put(Actions.FETCH_AD_GROUPS_TOTALS_FAILURE(err));
	}
}

function* fetchAdGroupsPerformance(action) {
	try {
		const {filter} = action.payload;
	
		const filterdStats = filter.model?.items?.filter(f => ![undefined, null, ''].includes(f.value))
		if (filterdStats && filterdStats.length < filter.model.items?.length) {
			return;
		}

		yield put(Actions.FETCH_AD_GROUPS_PERFORMANCE_REQUEST());
		const {data} = yield call(fetchAdGroupsPerformanceLogic, action.payload)
		yield put(Actions.FETCH_AD_GROUPS_PERFORMANCE_SUCCESS(data));
	} catch (err) {
		console.error(err);
		yield put(Actions.FETCH_AD_GROUPS_PERFORMANCE_FAILURE(err));
	}
}

function* compareAdGroupsPeriods(action) {
	try {
		const {adGroups, dateRange: currentDateRange} = action.payload;

		const previousEndDate = subDays(currentDateRange[0], 1);
		const rangeDays = differenceInDays(currentDateRange[0], currentDateRange[1]);
		const previousStartDate = subDays(previousEndDate, rangeDays);
		const compareDateRange = [previousStartDate, previousEndDate]
		const adGroupIds = adGroups.map(a => a.id);
		yield put(Actions.FETCH_AD_GROUPS_PERFORMANCE_COMPARE_REQUEST());
		const {data} = yield call(fetchAdGroupsPerformanceLogic, {adGroupIds, filter: {dateRange: compareDateRange}})
		const comparedById = _.keyBy(data.items, 'id');
		const comparedAdGroups = adGroups.map(a => ({...a, compareStats: comparedById[a.id]?.stats}))
		yield put(Actions.FETCH_AD_GROUPS_PERFORMANCE_COMPARE_SUCCESS(comparedAdGroups));
	} catch (err) {
		console.error(err);
		yield put(Actions.FETCH_AD_GROUPS_PERFORMANCE_COMPARE_FAILURE(err));
	}
}

function* submitAdGroupForm(action) {
	yield put(Actions.AD_GROUP_FORM_SUBMIT_REQUEST());
	try {
		const {payload} = action;
		const adGroupId = payload.id;
		
		const fields = _.pick(payload, []);
		const method = adGroupId ? axios.patch : axios.post;
		const url = adGroupId ? `adGroups/${adGroupId}` : `adGroups`;
		const result = yield call(method, url, fields);

		yield put(Actions.AD_GROUP_FORM_SUBMIT_SUCCESS(result.data, {isNew: !adGroupId}));
	} catch (err) {
		yield put(Actions.AD_GROUP_FORM_SUBMIT_FAILURE(err));
	}
}

function* updateStatus(action) {
	yield put(Actions.AD_GROUPS_UPDATE_STATUS_REQUEST());
	try {
		
		const payload = _.pick(action.payload, ['adGroupIds', 'action'])
		const result = yield call(axios.post, `adGroups/status/update`, payload);

		yield put(Actions.AD_GROUPS_UPDATE_STATUS_SUCCESS(result.data));
	} catch (err) {
		yield put(Actions.AD_GROUPS_UPDATE_STATUS_FAILURE(err));
	}
}

function* downloadAdGroupReport(action) {
	try {
		const {filter} = action.payload;
	
		const filterdStats = filter.model?.items?.filter(f => ![undefined, null, ''].includes(f.value))
		if (filterdStats && filterdStats.length < filter.model.items?.length) {
			return;
		}

		yield put(Actions.AD_GROUPS_DOWNLOAD_REPORT_REQUEST());

		const {data} = yield call(fetchAdGroupsPerformanceLogic, {...action.payload, pagination: {pageSize: -1, page: 0}})
		const columns = {
			'id': 'id',
			'externalId': 'externalId',
			'name': 'name',
			'tsAccount.name': 'tsAccount',
			'tsAccount.externalId': 'tsAccountExternalId',
			'countries': 'countries',
			'stats.tsCost': 'tsCost',
			'stats.mpRevenue': 'mpRevenue',
			'stats.net': 'net',
			'stats.roi': 'roi',
			'stats.cvr': 'cvr',
			'stats.tsCPC': 'tsCPC',
			'stats.mpConversions': 'mpConversions',
			'stats.tsClicks': 'tsClicks',
			'stats.cpa': 'cpa',
			'stats.payout': 'payout',
			'stats.epc': 'epc',
			'stats.tsCPM': 'tsCPM',
			'stats.tsImpressions': 'tsImpressions',
			'stats.tsCTR': 'tsCTR',
			'stats.uv': 'uv',
		}

		const csvOptions = {
			columns,
			escape_formulas: false,
			cast: {
				string: (val) => ['=', '+', '-', '@', '\t', '\r'].includes(val[0]) ? `'${val}` : val,
				object: (obj) => Array.isArray(obj) ? obj.join(', ') : JSON.stringify(obj),
			},
		}
		const csv = yield createCSV(data.items, csvOptions);
		const dateFrom = toUTC(startOfDay(new Date(filter.dateRange[0]))).slice(0, 10);
		const dateTo = toUTC(endOfDay(new Date(filter.dateRange[1]))).slice(0, 10);
		fileSaver.saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), `adGroups_${dateFrom}_${dateTo}.csv`);

		yield put(Actions.AD_GROUPS_DOWNLOAD_REPORT_SUCCESS(data));
	} catch (err) {
		console.error(err);
		yield put(Actions.AD_GROUPS_DOWNLOAD_REPORT_FAILURE(err));
	}
}

const sagas = [
	takeLatest(PagesActions.AD_GROUPS_FILTERING_CHANGED, fetchAdGroupsPerformance),
	takeLatest(PagesActions.AD_GROUPS_FILTERING_CHANGED, fetchAdGroupsTotals),
	takeLatest(PagesActions.AD_GROUPS_COMPARE_ENABLED, compareAdGroupsPeriods),
	takeLatest(PagesActions.AD_GROUP_FORM_SUBMITTED, submitAdGroupForm),
	takeLatest(PagesActions.AD_GROUPS_UPDATE_STATUS_CONFIRMED, updateStatus),
	takeLatest(PagesActions.AD_GROUPS_DOWNLOAD_REPORT_CLICK, downloadAdGroupReport),
]
export default sagas;
