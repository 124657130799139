import { combineReducers } from 'redux';
import {reducer as authReducer, Sagas as authSagas} from 'modules/auth';
import {reducer as adsReducer, Sagas as adsSagas} from 'modules/ads';
import {reducer as adGroupsReducer, Sagas as adGroupsSagas} from 'modules/adGroups';
import {reducer as chartsReducer, Sagas as chartsSagas} from 'modules/charts';
import {Sagas as navigationSagas} from 'modules/navigation';
import {Sagas as notifySagas} from 'modules/notify';
import {reducer as settingsReducer, Sagas as settingsSagas} from 'modules/settings';
import { all } from 'redux-saga/effects'

export const getRootReducer = (options) => combineReducers({
	adGroups: adGroupsReducer,
	ads: adsReducer,
	charts: chartsReducer,
	router: options.routerReducer,
	settings: settingsReducer,
	auth: authReducer,
})

export function * rootSaga() {
	yield all([
		...adGroupsSagas,
		...adsSagas,
		...chartsSagas,
		...authSagas,
		...navigationSagas,
		...notifySagas,
		...settingsSagas,
	])
}