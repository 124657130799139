import _ from 'lodash';
import {ActionTypes} from './actions';
import { setLoader } from 'utils/reduxUtils';

const initialValue = {
	filter: {showArchived: false},
}

// const formatError = (payload) => {
// 	const errorCode = _.get(payload, 'response.data.key');
// 	switch (errorCode) {
// 	default:
// 		return 'Oops. Request failed';
// 	}
// }
const campaignsReducer = (state = initialValue, action) => {
	let nextState;
	const {type, payload} = action;
	switch (type) {
	case ActionTypes.FETCH_ENTITY_CHART_REQUEST:
		nextState = {...state, chart: null, ...setLoader(state, 'loading', true)};
		break;
	case ActionTypes.FETCH_ENTITY_CHART_SUCCESS:
		nextState = {...state, chart: payload.items, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.FETCH_ENTITY_CHART_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false)};
		break;
	default:
		nextState = state;
	}
	return nextState;
};

export default campaignsReducer;
