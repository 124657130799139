const styles = {
	logotypeContainer: {
		height: "100%",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: {
			xs: '100%',
			lg: '60%',
		},
		display: {
			xs: 'none',
			lg: 'flex',
		},
	},
	logotypeImage: {
		width: '95%',
		maxWidth: 500,
		marginBottom: 4,
	},
	logotypeText: {
		fontWeight: 500,
		fontSize: {
			xs: 48,
			lg: 84,
		},
	},
	form: {
		position: 'relative',
		borderRadius: 4,
		maxWidth: 420,
		padding: 4,
		width: '95%',
		zIndex: 1,
		textAlign: 'center',
	},
	tab: {
		fontWeight: 400,
		fontSize: 18,
	},
	greeting: {
		fontWeight: 500,
		fontSize: 27,
		textAlign: "center",
		marginTop: 4,
	},
	subGreeting: {
		fontWeight: 500,
		textAlign: "center",
		marginTop: 2,
	},
	googleButton: {
		marginTop: 6,
		boxShadow: theme => theme.customShadows.widget,
		backgroundColor: "white",
		width: "100%",
		textTransform: "none",
	},
	googleButtonCreating: {
		marginTop: 0,
	},
	googleIcon: {
		width: 30,
		marginRight: theme => theme.spacing(2),
	},
	creatingButtonContainer: {
		marginTop: theme => theme.spacing(2.5),
		height: 46,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	createAccountButton: {
		height: 46,
		textTransform: "none",
	},
	formDividerContainer: {
		marginTop: theme => theme.spacing(4),
		marginBottom: theme => theme.spacing(4),
		display: "flex",
		alignItems: "center",
	},
	formDividerWord: {
		paddingInlineStart: theme => theme.spacing(2),
		paddingInlineEnd: theme => theme.spacing(2),
	},
	formDivider: {
		flexGrow: 1,
		height: 1,
		backgroundColor: theme => theme.palette.text.hint + "40",
	},
	errorMessage: {
		textAlign: "center",
	},
	textFieldUnderline: {
		"&:before": {
			borderBottomColor: theme => theme.palette.primary.light,
		},
		"&:after": {
			borderBottomColor: theme => theme.palette.primary.main,
		},
		"&:hover:before": {
			borderBottomColor: theme => `${theme.palette.primary.light} !important`,
		},
	},
	textField: {
		borderBottomColor: theme => theme.palette.background.light,
	},
	formButtons: {
		width: "100%",
		marginTop: theme => theme.spacing(4),
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	loginLoader: {
		marginLeft: theme => theme.spacing(4),
	},
	copyright: {
		marginTop: theme => theme.spacing(4),
		whiteSpace: "nowrap",
		position: {
			md: 'absolute',
		},
		bottom: {
			md: theme => theme.spacing(2),
		},
	},
}

export default styles;
