import React, { useCallback, useState } from "react";
import {
	Grid,
	CircularProgress,
	Button,
	Box,
	TextField,
	Paper,
	Divider,
} from '@mui/material';
import { Alert } from '@mui/material';

import styles from "./styles";
import googleLogo from "images/google-logo.svg";
import { Helmet } from "react-helmet-async";
import config from "config/config";
import Particles from "react-tsparticles";
import { loadFull } from 'tsparticles';
import { loadTextShape } from "tsparticles-shape-text";

const ParticlesBackground = () => {
	const particlesInit = useCallback(async(engine) => {

		// you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
		// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
		// starting from v2 you can add only the features you need reducing the bundle size
		await loadFull(engine);
		await loadTextShape(engine);
	}, []);

	if (!config.flavorConfig.login.particlesEnabled) {
		return null;
	}

	return (
		<Particles
			id="tsparticles"
			init={particlesInit}
			options={{
				background: {
					color: {value: "transparent"},
				},
				fpsLimit: 120,
				interactivity: {
					events: {
						onClick: {enable: true, mode: "push"},
						onHover: {enable: true, mode: "repulse"},
						resize: true,
					},
					modes: {
						push: {quantity: 4},
						repulse: {distance: 150, duration: 0.4},
					},
				},
				particles: config.flavorConfig.login.particles,
				detectRetina: true,
			}}
		/>
	);
};

function Login({loading, error, onSubmit}) {
	// local
	const [username, setUsernameValue] = useState("");
	const [password, setPasswordValue] = useState("");
	const [usePassword, setUsePassword] = useState(false);

	return (
		<Grid
			container
			sx={{
				height: '100vh',
				width: '100vw',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				overflow: 'hidden',
				position: 'relative',
			}}
			// onMouseMove={handleMouseMove}
		>
			<ParticlesBackground />
			<Box component={config.flavorConfig.login.media.kind}
				src={config.flavorConfig.login.media.src}
				{...config.flavorConfig.login.media.props}
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '110%',
					height: '110%',
					objectFit: 'cover',
					objectPosition: 'center center',
					zIndex: -1,
					overflow: 'hidden',
					...config.flavorConfig.login.media.sx,
				}} />
			<Helmet><title>Login - {config.appName}</title></Helmet>
			<Box sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				...config.flavorConfig.login.formSx,
				width: {
					xs: '100%',
					lg: config.flavorConfig.login.formWidth,
				},
			}}>
				<Paper sx={styles.form} elevation={5}>
					<Box component="img" src={config.flavorConfig.logo} sx={{
						width: `${config.flavorConfig.login.logoWidthPercent}%`,
						margin: 'auto',
						display: 'block',
					}} />
					<Box component="form" method="post" action="/api/auth/login/oidc-google/start?app=tr" sx={{mt: 2}}>
						<Button type="submit" fullWidth size="large" variant="outlined" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2}}>
							<Box component="img" src={googleLogo} />
							Sign In With Google
						</Button>
					</Box>
					{!usePassword && <Button variant="text" sx={{color: '#bdbdbd', textTransform: 'none', p: 0, alignSelf: 'center', mt: 1}} onClick={() => setUsePassword(!usePassword)}>Use password</Button>}
					{usePassword && (
						<Box component="form" onSubmit={(e) => {
							e.preventDefault();
							onSubmit({username, password});
						}}>
							<Divider sx={{mt: 2}}>OR</Divider>
							<TextField
								id="username"
								value={username}
								onChange={e => setUsernameValue(e.target.value)}
								margin="normal"
								label="Username"
								fullWidth
								size="small"
							/>
							<TextField
								id="password"
								InputProps={{
									classes: {
										underline: styles.textFieldUnderline,
										input: styles.textField,
									},
								}}
								value={password}
								onChange={e => setPasswordValue(e.target.value)}
								margin="normal"
								label="Password"
								type="password"
								size="small"
								fullWidth
							/>
							<div sx={styles.formButtons}>
								{loading ? (
									<CircularProgress size={26} sx={styles.loginLoader} />
								) : (
									<Button
										disabled={
											username.length === 0 || password.length === 0
										}
										type="submit"
										variant="contained"
										color="primary"
										size="large"
										fullWidth
										sx={{mt: 2}}
									>
										Login
									</Button>
								)}
							</div>
						</Box>
					)}
					{error && (<Alert sx={{mt: 4}} severity="error">{error}</Alert>)}
				</Paper>
			</Box>
		</Grid>
	);
}

export default Login;
