import React from "react";
import {Route, Routes, Navigate, useLocation } from "react-router-dom";
import ScrollToTop from 'navigation/ScrollToTop';

// components
import Layout from "../Layout/Layout";

// pages
import Error from "../../pages/error/Error";
import Login from "../../pages/login/loginPageRedux";
import { useSelector } from "react-redux";
import * as AuthSelectors from 'modules/auth/selectors';

export default function App({isAuthenticated, isAuthComplete}) {
	const user = useSelector(AuthSelectors.user);

	if (!isAuthComplete) {
		return null;
	}

	const adminRoles = ['superAdmin'];
	const baseRedirect = adminRoles.includes(user?.primaryRole) ? `dashboard` : 'offers';
	// global
	return (
		<ScrollToTop>
			<Routes>
				<Route exact path="/" element={<Navigate to="/app" />} />
				<Route
					exact
					path="/app"
					element={<Navigate to={`/app/admin/${baseRedirect}`} />}
				/>
				<Route
					exact
					path="/app/login"
					element={<PublicRoute component={Login} />}
				/>
				<Route
					path="/app/admin/*"
					element={<PrivateRoute component={Layout} />}
				/>
				<Route path="*" element={<Error />} />
			</Routes>
		</ScrollToTop>
	);

	// #######################################################################

	function PrivateRoute({ component }) {
		const location = useLocation();
		return isAuthenticated ? 
			(React.createElement(component)) : 
			(<Navigate
				to={{
					pathname: "/app/login",
					state: {
						from: location,
					},
				}}
			/>)
	}

	function PublicRoute({ component }) {
		return isAuthenticated ? (
			<Navigate
				to={{
					pathname: "/app",
				}}
			/>
		) : (
			React.createElement(component)
		)
	}
}
