import _ from 'lodash';
import { Paper, Box } from "@mui/material"
import {
	Edit as EditIcon,
	FilterAlt as FilterAltIcon,
} from "@mui/icons-material";
import { useWrappedMutation, useWrappedQuery } from "utils/reactQueryHooks";
import { GridSortModel } from "@mui/x-data-grid-pro";
import {PlaygroundFilter} from "./PlaygroundFilter";
import { subDays } from "date-fns";
import { useRef, useState } from "react";
import { formatDate, toUTC } from "utils/transformUtils";
import fileSaver from 'file-saver';
import { createCSV } from 'utils/fileUtils';
import { EnhancedDataGrid } from "components/DataGrid/EnhancedDataGrid";
import { fetchReport } from "modules/report/api";
import { useAvailableWindowHeight, useQueryableFilter } from "utils/hooks";
import { getColumns } from "./playgroundColumns";
import { useWorkspaces } from "context/WorkspaceContext";
import * as SettingSelectors from 'modules/settings/selectors';
import { useSelector } from "react-redux";
import { BulkUpdateOffersModal } from 'components/BulkUpdateOffersModal';
import { bulkUpdateOffers } from 'modules/offers/api';
import { Offer } from 'modules/offers/api.interface';

const now = new Date();

function getNextGroupBy(groupBy) {
	switch (groupBy) {
	case 'trafficSource': 
	case 'offer':
		return 'campaign';
	default:
		return 'offer';
	}
}

function getNextFilter(groupBy, row) {
	if (groupBy === 'geo') {
		return {
			filter1Value: null,
			offerGeos: [row.geo],
		}
	}
	return {
		filter1Name: groupBy,
		filter1Value: [row[groupBy]?.id].filter(f => f),
	}
}
const defaultFilter = {
	dateRange: [toUTC(subDays(now, 7)).slice(0, 10), toUTC(now).slice(0, 10)], filter1Name: 'offer', filter1Value: null,
	groupBy1: 'offer', search: '',
};
const defaultSortModel = [{field: 'profit', sort: 'desc'}] as GridSortModel

function mapItemForDownload(itm, columns) {
	const updatedItm = {...itm};

	columns.forEach(col => {
		let val = itm[col.field];
		if (col.valueGetter) {
			updatedItm[col.field] = col.valueGetter(val, itm)
		}
	});
	['offerTags'].forEach(key => {
		if (updatedItm[key]) {
			updatedItm[key] = updatedItm[key].map(data => data?.name ?? data).filter(t => !_.isNil(t)).join(',')
		}
	})
	return updatedItm
}
export function PlaygroundPage() {
	const globalSettings = useSelector(SettingSelectors.globalSettings)
	const [downloadingStats, setDownloadingStats] = useState(false);
	const filterRef = useRef<any>();
	const [bulkOffersModal, setBulkOffersModal] = useState({open: false, offerIds: []})
	const gridHeight = useAvailableWindowHeight(filterRef?.current?.clientHeight)
	const {selectedWorkspaces} = useWorkspaces();


	const {filter, pagination, sortModel, setFilter, setPagination, setSortModel} = useQueryableFilter({defaultFilter, defaultPageSize: 100, defaultSortModel, replace: false})


	const {isFetching, data: stats, refetch} = useWrappedQuery({
		keepPreviousData: true,
		queryKey: ['stats', filter, pagination, sortModel, selectedWorkspaces.ids],
		queryFn: () => fetchReport({filter, pagination, sortModel, workspaceIds: selectedWorkspaces.ids}),
		// initialData: {items: [], pagination: {total: 0}, totals: {}}
	});
	const updateOffersQuery = useWrappedMutation({
		mutationFn: (data: Partial<Offer>) => bulkUpdateOffers({ids: bulkOffersModal.offerIds, update: data}),
	})
	const columns = getColumns(filter.groupBy1, globalSettings);

	function handleRowDoubleClick(params) {
		const currentGroupBy = filter.groupBy1;
		const nextGroupBy = getNextGroupBy(currentGroupBy);
		const nextFilter = getNextFilter(currentGroupBy, params.row);
		setFilter({
			...filter,
			...nextFilter,
			groupBy1: nextGroupBy,
			search: '',
		})
	}
	async function downloadStats() {
		try {
			setDownloadingStats(true);
			const statsResp = await fetchReport({filter, sortModel, workspaceIds: selectedWorkspaces.ids})
			const reportCols = columns.filter(c => !c.skipDownload)
			const mappedColumns = reportCols.reduce((aggr, col) => {
				aggr[col.field] = col.headerName || col.field;
				return aggr;
			}, {})
			const mappedStats = statsResp.items.map(i => mapItemForDownload(i, reportCols))
			const csv = await createCSV(mappedStats, {columns: mappedColumns});
			fileSaver.saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), `report-${formatDate(filter.dateRange[0], 'yyyyMMdd')}-${formatDate(filter.dateRange[1], 'yyyyMMdd')}.csv`);

		} catch (err) {
			console.error(err);
		} finally {
			setDownloadingStats(false);
		}
	}

	return (
		<>
			<PlaygroundFilter
				ref={filterRef}
				setFilter={setFilter}
				filter={filter}
				refetch={refetch}
				exportToCSV={downloadStats}
			/>
			<Paper sx={{mt: 1, display: 'flex', flexDirection: 'column', gap: 2}}>
				<Box sx={{height: gridHeight}}>
					<EnhancedDataGrid
						striped noToolbar
						loading={isFetching}
						items={stats?.items}
						columnHeaderHeight={36}
						rowHeight={28}
						getRowId={row => row[filter.groupBy1]?.id || JSON.stringify(row)}
						pinnedRows={{bottom: [{id: '_totals', ...stats?._meta?.totals}]}}
						pluralName="Stats"
						disableColumnMenu
						pagination
						paginationMode="server"
						checkboxSelection
						rowCount={stats?._meta?.pagination?.totalItems || 0}
						getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
						}
						getCellClassName={(params: any) => {
							const classes = [
								params.colDef?.field,
							].filter(f => f)

							if (params.colDef.field === 'profitability' && params.row.id !== '_totals') {
								const cls = params.value === '+' ? 'positive' : params.value === '-' ? 'negative' : 'neutral';
								classes.push(cls)
							}
							if (params.colDef.field === 'profit') {
								const cls = params.value > 0 ? 'positive' : params.value < 0 ? 'negative' : 'neutral';
								classes.push(cls)
							}
							if (params.colDef.field === 'roi') {
								const cls = params.value > 250 ? 'positive' : params.value < 0 ? 'negative' : 'neutral';
								classes.push(cls)
							}
							return classes.join(' ')
						}}
						sx={{
							'& .MuiDataGrid-cell': {display: 'flex', alignItems: 'center'},
							'& .profitability.positive': {bgcolor: '#29af61', color: 'white', p: 0},
							'& .profitability.negative': {bgcolor: '#f73a5d', color: 'white', p: 0},
							'& .profitability.neutral': {bgcolor: '#f9f9f9', color: '#8a94a6', p: 0},
							'& .roi': {fontWeight: 'bold'},
							'& .profit.positive, & .roi.positive': {color: '#29af61'},
							'& .profit.negative, & .roi.negative': {color: '#f73a5d'},
							'& .MuiDataGrid-columnHeaderTitleContainer': {
								'& .MuiButtonBase-root': {
									p: 0.1,
									fontSize: 12,
									marginLeft: 0.4,
								},
								'& .MuiDataGrid-columnHeaderTitle': {
									textOverflow: 'clip',
								},
							},
							'.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-iconButtonContainer': {
								display: 'none',
							},
							'& .MuiCheckbox-root': {zoom: 0.8},
						}}
						slotProps={{
							footer: {sx: {minHeight: 'initial'}},
							pagination: {sx: {
								minHeight: 'initial',
								'& .MuiToolbar-root': {minHeight: 'initial'},
								'& .MuiTablePagination-displayedRows': {margin: 0},
							}},
							footerRowCount: {sx: {minHeight: 38, bgcolor: 'red'}},
						}}
						columns={columns}
						paginationModel={pagination}
						onPaginationModelChange={setPagination}
						sortingMode="server"
						sortModel={sortModel}
						onSortModelChange={setSortModel}
						onRowDoubleClick={handleRowDoubleClick}
						selectionActions={[{
							icon: EditIcon,
							tooltip: 'Edit',
							skipConsent: true,
							onClick: (offerIds) => setBulkOffersModal({open: true, offerIds}),
						}, {
							icon: FilterAltIcon,
							tooltip: 'Filter',
							skipConsent: true,
							onClick: (items) => {
								setFilter({...filter, filter1Name: filter.groupBy1, filter1Value: items})
							},
						}]}
					/>
				</Box>
				<BulkUpdateOffersModal
					{...bulkOffersModal}
					error={updateOffersQuery.formattedError}
					loading={updateOffersQuery.isLoading}
					onSubmit={(update) => {
						updateOffersQuery.mutateAsync(update)
							.then(() => {
								setBulkOffersModal({...bulkOffersModal, open: false})
								return refetch()
							})
					}}
					onClose={() => setBulkOffersModal({...bulkOffersModal, open: false})}
				/>
			</Paper>
		</>
	);
}
