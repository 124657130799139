import _ from 'lodash';
import { useMediaQuery, useTheme } from "@mui/material";
import { useRef, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';

export const headerHeight = 40;

export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export const useDebouncedEffect = (effect, deps, delay) => {
	useEffect(() => {
		const handler = setTimeout(() => effect(), delay);

		return () => clearTimeout(handler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...(deps || []), delay]);
};

export const useIsMobile = () => {
	const theme = useTheme();
	return useMediaQuery(theme.breakpoints.down('md'))
}

export const useQueryableFilter = ({defaultFilter, replace = true, ...props}) => {
	const {defaultPageSize, defaultSortModel, prepareQuery, tagged} = props;
	const navigate = useNavigate();
	const location = useLocation();

	const getDefaultQuery = useCallback(({tagIds} = {}) => {
		const search = queryString.parse(location.search);
		let initialQuery = search.query ? JSON.parse(search.query) : {};
		if (prepareQuery) {
			initialQuery = prepareQuery(initialQuery);
		}
		const result = {
			filter: {...defaultFilter, ...initialQuery.filter},
			sortModel: initialQuery.sortModel || defaultSortModel,
			pagination: initialQuery.pagination || {page: 0, pageSize: defaultPageSize},
		}
		if (tagged && tagIds?.length && !result.filter.tagIds?.length) {
			result.filter.tagIds = tagIds;
		}
		return result;
	}, [defaultFilter, defaultPageSize, defaultSortModel, location.search, prepareQuery, tagged])

	const [{query, initial, filterChanged}, setQuery] = useState({initial: true, filterChanged: false, query: getDefaultQuery({tagIds: []})});

	useEffect(() => {
		// url reset. set default query
		if (!location.search) {
			const queryParams = new URLSearchParams(location.search);
			if (!queryParams.query) {
				const newQuery = getDefaultQuery({tagIds: []});
				queryParams.set('query', JSON.stringify(newQuery))
				setQuery({initial: true, filterChanged: true, query: newQuery});
				navigate(`${location.pathname}?${queryParams.toString()}`, {replace: true});
			}
		} else if (!filterChanged) {
			// url changed. update local query from url
			const search = queryString.parse(location.search);
			const searchQuery = search.query ? JSON.parse(search.query) : null;
			if (searchQuery && !_.isEqual(searchQuery, query)) {
				setQuery({initial: false, filterChanged: false, query: searchQuery})
			}
		}

	}, [location.search, location.pathname, getDefaultQuery, navigate, filterChanged, query])


	useDebouncedEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const searchQueryStr = queryParams.get('query');
		const strQuery = JSON.stringify(query)
		if (!_.isEqual(searchQueryStr, strQuery)) {
			queryParams.set('query', strQuery)
			navigate(`${location.pathname}?${queryParams.toString()}`, {replace: initial || replace});
			setQuery({initial: false, filterChanged: false, query: query})
		}
	}, [query], 300)

	const setFilter = useCallback((newFilter) => {
		if (typeof newFilter === 'function') {
			const filter = newFilter(query.filter);
			if (filter === query.filter) {return;}

			setQuery({initial: false, filterChanged: true, query: {...query, filter: filter, pagination: {...query.pagination, page: 0}}})
		} else {
			setQuery({initial: false, filterChanged: true, query: {...query, filter: newFilter, pagination: {...query.pagination, page: 0}}})
		}
	}, [query])

	function setPagination(newPagination) {
		setQuery({initial: false, filterChanged: true, query: {...query, pagination: newPagination}})
	}
	function setSortModel(newSortModel) {
		setQuery({initial: false, filterChanged: true, query: {...query, sortModel: newSortModel}})
	}
	function resetQuery({filter, sortModel}) {
		setQuery({initial: false, filterChanged: true, query: {filter, sortModel: sortModel || defaultSortModel, pagination: {page: 0, pageSize: defaultPageSize}}})
	}

	return {...query, setFilter, setPagination, setSortModel, resetQuery}
}

export const useHeaderHeight = () => {
	const pageHeight = window.chrome ? '100dvh' : '100vh'
	return {
		headerHeight,
		nonHeaderHeight: `calc(${pageHeight} - ${headerHeight}px)`,
	}
}
export const useAvailableWindowHeight = (additionalGap = 0) => {
	const {headerHeight} = useHeaderHeight();
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	useEffect(() => {
		function handleResize() {
			setWindowHeight(window.innerHeight);
		}
		window.addEventListener('resize', handleResize);
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	}, []);

	const littleGap = 24 + (additionalGap || 0);
	return windowHeight - headerHeight - littleGap;
}

export const useModalState = (name, defaultParams = {}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const getQueryState = useCallback(function getQueryState() {
		const search = queryString.parse(location.search);
		const modal = search[name] && JSON.parse(search[name]);
		return modal || {open: false};
	}, [location.search, name])

	const [modalState, setModalState] = useState(getQueryState());

	useEffect(() => {
		setModalState(getQueryState())
	}, [getQueryState])

	function setModal(newFilter) {
		const queryParams = new URLSearchParams(location.search);
		if (newFilter) {
			queryParams.set(name, JSON.stringify({...defaultParams, ...newFilter, open: true}));
		} else {
			queryParams.delete(name);
		}
		navigate(`${location.pathname}?${queryParams.toString()}`, {replace: true});
	}

	function toggleModal(toggleState) {
		const search = queryString.parse(location.search);
		const modal = search[name] && JSON.parse(search[name]);
		const isOpen = toggleState ?? !modal?.open;
		const newState = isOpen ? {...modal, open: true} : undefined;
		setModal(newState);
	}

	return {modalState, setModal, toggleModal}
}

export const useEnhancedLocation = () => {
	const location = useLocation();
	const [search, setSearch] = useState({})

	useEffect(() => {
		const newSrch = queryString.parse(location.search);
		setSearch(newSrch);
	}, [location.search])

	return {
		...location,
		parsedSearch: search,
	}
}
