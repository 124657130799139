import _ from 'lodash';
import { CustomizedTextField } from 'components/CustomizedTextField';


export function fieldToTextField({
	disabled,
	field: { onBlur: fieldOnBlur, ...field },
	form: { isSubmitting, touched, errors, values },
	onBlur,
	helperText,
	...props
}) {
	const fieldError = _.get(errors, field.name);
	const showError = _.get(touched, field.name) && !!fieldError;
	const value = _.get(values, field.name);
	const actualValue = _.isNil(value) ? '' : value;
  
	return {
	  error: showError,
	  helperText: showError ? fieldError : helperText,
	  disabled: disabled ?? isSubmitting,
	  onBlur:
		onBlur ??
		function(e) {
		  fieldOnBlur(e ?? field.name);
		},
	  ...field,
	  ...props,
	  value: actualValue,
	};
}
  
export default function FormikTextField({ children, ...props }) {
	return <CustomizedTextField {...fieldToTextField(props)}>{children}</CustomizedTextField>;
}
