import { takeEvery } from 'redux-saga/effects';
import notify from './snackBarUtils';
// import _ from 'lodash';

// eslint-disable-next-line require-yield
function* handleNotifications(action) {
	try {
		switch (action.type) {
		// case ReportActionTypes.UPLOAD_DAILY_REPORT_SUCCESS:
		// 	notify.success('Operation Completed Successfully');
		// 	break;
		// case ReportActionTypes.UPLOAD_DAILY_REPORT_FAILURE:
		// 	// case AdvertiserActionTypes.ADVERTISER_FORM_SUBMIT_FAILURE:
		// 	notify.error('Operation Failed');
		// 	break;
		default:
			const successRegex = [/FORM_SUBMIT_SUCCESS/, /UPLOAD.*_SUCCESS/, /GENERATE.*_SUCCESS/, /UPDATE.*_SUCCESS/]
			const failureRegex = [/FORM_SUBMIT_FAILURE/, /UPLOAD.*_FAILURE/, /GENERATE.*_FAILURE/, /UPDATE.*_FAILURE/]
			if (successRegex.some(sr => sr.test(action.type))) {
				notify.success('Operation Completed Successfully');
			} else if (failureRegex.some(sr => sr.test(action.type))) {
				notify.error('Operation Failed');
			}
			break;
		}
	} catch (err) {
		console.error(err);
	}
}

const sagas = [
	takeEvery('*', handleNotifications),
]
export default sagas;
