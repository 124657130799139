import TooltipButton from "components/TooltipButton/TooltipButton";
import { useModalState } from "utils/hooks";
import {
	Edit as EditIcon,
} from '@mui/icons-material'

export function EditButton({entity, row}) {
	const {setModal} = useModalState(entity);
	const id = row[entity]?.id;
	if (!id) return null;
	return (
		<TooltipButton
			title={`Edit ${entity}`}
			size="small"
			icon={EditIcon}
			onClick={() => setModal({open: true, id, reason: 'Edit'})}
			iconProps={{sx: {fontSize: 16} }}
			sx={{p: 0.2}}
		/>
	)
}