import React from "react";
import {CircularProgress, Box, Button} from '@mui/material';

export default function ProgressButton({loading, disabled = false, children, sxWrap, ...props}) {
	return (
		<Box sx={{ m: 1, position: 'relative', ...sxWrap }}>
			<Button
				disabled={disabled || loading}
				{...props}
			>
				{children}
			</Button>
			{loading && (
				<CircularProgress
					size={24}
					sx={{
						color: 'primary',
						position: 'absolute',
						top: '50%',
						left: '50%',
						marginTop: '-12px',
						marginLeft: '-12px',
					}}
				/>
			)}
		</Box>
	);
}
