import _ from 'lodash'
import { useCallback, useEffect, useState } from "react"
import axios from 'axios' // can't use services/axios
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { useIsMobile } from 'utils/hooks';
import { Box, Button } from '@mui/material';
import {
	AutoAwesome as AutoAwesomeIcon,
} from '@mui/icons-material';

const now = Date.now();
export function StaleAppWarning({iconOnly}) {
	const [currentVersion, setCurrentVersion] = useState('');
	const [remote, setRemote] = useState({time: 0,  version: ''});
	const [delayedUntil, setDelayedUntil] = useState(now);
	const isMobile = useIsMobile();
    
	useEffect(() => {
		const meta: HTMLMetaElement | null = window.document.querySelector('meta[name="version"]');
		const metaContent = meta?.content || ''
		if (metaContent && metaContent !== '{{version}}') {
			setCurrentVersion(metaContent);
		}
	}, [])

	const fetchRemoteVersion = useCallback(() => {
		const time = Date.now()
		axios.get(`/assets/version.txt?${time}`)
			.then(resp => {
				setRemote({time, version: String(resp.data)})
			})
			.catch(_.identity)
	},  [])

	useEffect(() => {
		if (currentVersion) {
			fetchRemoteVersion();
			setInterval(fetchRemoteVersion, 60000)
		}
	}, [currentVersion, fetchRemoteVersion])

	function reloadPage() {
		const {href} = window.location;
		const separator = href.includes('?') ? '&' : '?'
		const cleanHref = href.replace(/&?refresh=\d+/, '')
		window.location.href = `${cleanHref}${separator}refresh=${Date.now()}`
	}

	function delayModal() {
		setDelayedUntil(Date.now() + 30 * 60 * 1000)
	}

	if (!currentVersion || !remote.version || currentVersion === remote.version || delayedUntil > Date.now()) {
		return null
	}

	if (isMobile) {
		return (
			<ConfirmDialog
				open
				title="New version is available"
				subtitle="A new version was just released and you're missing out. Want to reload to get the new version?"
				confirmText="Reload"
				onConfirm={reloadPage}
				onCancel={delayModal}
			/>
		);
	}

	return (
		<Box sx={{display: 'flex', flex: 1, alignItems: 'flex-end', p: 1}}>
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', border: '1px solid', borderColor: 'primary.main', p: 1, width: '100%'}}>
				<AutoAwesomeIcon color="primary" fontSize="large" />
				{!iconOnly && <><Box sx={{fontSize: 14}}>New version available</Box>
					<Button variant="contained" size="small" onClick={reloadPage}>Reload</Button></>}
			</Box>
		</Box>
	); 
}
