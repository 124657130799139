import { IconButton, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function TooltipButton({icon: Icon, title, iconProps = {}, loading = false, loaderProps = {}, tooltipProps = {}, ...props}) {
	const button = (
		<IconButton {...props} size="small">
			{loading ? (<CircularProgress {...loaderProps} size={20} />) : <Icon {...iconProps} />}
		</IconButton>
	)
	if (props.disabled) {
		return button;
	}
	return (
		<Tooltip title={title} slotProps={{popper: {sx: {zIndex: 2500}}}} {...tooltipProps}>
			{button}
		</Tooltip>
	)
}
