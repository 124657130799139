import {Dialog, Paper, DialogTitle} from '@mui/material';
import { useRef } from 'react';

import Draggable from 'react-draggable';

function PaperComponent(props) {
	const {titleId, ...restProps} = props;
	const nodeRef = useRef(null);
	return (
		<Draggable
			nodeRef={nodeRef}
			handle={`#${titleId}`}
			cancel={'[class*="MuiDialogContent-root"]'}
			// bounds="parent"
		>
			<Paper ref={nodeRef} {...restProps} />
		</Draggable>
	);
}

export default function DraggableDialog({children, title, titleSx, titleId = 'dialog-title', ...props}) {
	return (
		<Dialog
			{...props}
			PaperComponent={PaperComponent}
			PaperProps={{titleId}}
			aria-labelledby={titleId}
		>
			<DialogTitle sx={{...titleSx, cursor: 'pointer'}}>{title}</DialogTitle>
			{children}
		</Dialog>
	);
}
