import {ActionTypes} from './actions';
import _ from 'lodash';
import { setLoader } from 'utils/reduxUtils';

const initialValue = {
	filter: {showArchived: false},
	loaders: {},
}

const formatError = (payload) => {
	const errorCode = _.get(payload, 'response.data.key');
	switch (errorCode) {
	default:
		return 'Oops. Request failed';
	}
}
const settingsReducer = (state = initialValue, action) => {
	let nextState;
	const {type, payload} = action;
	switch (type) {
	case ActionTypes.REFRESH_ACTIVITY_STATUS_REQUEST:
		nextState = {...state, ...setLoader(state, 'refreshing', true)};
		break;
	case ActionTypes.REFRESH_ACTIVITY_STATUS_FAILURE:
		nextState = {...state, ...setLoader(state, 'refreshing', false)};
		break;
	case ActionTypes.FETCH_SETTINGS_REQUEST:
		nextState = {...state, settings: null, ...setLoader(state, 'loading', true)};
		break;
	case ActionTypes.SETTINGS_FORM_SUBMIT_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false, submitError: null};
		break;
	case ActionTypes.SETTINGS_FORM_SUBMIT_SUCCESS:
		nextState = {...state, ...setLoader(state, 'submitting', false), justSubmitted: true, settings: payload};
		break;
	case ActionTypes.FETCH_GLOBAL_SETTINGS_SUCCESS:
		nextState = {...state, ...setLoader(state, 'submitting', false), justSubmitted: true, globalSettings: payload};
		break;
	case ActionTypes.SETTINGS_FORM_SUBMIT_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	case ActionTypes.FETCH_SETTINGS_SUCCESS:
		nextState = {...state, settings: payload, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.FETCH_SETTINGS_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false)};
		break;
	default:
		nextState = state;
	}
	return nextState;
};

export default settingsReducer;
