import _ from 'lodash';
import { Box } from "@mui/material";
import AsyncAutoComplete from "components/Form/AsyncAutoComplete";
import { useEffect, useRef } from "react";
import { fetchGlobalSearch } from "utils/autoComplete";
import { useModalState } from "utils/hooks";

function EntityItem({value, option, props}) {
	const {setModal} = useModalState(option?.kind)
	if (!option) {return null}
	return (
		<Box
			{...props}
			onClick={() => setModal({id: option.id, open: true, reason: 'Update'})}
			sx={{textDecoration: 'none', color: 'initial'}}>
			{option?.name || value}
		</Box>
	)
}
const focusableTags = ['input', 'textarea'];
export function GlobalSearch() {
	const autoCompleteRef = useRef<any>(null);

	useEffect(() => {
		const handleKeyUp = (event) => {
			if (event.shiftKey && event.key === 'S' && !focusableTags.includes(event.target.tagName.toLowerCase())) {
				autoCompleteRef.current.focus();
			}
		};

		document.addEventListener('keyup', handleKeyUp);

		return () => {
			document.removeEventListener('keyup', handleKeyUp);
		};
	}, []);

	return (
		<AsyncAutoComplete
			inputRef={autoCompleteRef}
			ListboxProps={{
				sx: {
					'.MuiListSubheader-root': {
						lineHeight: '28px',
						color: 'primary.main',
						fontWeight: 'bold',
					},
				},
			}}
			textFieldProps={{
				variant: 'outlined',
				sx: {
					'& .MuiCircularProgress-root': {position: 'absolute', top: 14, right: 40},
					'& .MuiInputBase-root.MuiOutlinedInput-root': {py: 0.2},
				},
			}}
			renderOption={(value, option, props) => {
				return (
					<EntityItem value={value} option={option} props={props} />
				)
			}}
			limit={15}
			size="small"
			entityName='global'
			placeholder="Search All"
			value={null}
			minInputLength={3}
			groupBy={opt => _.upperFirst(opt?.kind)}
			simpleQuery
			getItems={fetchGlobalSearch}
		/>
	)
}
