import {Typography} from '@mui/material';
import TooltipButton from "components/TooltipButton/TooltipButton";
import {
	ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';

export function CopyId({id, show = true, sx = {}}) {
	if (!id || !show) {return null;}
	return (
		<Typography color='text.hint' fontSize={14} sx={{display: 'inline-flex', alignItems: 'center', ...sx}}>
			<TooltipButton
				size="small"
				iconProps={{sx: {fontSize: 14} }}
				icon={ContentCopyIcon} title="Copy"
				onClick={() => window.navigator.clipboard.writeText(id)}
			/>
			{id}
		</Typography>
	);
}
