import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const allActions = [
	'PUBLISHERS_FILTERING_CHANGED',
	
	'LOGIN_FORM_SUBMITTED',
	'HEADER_LOGOUT_REQUESTED',

	'SETTINGS_PAGE_MOUNTED',
	'SETTINGS_FORM_SUBMITTED',

	'CAMPAIGNS_FILTERING_CHANGED',
	'CAMPAIGN_DELETE_SUBMITTED',
	'CAMPAIGN_DELETE_MODAL_OPENED',
	'CAMPAIGN_FORM_SUBMITTED',
	
	'SINGLE_CAMPAIGN_FILTERING_CHANGED',

	'ADS_FILTERING_CHANGED',
	'AD_FORM_SUBMITTED',

	'AD_GROUPS_FILTERING_CHANGED',
	'AD_GROUP_FORM_SUBMITTED',
	'AD_GROUPS_UPDATE_STATUS_CONFIRMED',
	'AD_GROUPS_DOWNLOAD_REPORT_CLICK',
	'AD_GROUPS_COMPARE_ENABLED',
	
	'ENTITY_CHART_FILTERING_CHANGED',
]

export const ActionTypes = createActionTypes(allActions);
export const Actions = createActionCreators(allActions);
