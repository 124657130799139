import { OfferRevenueStatsModal } from "components/OfferRevenueStatsModal/OfferRevenueStatsModal";
import { CreateEditCampaign } from "pages/campaigns/CreateEditCampaign";
import { CreateEditNetwork } from "pages/networks/CreateEditNetwork";
import { CreateEditOffer } from "pages/offers/CreateEditOffer";
import { CreateEditSite } from "pages/sites/CreateEditSite";
import { CreateEditTag } from "pages/tags/CreateEditTag";
import { CreateEditTrafficSource } from "pages/trafficSources/CreateEditTrafficSource";
import { CreateEditWorkspace } from "pages/workspaces/CreateEditWorkspace";


export function AutoModals() {
	return (
		<>
			<CreateEditSite />
			<CreateEditTrafficSource />
			<CreateEditNetwork />
			<CreateEditOffer />
			<CreateEditCampaign />
			<CreateEditWorkspace />
			<CreateEditTag />
			<OfferRevenueStatsModal />
		</>
	)
}
