import _ from 'lodash';
import {ActionTypes} from './actions';
import { deepMergeBy, mergeBy, setLoader } from 'utils/reduxUtils';

const initialValue = {
	filter: {showArchived: false},
}

const formatError = (payload) => {
	const errorCode = _.get(payload, 'response.data.key');
	switch (errorCode) {
	default:
		return 'Oops. Request failed';
	}
}
export default function adGroupsReducer(state = initialValue, action) {
	let nextState;
	const {type, payload} = action;
	switch (type) {
	case ActionTypes.FETCH_AD_GROUPS_PERFORMANCE_COMPARE_REQUEST:
		nextState = {...state, ...setLoader(state, 'loading', true)};
		break;
	case ActionTypes.FETCH_AD_GROUPS_PERFORMANCE_COMPARE_SUCCESS:
		nextState = {...state, adGroups: payload, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.FETCH_AD_GROUPS_PERFORMANCE_COMPARE_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.FETCH_AD_GROUPS_PERFORMANCE_REQUEST:
		nextState = {...state, adGroups: [], adGroupsSummary: null, ...setLoader(state, 'loading', true)};
		break;
	case ActionTypes.FETCH_AD_GROUPS_PERFORMANCE_SUCCESS:
		nextState = {...state, adGroups: payload.items, adGroupsTotal: payload.pagination.total, adGroupsSummary: payload.totals, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.FETCH_AD_GROUPS_PERFORMANCE_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.AD_GROUPS_UPDATE_STATUS_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false, submitError: null};
		break;
	case ActionTypes.AD_GROUPS_UPDATE_STATUS_SUCCESS:
		nextState = {...state, ...setLoader(state, 'submitting', false), justSubmitted: true, adGroups: deepMergeBy(state.adGroups, payload.items), selectedAd: payload};
		break;
	case ActionTypes.AD_GROUPS_UPDATE_STATUS_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	case ActionTypes.AD_GROUPS_DOWNLOAD_REPORT_REQUEST:
		nextState = {...state, ...setLoader(state, 'loading', true)};
		break;
	case ActionTypes.AD_GROUPS_DOWNLOAD_REPORT_SUCCESS:
		nextState = {...state, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.AD_GROUPS_DOWNLOAD_REPORT_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false), submitError: formatError(payload)};
		break;
	case ActionTypes.FETCH_AD_GROUPS_TOTALS_SUCCESS:
		nextState = {...state, adGroupsTotals: payload.totals, ...setLoader(state, 'loadingTotals', false)};
		break;
	case ActionTypes.FETCH_AD_GROUPS_TOTALS_FAILURE:
		nextState = {...state, ...setLoader(state, 'loadingTotals', false)};
		break;
	case ActionTypes.FETCH_AD_GROUPS_TOTALS_REQUEST:
		nextState = {...state, adGroupsTotals: null, ...setLoader(state, 'loadingTotals', true)};
		break;
	default:
		nextState = state;
	}
	return nextState;
};
