import { Box, BoxProps } from "@mui/material"


export interface FlexProps extends BoxProps {
 gap?: number,
 align?: string,
 justify?: string,
 direction?: 'row' | 'column',
}
export function Flex(props: FlexProps) {
	const {gap = 1, align = 'center', justify = 'center', direction = 'row', ...restProps} = props
    
	return (<Box {...restProps} sx={{display: 'flex', gap, alignItems: align, justifyContent: justify, flexDirection: direction, ...props.sx}} />)
}
