import _ from 'lodash';
import { FetchConversionsProps } from "./api.interface";
import { toUTC } from 'utils/transformUtils';
import queryString from 'query-string';
import axios from 'services/axios';


export async function fetchConversions({pagination = undefined, filter, sortModel = undefined, workspaceIds}: FetchConversionsProps) {
	const pageSize = pagination?.pageSize ?? -1;
	const page = pagination?.page ?? 0;
	const offset = pageSize * page;
	const payload = {
		search: filter.search,
		query: {conversion: {}, trafficSource: {}},
	} as any;

	if (filter.filter1Name && filter.filter1Value?.length) {
		_.set(payload.query, [filter.filter1Name, 'id'], {op: 'in', value: filter.filter1Value});
	}
	if (workspaceIds?.length) {
		payload.query.trafficSource.workspaceId = {op: 'in', value: workspaceIds};
	}
	if (filter.offerStages?.length) {
		_.set(payload, 'query.offer.stage', {op: 'in', value: [filter.offerStages]})
	}
	if (filter.offerGeos?.length) {
		_.set(payload, 'query.offer.geo', {op: 'in', value: [filter.offerGeos]})
	}
	if (filter.offerDate) {
		_.set(payload, 'query.offer.date', toUTC(filter.offerDate).slice(0, 10))
	}


	const orderBy = sortModel?.length ? `conversion.${sortModel[0].field}` : '';
	const order = sortModel?.length ? sortModel[0].sort : '';
	const query = {
		fromDate: `${toUTC(filter.dateRange[0]).slice(0, 10)}T00:00:00Z`,
		toDate: `${toUTC(filter.dateRange[1]).slice(0, 10)}T23:59:59.999Z`,
		limit: pageSize,
		offset,
		orderBy,
		order,
	}
	const queryStr = queryString.stringify(query);
	const url = `/conversions/query?${queryStr}`;
	const tdpResp = await axios.post(url, payload);

	return tdpResp.data;
}