import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
	'FETCH_SETTINGS_REQUEST',
	'FETCH_SETTINGS_SUCCESS',
	'FETCH_SETTINGS_FAILURE',
    
	'FETCH_GLOBAL_SETTINGS_REQUEST',
	'FETCH_GLOBAL_SETTINGS_SUCCESS',
	'FETCH_GLOBAL_SETTINGS_FAILURE',
    
	'SETTINGS_FORM_SUBMIT_REQUEST',
	'SETTINGS_FORM_SUBMIT_SUCCESS',
	'SETTINGS_FORM_SUBMIT_FAILURE',
	
	'REFRESH_ACTIVITY_STATUS_REQUEST',
	'REFRESH_ACTIVITY_STATUS_SUCCESS',
	'REFRESH_ACTIVITY_STATUS_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
