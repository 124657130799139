import {connect} from 'utils/reduxUtils';
import Component from './Sidebar';
import * as AuthSelectors from 'modules/auth/selectors';

const mapDispatchToProps = {
};

const mapStateToProps = state => {
	return {
		user: AuthSelectors.user(state),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
