import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [   
	'FETCH_ENTITY_CHART_REQUEST',
	'FETCH_ENTITY_CHART_SUCCESS',
	'FETCH_ENTITY_CHART_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
