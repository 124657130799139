import axios from 'services/axios';
import {FetchTagsProps, UpsertTagPayload} from './api.interface';
import queryString from 'query-string';

export async function fetchTags({pagination, filter, sortModel, workspaceIds}: FetchTagsProps) {
	const pageSize = pagination?.pageSize ?? -1;
	const page = pagination?.page ?? 0;
	const offset = pageSize * page;
	const payload = {query: {tag: {...filter.tag}, search: filter.text}};

	if (workspaceIds?.length) {
		payload.query.tag.workspaceId = {op: 'in', value: workspaceIds};
	}

	const orderBy = sortModel?.length ? `tag.${sortModel[0].field}` : '';
	const order = sortModel?.length ? sortModel[0].sort : '';
	const query = {
		limit: pageSize,
		offset,
		orderBy,
		order,
	}
	const queryStr = queryString.stringify(query);
	const url = `/tags/query?${queryStr}`;
	const resp = await axios.post(url, payload);

	return resp.data;
}

export async function fetchSingleTag(id: string) {
	const url = `tags/${id}`;
	const {data} = await axios.get(url);   
	return data;
}

export async function upsertTag({tag}: UpsertTagPayload) {
	const method = tag.id ? axios.patch : axios.post;
	const url = tag.id ? `tags/${tag.id}` : `tags`;
	const {data} = await method(url, tag);   
	return data;
}


export async function deleteTag({id}) {
	const url = `tags/${id}`;
	const {data} = await axios.delete(url);
	return data;
}

export const formatError = (error) => {
	switch (error?.response?.data?.key) {
	default:
		return 'Oops. Request failed';
	}
}
