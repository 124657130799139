import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
	'FETCH_ADS_REQUEST',
	'FETCH_ADS_SUCCESS',
	'FETCH_ADS_FAILURE',

	'FETCH_ADS_PERFORMANCE_REQUEST',
	'FETCH_ADS_PERFORMANCE_SUCCESS',
	'FETCH_ADS_PERFORMANCE_FAILURE',

	'FETCH_SINGLE_AD_REQUEST',
	'FETCH_SINGLE_AD_SUCCESS',
	'FETCH_SINGLE_AD_FAILURE',
    
	'AD_FORM_SUBMIT_REQUEST',
	'AD_FORM_SUBMIT_SUCCESS',
	'AD_FORM_SUBMIT_FAILURE',
    
	'FETCH_SINGLE_AD_PERFORMANCE_REQUEST',
	'FETCH_SINGLE_AD_PERFORMANCE_SUCCESS',
	'FETCH_SINGLE_AD_PERFORMANCE_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
