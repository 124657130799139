import { UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { clearAccessToken } from './authUtils';
import {Actions as AuthActions} from 'modules/auth/actions';
import { filterBy, mergeBy } from './reduxUtils';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

const useDisconnectOnUnauthorized = (query) => {
	const dispatch = useDispatch();
	useEffect(() => {
		if (query?.error?.response?.status === 401) {
			clearAccessToken();
			dispatch(AuthActions.LOGOUT_SUCCESS({reason: 'expired'}))
		}
	}, [dispatch, query?.error?.response?.status])
}
export const useWrappedQuery = <TQueryFnData, TError>({formatError, queryFn, ...options}: Omit<
    UseQueryOptions<TQueryFnData, TError>,
    'initialData'
  > & { formatError?: (error: TError) => string }): UseQueryResult<TQueryFnData, TError> & {formattedError?: string} => {
	const query = useQuery<TQueryFnData, TError>({retry: 1, queryFn, ...options});
	useDisconnectOnUnauthorized(query);

	if (query.isError && formatError) {
		return {...query, formattedError: formatError(query.error)}
	}

	return query;
}

const defaultError = 'Operation Failed'
export const useWrappedMutation = <TData, TError, TVariables, TContext>({formatError, ...props}: UseMutationOptions<TData, TError, TVariables, TContext> & {formatError?: (error: TError) => string}): UseMutationResult<TData, TError, TVariables, TContext> & {formattedError?: string} => {
	const snackbarRef = useSnackbar();
	
	const mutation = useMutation<TData, TError, TVariables, TContext>({
		...props,
	})
	useDisconnectOnUnauthorized(mutation);
	
	useEffect(() => {
		if (mutation.error) {
			snackbarRef.enqueueSnackbar(defaultError, {variant: 'error'})
		}
	}, [snackbarRef, mutation.error])

	if (mutation.isError && formatError) {
		const error = formatError ? formatError(mutation.error) : defaultError;
		return {...mutation, formattedError: error}
	}

	return mutation;
}

export const useMutateArrayItemQuery = <TData, TError, TVariables, TContext>({queryKey, queryListKey, kind, ...props}: UseMutationOptions<TData, TError, TVariables, TContext> & {queryKey?: string[], queryListKey?: string[], kind: string, formatError?: (error: TError) => string}) => {
	const queryClient = useQueryClient();

	const mutation = useWrappedMutation<TData, TError, TVariables, TContext>({
		...props,
		onSuccess: newData => {
			if (queryKey) {
				queryClient.setQueryData(queryKey, newData)
			}
			if (queryListKey) {
				const cachedQueries = queryClient.getQueriesData<any>(queryListKey) || [];
				cachedQueries.forEach(([actualKey, itemsResp]: any) => {
					if (!itemsResp) {return;}
					switch (kind) {
					case 'upsert':
						queryClient.setQueryData(actualKey, {...itemsResp, items: mergeBy(itemsResp.items, newData)});
						break;
					case 'delete':
						queryClient.setQueryData(actualKey, {...itemsResp, items: filterBy(itemsResp.items, newData)});
						break;
					default:
						break;
					}
				})
			}
		},
	})

	return mutation;
}
