import * as Yup from 'yup';

// Yup.addMethod(Yup.string, 'domain', function(message) {
//   const msg = message || 'Not a valid domain';
//   return this.test('domain', msg, function (value) {
//     const validHostRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
//     return !value || validHostRegex.test(value);
//   })
// })

export default Yup;

const nullableNum = Yup.number().min(0).nullable(true).transform(v => (v === '' || Number.isNaN(v)) ? null : v);
export const validations = {
	rangeTemplate: Yup.string().trim().matches(/{{range}}/i, 'Must contain {{range}} template part'),
	siteUrl: Yup.string().trim()
		.matches(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//={}[\]]*)$/i, 'Not a valid url')
		.matches(/{{redirect}}/, 'Must contain {{redirect}} template part'),
	offerUrl: Yup.string().trim()
		.matches(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//={}[\]]*)$/i, 'Not a valid url'),
	nullableNum: nullableNum,
	nullablePercent: nullableNum.max(100),
}
