import _ from 'lodash';
import { Workspace, useWorkspaces } from "context/WorkspaceContext";
import { fetchWorkspaceOverview } from "modules/trafficHourlyPerformance/api";
import { useEffect, useState } from "react";
import { useWrappedQuery } from "utils/reactQueryHooks";
import { EnhancedDataGrid } from 'components/DataGrid/EnhancedDataGrid';
import { ResponsivePaper } from 'components/ResponsivePaper/ResponsivePaper';
import { GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { Flex } from 'components/Flex/Flex';
import { formatNum } from 'utils/transformUtils';

function getData(row, prop) {
	if (typeof prop === 'function') {
		return prop(row);
	}
	return _.get(row, prop);
}
function getOffers(row) {
	if (!row.dailyTotalOffers && !row.dailyLiveOffers) {return ''}
	
	return `${row.dailyTotalOffers || ''} / ${row.dailyLiveOffers || ''}`
}
function WorkspaceColumn({row, prop, type, sx = {}}) {
	let rawVal = getData(row, prop);
	const val = type === 'number' ? formatNum(rawVal) : rawVal;
	return (
		<Flex
			gap={0}
			sx={{height: '100%', fontSize: 12, lineHeight: 1}}
			justify="space-around"
			align="flex-start"
			direction="column"
		>
				
			<Box sx={{...sx}}>{val}</Box>
		</Flex>
	)
}
function getColumns(workspaces): GridColDef[] {
	return [
		{ field: `date`, headerName: `Date`, width: 120, type: 'string', valueFormatter: (value: any) => value.slice(0, 10)},	
		{ field: `workspaces`, headerName: `WS`, width: 100, type: 'number', headerAlign: 'left', renderCell: ({row}) => (<WorkspaceColumn row={row} prop="workspace.name" type="string" />)},
		{ field: `totalOffers`, headerName: `Daily Offers`, width: 100, type: 'number', headerAlign: 'left', renderCell: ({row}) => (<WorkspaceColumn row={row} prop={getOffers} type="string" />)},
		{ field: `activeLiveOffers`, headerName: `Live Offers`, width: 100, type: 'number', headerAlign: 'left', renderCell: ({row}) => (<WorkspaceColumn row={row} prop="activeLiveOffers" type="number" />)},
		{ field: `realProfit`, headerName: `Real Profit`, description: '60% of profit', width: 100, type: 'number', headerAlign: 'left', renderCell: ({row}) => (<WorkspaceColumn row={row} prop={r => r.profit * 0.6} type="number" sx={{fontWeight: 'bold'}} />)},
		{ field: `profit`, headerName: `Profit`, width: 100, type: 'number', headerAlign: 'left', renderCell: ({row}) => (<WorkspaceColumn row={row} prop="profit" type="number" />)},
		{ field: `cost`, headerName: `Cost`, width: 100, type: 'number', headerAlign: 'left', renderCell: ({row}) => (<WorkspaceColumn row={row} prop="cost" type="number" />)},
		{ field: `revenue`, headerName: `Revenue`, width: 100, type: 'number', headerAlign: 'left', renderCell: ({row}) => (<WorkspaceColumn row={row} prop="revenue" type="number" />)},
		{ field: `lroi`, headerName: `LROI`, width: 100, type: 'number', headerAlign: 'left', renderCell: ({row}) => (<WorkspaceColumn row={row} prop="lroi" type="number" />)},
		{ field: `roi`, headerName: `ROI`, width: 100, type: 'number', headerAlign: 'left', renderCell: ({row}) => (<WorkspaceColumn row={row} prop="roi" type="number" />)},
		{ field: `defaultConversions`, headerName: `Conversions`, width: 100, type: 'number', headerAlign: 'left', renderCell: ({row}) => (<WorkspaceColumn row={row} prop="defaultConversions" type="number" />)},
		{ field: `visits`, headerName: `Visits`, width: 100, type: 'number', headerAlign: 'left', renderCell: ({row}) => (<WorkspaceColumn row={row} prop="visits" type="number" />)},
	]
}
const sortModel: GridSortModel = [{field: 'date', sort: 'desc'}];

export function WorkspaceOverview({filter}) {
	const [stats, setStats] = useState<any>({})
	const {isFetching, data: rawStats, refetch} = useWrappedQuery({
		keepPreviousData: true,
		queryKey: ['fetchWorkspaceOverview', filter.dateRange],
		queryFn: () => fetchWorkspaceOverview({filter: filter}),
		// initialData: {items: [], pagination: {total: 0}, totals: {}}
	});
	const {workspaces} = useWorkspaces();
	const filteredWorkspaces = workspaces?.filter((ws: Workspace) => !filter.workspaces.length || filter.workspaces.includes(ws.id));

	useEffect(() => {
		if (!rawStats?.length) {
			setStats([]);
			return;
		};
		const statsByWSId = _.groupBy(rawStats, 'workspace.id');

		// const statsByWSId = _.groupBy(rawStats, 'workspace.id');
		// const totals = _.mapValues(statsByWSId, group => {
		// 	group.reduce((aggr, itm) => {}, {})
		// })

		setStats(statsByWSId);
	}, [rawStats, filter.workspaces])

	return (
		<Flex direction='column' gap={2} sx={{flex: 1}}>
			{filteredWorkspaces.map(ws => (
				<ResponsivePaper key={ws.id} sx={{width: '100%'}}>
					<Box sx={{fontSize: 24}}>{ws.name} Daily Stats</Box>
					<EnhancedDataGrid
						items={stats[ws.id]}
						loading={isFetching}
						pluralName='Stats'
						density='compact'
						rowHeight={35}
						getRowId={(row) => row.date}
						columns={getColumns(filteredWorkspaces)}
						noToolbar
						hideFooter
						sortModel={sortModel}
						pagination={false}
						paginationMode='client'
						slotProps={{
							toolbar: {
								noSearch: true,
							},
						}}
					/>
				</ResponsivePaper>
			))}
		</Flex>
	)
}