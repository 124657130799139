import { TextField, Box } from "@mui/material"
import TooltipButton from "components/TooltipButton/TooltipButton"
import {
	Help as HelpIcon,
} from '@mui/icons-material';

export function CustomizedTextField({explain, sxWrap, ...props}) {
	return (
		<Box sx={{display: 'flex', alignItems: 'center', gap: 1, ...sxWrap}}>
			<TextField {...props} value={props.value ?? ''} sx={{flex: 1, ...props.sx}} />
			{explain && <TooltipButton
				size="small"
				// iconProps={{sx: {fontSize: 14} }}
				icon={HelpIcon} title={explain}
			/>}
		</Box>
	)
}
