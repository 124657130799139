import {Tooltip, IconButton, Paper, Box, Typography} from '@mui/material';
import {
	Add as AddIcon,
	Edit as EditIcon,
	CallSplit as CallSplitIcon,
} from '@mui/icons-material';
import _ from 'lodash';

// components
import PageTitle from "../../components/PageTitle";
import { useAvailableWindowHeight, useModalState, useQueryableFilter } from "utils/hooks";
import config from "config/config";
import { useMutateArrayItemQuery, useWrappedQuery } from "utils/reactQueryHooks";
import { fetchTrafficSources, upsertTrafficSource } from "modules/trafficSources/api";
import { EnhancedDataGrid } from "../../components/DataGrid/EnhancedDataGrid";
import { TrafficSource } from "modules/trafficSources/api.interface";
import { useWorkspaces } from 'context/WorkspaceContext';
import { useState } from 'react';

function getColumns({renderActionsColumn}) {
	return [
		{ field: 'name', headerName: 'Name', filterable: false, minWidth: 350, flex: 1},
		{ field: 'comments', headerName: 'Comments', minWidth: 350, flex: 1, editable: true},
		{ field: 'actions', headerName: 'Actions', sortable: false, filterable: false, renderCell: renderActionsColumn, width: 120},
	]
}

function ActionsColumn({row, onEditClick}) {
	return (
		<>
			<Tooltip arrow title="Edit TrafficSource">
				<IconButton
					aria-label="Edit TrafficSource"
					sx={{p: 0.5}}
					onClick={() => onEditClick(row)}
					size="medium">
					<EditIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
		</>
	)
}

const defaultFilter = {text: ''};
const defaultSortModel = [{field: 'name', sort: 'asc'}]
export function TrafficSourcesPage() {
	const {filter, pagination, sortModel, setFilter, setPagination, setSortModel} = useQueryableFilter({defaultFilter, defaultPageSize: config.paginationDefaultValue, defaultSortModel});
	const gridHeight = useAvailableWindowHeight()
	const {setModal: setTrafficSourceModal} = useModalState('trafficSource')
	const {selectedWorkspaces} = useWorkspaces();
	const [inlineTrafficSourceId, setInlineTrafficSourceId] = useState<string>();

	const {isLoading: isTrafficSourcesLoading, data: trafficSourcesResponse} = useWrappedQuery({
		queryKey: ['trafficSourcesList', filter, pagination, sortModel, selectedWorkspaces.ids],
		queryFn: () => fetchTrafficSources({
			pagination,
			sortModel: sortModel,
			filter: {text: filter.text},
			workspaceIds: selectedWorkspaces.ids,
		}),
	});

	const saveTrafficSourceQuery = useMutateArrayItemQuery({
		kind: 'upsert',
		mutationFn: (data: any) => upsertTrafficSource({trafficSource: data}),
		queryKey: inlineTrafficSourceId ? ['trafficSource', inlineTrafficSourceId] : [],
		queryListKey: ['trafficSourcesList'],
	})

	function openTrafficSourceModal(id?: string, reason?: string) {
		setTrafficSourceModal({id, open: true, reason})
	}

	function renderActionsColumn(params) {
		return (
			<ActionsColumn row={params.row}
				onEditClick={() => openTrafficSourceModal(params.row.id, 'Update')}
			/>
		);
	}

	function handleDoubleCellClick(params) {
		if (!['actions', 'comments'].includes(params.field)) {
			openTrafficSourceModal(params.row.id, 'Update')
		}
	}

	function handleUpdatedInline(trafficSource) {
		setInlineTrafficSourceId(trafficSource.id);
		saveTrafficSourceQuery.mutateAsync(trafficSource)
			.catch(console.error)
	}

	return (
		<>
			<PageTitle title="Traffic Sources" icon={CallSplitIcon} />
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
				<Paper sx={{height: gridHeight}}>
					<EnhancedDataGrid<TrafficSource>
						items={trafficSourcesResponse?.items}
						loading={isTrafficSourcesLoading}
						columns={getColumns({renderActionsColumn})}
						rowCount={trafficSourcesResponse?._meta?.total}
						checkboxSelection
						pagination
						paginationMode="server"
						paginationModel={pagination}
						onPaginationModelChange={newModel => setPagination(newModel)}
						sortingMode="server"
						sortModel={sortModel}
						onSortModelChange={setSortModel}
						pageSizeOptions={config.paginationOptions}
						editMode='cell'
						onProcessRowUpdateError={console.log}
						processRowUpdate={(newRow, oldRow) => {
							const validFields = ['comments'];
							const toUpdate = _.pickBy(newRow, (val, key) => validFields.includes(key) && newRow[key] !== oldRow[key])
							if (!_.isEmpty(toUpdate)) {
								handleUpdatedInline({...toUpdate, id: newRow.id});
							}
							return newRow
						}}
						pluralName="TrafficSources"
						filter={filter}
						setFilter={setFilter}
						showHiddenToggle
						// actionSubmitted={trafficSourceJustSubmitted}
						// actionSubmitting={trafficSourceSubmitting}
						actions={[{
							icon: AddIcon,
							primary: true,
							hint: 'Add new trafficSource',
							onClick: () => openTrafficSourceModal(),
						}]}
						onCellDoubleClick={handleDoubleCellClick}
					/>
				</Paper>
			</Box>
		</>
	)
}
