import React, { useEffect } from "react";
import { useIsMobile } from "utils/hooks";

var LayoutStateContext = React.createContext();
var LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
	switch (action.type) {
	case "TOGGLE_SIDEBAR":
		return { ...state, isSidebarOpened: action.newState ?? !state.isSidebarOpened }
	case "SET_SIDEBAR":
		return { ...state, isSidebarOpened: action.isOpen };
	default: {
		throw new Error(`Unhandled action type: ${action.type}`);
	}
	}
}

function LayoutProvider({ children }) {
	const isMobile = useIsMobile();
	const [state, dispatch] = React.useReducer(layoutReducer, {
		isSidebarOpened: true,
	});
	useEffect(() => {
		dispatch({type: 'SET_SIDEBAR', isOpen: !isMobile})
	}, [isMobile])
	return (
		<LayoutStateContext.Provider value={state}>
			<LayoutDispatchContext.Provider value={dispatch}>
				{children}
			</LayoutDispatchContext.Provider>
		</LayoutStateContext.Provider>
	);
}

function useLayoutState() {
	var context = React.useContext(LayoutStateContext);
	if (context === undefined) {
		throw new Error("useLayoutState must be used within a LayoutProvider");
	}
	return context;
}

function useLayoutDispatch() {
	var context = React.useContext(LayoutDispatchContext);
	if (context === undefined) {
		throw new Error("useLayoutDispatch must be used within a LayoutProvider");
	}
	return context;
}

const drawerWidth = 190;

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar, drawerWidth };

// ###########################################################
function toggleSidebar(dispatch, newState) {
	dispatch({
		type: "TOGGLE_SIDEBAR",
		newState,
	});
}
