import _ from 'lodash';
import queryString from 'query-string';
import {ActionTypes} from './actions';

const getFailureMessage = (action) => {
	const errorCode = _.get(action.payload, 'response.data.key');
	switch (errorCode) {
	case 'noMatchUserPass':
		return 'Invalid username and password';
	default:
		return 'Oops request failed'; 
	}
}

const query = queryString.parse(window.location.search, {ignoreQueryPrefix: true})

const authReducer = (state = {
	loading: false,
	loginFailureMessage: query.error || false,
	authComplete: false,
	isLoggedIn: false,
	user: null,
	userRoles: null,

}, action) => {
	let nextState;
	const {type, payload} = action;
	switch (type) {
	case ActionTypes.LOGIN_REQUEST:
		nextState = {...state, loading: true, loginFailureMessage: false};
		break;
	case ActionTypes.LOGIN_SUCCESS:
		nextState = {...state, user: payload, isLoggedIn: true, loading: false, authComplete: true};
		break;
	case ActionTypes.LOGOUT_SUCCESS:
		nextState = {isLoggedIn: false, authComplete: true, loginFailureMessage: state.loginFailureMessage};
		break;
	case ActionTypes.LOGIN_FAILURE:
		nextState = {...state, isLoggedIn: false, loading: false, loginFailureMessage: getFailureMessage(action)};
		break;
	case ActionTypes.GET_USER_ROLES_REQUEST:
		nextState = {...state, loading: true, loginFailureMessage: false};
		break;
	case ActionTypes.GET_USER_ROLES_SUCCESS:
		nextState = {...state, userRoles: payload, loading: false};
		break;
	case ActionTypes.GET_USER_ROLES_FAILURE:
		nextState = {...state, loading: false, loginFailureMessage: getFailureMessage(action)};
		break;
	default:
		nextState = state;
	}
	return nextState;
};

export default authReducer;
