import axios from 'services/axios';
import {FetchOfferStatsProps} from './api.interface';
import queryString from 'query-string';


export async function fetchOfferStats({dateRange, workspaceId}: FetchOfferStatsProps) {

	const query = {
		fromDate: dateRange[0],
		toDate: dateRange[1],
	}
	const queryStr = queryString.stringify(query);
	const url = `/offerStats?${queryStr}`;
	const resp = await axios.post(url, {workspaceId});

	return resp.data
}
