import _ from 'lodash';
import { useCallback, useEffect, useState } from "react";
import {DialogActions, DialogContent, Button, Box, Alert} from '@mui/material';
import Yup from 'services/yup';

// components
import { useModalState } from "utils/hooks";
import { Formik, Field } from 'formik';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import PromptIfDirty from 'components/PromptIfDirty/PromptIfDirty';
import {
	Add as AddIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,

} from '@mui/icons-material';
import DraggableDialog from "components/DraggableDialog";
import FormikTextField from 'components/Form/FormikTextField';
import { CopyId } from 'components/CopyId';
import { useMutateArrayItemQuery, useWrappedQuery } from 'utils/reactQueryHooks';
import { fetchSingleWorkspace, upsertWorkspace, formatError } from 'modules/workspaces/api';
import { AsyncAutoCompleteFormik } from 'components/Form/AsyncAutoCompleteFormik';
import { fetchCampaigns } from 'utils/autoComplete';
import { useWorkspaces } from 'context/WorkspaceContext';

function getSchema() {
	let schema = Yup.object().shape({
		name: Yup.string().trim().required(),
		config: Yup.object().shape({
		}),
		globalCampaignId: Yup.string().nullable(),
		color: Yup.string().nullable(),
		order: Yup.number().min(0).nullable(),
	})
	return schema;
}

const initalValues = {
	name: '', globalCampaignId: null, color: null,
}


export function CreateEditWorkspace() {
	const {modalState: workspaceModal, toggleModal: toggleWorkspaceModal} = useModalState('workspace')

	const {workspaces} = useWorkspaces();
	const workspaceQuery = useWrappedQuery({
		enabled: !!workspaceModal.id,
		queryFn: () => fetchSingleWorkspace(workspaceModal.id),
		queryKey: ['workspace', workspaceModal.id],
	})

	const rawWorkspace = workspaceQuery.data;
	const workspace = (rawWorkspace && workspaceModal.reason === 'Duplicate') ? _.omit(rawWorkspace, ['id']) : rawWorkspace;
	
	const closeModal = useCallback(function closeModal() {
		toggleWorkspaceModal(false);
	}, [toggleWorkspaceModal]);
	
	// useEffect(() => {
		
	// }, [workspaceModal.open])
	const saveWorkspaceQuery = useMutateArrayItemQuery({
		kind: 'upsert',
		mutationFn: (data: any) => upsertWorkspace({workspace: data}),
		queryKey: workspaceModal.id && ['workspace', workspaceModal.id],
		queryListKey: ['workspacesList'],
		formatError,
	})
	const resetQuery = saveWorkspaceQuery.reset;
	const fieldsDisabled = workspaceQuery.isFetching || saveWorkspaceQuery.isLoading
	const submitError = saveWorkspaceQuery.formattedError;
		
	useEffect(() => {
		if (workspaceModal.open) {
			resetQuery();
		}
	}, [workspaceModal, resetQuery])
      
	function handleFormSubmit(values) {
		const castValues = getSchema().cast({
			...values,
		});
		saveWorkspaceQuery.mutateAsync(castValues)
			.then(() => closeModal())
	}

	const handleClose = (event, reason) => {
		if (reason === "backdropClick") 
			return;
		closeModal();
	}
	const title = 
		workspaceQuery.isFetching ?
			'Loading...' :
			workspaceModal.id ? `${workspaceModal.reason} Workspace ${workspace?.n || ''}` : "Create New Workspace";
	return (
		<DraggableDialog
			open={workspaceModal.open}
			onClose={handleClose}
			fullWidth
			title={title}
			titleId="workspace-dialog-title"
			titleSx={{bgcolor: 'primary.main', color: t => t.palette.primary.contrastText}}
		>
			<Formik
				initialValues={_.merge({order: workspaces?.length || 0}, initalValues, workspace)}
				enableReinitialize
				validationSchema={getSchema()}
				onSubmit={(values, { setSubmitting }) => {
					handleFormSubmit(values);
					setSubmitting(false);
				}}
			>
				{({ submitForm, values }) => {
					return (
						<>
							<DialogContent dividers>
								<CopyId id={workspace?.id} sx={{mb: 1}} />
								<Box component="form" sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 600}}>
									<PromptIfDirty />
									<Field
										component={FormikTextField}
										name="name"
										type="text"
										label="Workspace name"
										disabled={fieldsDisabled}
										size="small"
									/>
									<AsyncAutoCompleteFormik
										entityName='campaign'
										limit={15}
										getItems={fetchCampaigns}
										label="Global Campaign"
										name="globalCampaignId"
										disabled={fieldsDisabled}
										sx={{flex: 1}}
										size="small"
									/>
									<Field
										component={FormikTextField}
										type="number"
										label="Order"
										name="order"
										disabled={fieldsDisabled}
										InputProps={{inputProps: {min: 0}}}
										size="small"
									/>
									<Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
										<Field
											component={FormikTextField}
											name="color"
											type="text"
											label="Tag color"
											sxWrap={{flex: 1}}
											disabled={fieldsDisabled}
											size="small"
										/>
										<Box sx={{bgcolor: values.color, width: 35, height: 35, borderRadius: 50}} />
									</Box>
									{submitError && <Alert severity="error">{submitError}</Alert>}
								</Box>
							</DialogContent>
							<DialogActions>
								<Button onClick={closeModal}>Cancel</Button>
								<ProgressButton
									variant="contained"
									color="primary"
									sxWrap={{alignSelf: 'flex-start'}}
									onClick={submitForm}
									disabled={fieldsDisabled}
									loading={saveWorkspaceQuery.isLoading}
								>
									Save
								</ProgressButton>
							</DialogActions>
						</>
					)
				}}
			</Formik>
		</DraggableDialog>
	)
}
