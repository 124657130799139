import _ from 'lodash';
import {format as dateFormatter} from 'date-fns';

export function formatNum(num, floating = 2, notation: 'standard' | 'compact' = "standard") {
	if (_.isNil(num)) {return num;}
	return Number(num).toLocaleString('en', {maximumFractionDigits: floating, notation})
}

export function formatDate(dateStr, format = 'yyyy-MM-dd') {
	if (!dateStr) {return ''}
	
	const date = new Date(dateStr);
	return dateFormatter(date, format)
}

export function formatUTCDate(dateStr) {
	const utcStr = toUTC(dateStr);
	if (!utcStr) {return utcStr}

	return utcStr.slice(0, 10);
}

export function formatDateTime(isoStr) {
	if (!isoStr) {return ''}
	
	const date = new Date(isoStr);
	return dateFormatter(date, 'yyyy-MM-dd HH:mm:ss');
}

export function dateStringToISODate(dateString) {
	const date = new Date(dateString)
	if (!date) {
		return null;
	}
	return date.toISOString();
}

export function toUTC(dateOrStr): string {
	if (!dateOrStr) {
		return dateOrStr;
	}
	const date = new Date(dateOrStr)
	const userTimezoneOffset = date.getTimezoneOffset() * 60000;
	return new Date(date.getTime() - userTimezoneOffset).toISOString();
}

export function joinByAnds(strArray) {
	const filtered = strArray.filter(_.identity)
	const last = filtered.slice(-1);
	const notLast = filtered.slice(0, -1);
	
	if (filtered.length <= 1) {
		return filtered[0];
	}
	const notJoinedStr = notLast.join(', ')
	return `${notJoinedStr} and ${last}`;
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export const numberFormatter = new Intl.NumberFormat('en-US');

export const getDataGridPercentageProps = field => ({
	valueFormatter: ({value, id}) => id === '_total' ? '' : `${Number(value).toFixed(2)}%`,
	valueGetter: ({value}) => (value * 100).toFixed(2),
	valueSetter: (params) => {
		return ({...params.row, [field]: params.value / 100})
	},
})

export const getRoundedPercent = (numerator, denumerator = 1, decimal = 2) => {
	return parseFloat(((numerator / denumerator) * 100).toFixed(decimal));
}

export const roundNumber = <T extends (null | number)>(number: T, decimal = 2): T => {
	if (!number) {return number;}
	return Number(number.toFixed(decimal)) as T
}

export const resolveContextTemplate = (template, channel, publisher) => {
	return template
		.replace(/{{gd}}/g, channel.gd)
		.replace(/{{n}}/g, channel.n)
		.replace(/{{prefix}}/g, channel.prefix || channel.gd)
		.replace(/{{kindFirst}}/g, channel.kind[0])
		.replace(/{{publisherSlug}}/g, publisher.slug)
}

const omitNullablesImpl = (source, nullables) => {
	if (!source) {return source;}
	const result = _.omit(source, nullables);
	nullables.forEach(n => {
		if (!_.isNil(source[n])) {
			result[n] = source[n]
		}
	})
	return result;
}

export const omitNullables = (source, nullables) => {
	if (!source) {return source;}
	if (Array.isArray(source)) {
		return source.map(a => omitNullablesImpl(a, nullables));
	}
	return omitNullablesImpl(source, nullables);
}

export const getGridNumberFormatter = (options: {decimal?: number, suffix?: string, notation?: 'standard' | 'compact'} = {decimal: 2}) => {
	return {
		valueFormatter: (value) => Number.isFinite(value) ? `${formatNum(value, options.decimal, options.notation)}${options.suffix ?? ''}` : value,
	}
}


export const divide = (divisor: number, dividend: number, ifNull = 0) => {
	if (!dividend) {return ifNull}
	return divisor / dividend;
}
