import axios from 'axios';
import { retrieveAccessToken } from 'utils/authUtils';
import config from 'config/config';

// const serverHost = config.isDebug ? window.location.origin : config.serverHost
const baseURL = `${window.location.origin}/api`;

const axiosInstance = axios.create({baseURL});

axiosInstance.interceptors.request.use(async request => {
	const accessToken = await retrieveAccessToken();
	request.headers.Authorization = `Bearer ${accessToken}`;
	return request;
});

export default axiosInstance;
