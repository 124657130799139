const style = {
	link: {
		textDecoration: "none",
		"&:hover, &:focus": {
			backgroundColor: theme => theme.palette.background.light,
		},
	},
	linkActive: {
		backgroundColor: theme => theme.palette.background.light,
	},
	linkNested: {
		paddingInlineStart: 0,
		"&:hover, &:focus": {
			backgroundColor: "#FFFFFF",
		},
	},
	linkIcon: {
		color: theme => theme.palette.text.secondary + "99",
		transition: theme => theme.transitions.create("color"),
		width: 24,
		display: "flex",
		justifyContent: "center",
	},
	linkIconActive: {
		color: theme => theme.palette.primary.main,
	},
	linkText: {
		padding: 0,
		color: theme => theme.palette.text.secondary + "CC",
		transition: theme => theme.transitions.create(["opacity", "color"]),
		fontSize: 16,
	},
	linkTextActive: {
		color: theme => theme.palette.text.primary,
	},
	linkTextHidden: {
		display: 'none',
	},
	nestedList: {
		paddingInlineStart: theme => theme.spacing(2) + 30,
	},
	sectionTitle: {
		marginRight: theme => theme.spacing(4.5),
		marginTop: theme => theme.spacing(2),
		marginBottom: theme => theme.spacing(2),
	},
	divider: {
		marginTop: theme => theme.spacing(2),
		marginBottom: theme => theme.spacing(4),
		height: 1,
		backgroundColor: "#D8D8D880",
	},
};
export default style;