import _ from 'lodash';
import { Box, Typography } from "@mui/material";
import { formatNum } from "utils/transformUtils";
import { 
	ArrowDropUp as ArrowDropUpIcon,
	ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';
import { Campaign } from 'modules/campaigns/api.interface';
import { TrafficSource } from 'modules/trafficSources/api.interface';

function StatValueFormatter(props) {
	const {value, isPercent, decimal, coloredBox} = props;
	if (!Number.isFinite(value)) return value;

	const finalValue = isPercent ? value * 100 : value;

	let formattedNum = formatNum(finalValue, decimal)
	if (isPercent) {
		formattedNum = `${formattedNum}%`
	}
	let typographySX = {}
	if (coloredBox) {
		const bgcolor = finalValue > 0 ? '#269f26' : finalValue < 0 ? '#ce2222' : '#f1f1f1'
		typographySX = {p: '2px', px: '4px', fontSize: 12, bgcolor: bgcolor, color: t => t.palette.getContrastText(bgcolor)}
	}

	return (<Typography variant="body1" sx={typographySX}>{formattedNum}</Typography>)
}

function StatsValueCell({row, column, compareEnabled, ...props}) {

	const value = row.stats?.[column];
	const formattedValue = <StatValueFormatter value={value} {...props}  />
	
	if (!compareEnabled) {return formattedValue}
	
	const compareValue = row.compareStats ? row.compareStats[column] : null;
	const formattedCompare = <StatValueFormatter value={compareValue} {...props}  />

	let icon;
	const iconSx = {position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}
	if (!_.isNil(compareValue) && !_.isNil(value) && compareValue !== value) {
		icon = value > compareValue ? <ArrowDropUpIcon color="success" sx={iconSx} /> : <ArrowDropDownIcon color="error" sx={iconSx} />
	}
	return (<Box sx={{py: 1, display: 'flex', flexDirection: 'column', gap: 1, position: 'relative', pr: 4}}>
		{formattedValue}{formattedCompare}{icon}
	</Box>);
}

const defaultProps = {
	type: 'number',
	align: 'left',
	headerAlign: 'left',
}
export function getStatsColumns({compareEnabled} = {compareEnabled: false}) {
	const getNumberFormatter = (column, options = {}) => ({
		valueGetter: ({row}) => row.stats?.[column],
		renderCell: ({row}) => <StatsValueCell {...options} compareEnabled={compareEnabled} column={column} row={row} />,
	})

	return [
		{ field: 'tsCost', headerName: 'TS Cost', ...defaultProps, ...getNumberFormatter('tsCost')},
		{ field: 'mpRevenue', headerName: 'MP Revenue', ...defaultProps, ...getNumberFormatter('mpRevenue')},
		{ field: 'net', headerName: 'Net', ...defaultProps, ...getNumberFormatter('net', {coloredBox: true})},
		{ field: 'roi', headerName: 'ROI', ...getNumberFormatter('roi', {isPercent: true})},
		{ field: 'cvr', headerName: 'CVR', ...defaultProps, ...getNumberFormatter('cvr', {decimal: 3, isPercent: true})},
		{ field: 'tsCPC', headerName: 'TS CPC', ...defaultProps, ...getNumberFormatter('tsCPC', {decimal: 3})},
		{ field: 'tsCPM', headerName: 'TS CPM', ...defaultProps, ...getNumberFormatter('tsCPM')},
		{ field: 'rpm', headerName: 'RPM', ...defaultProps, ...getNumberFormatter('rpm', {decimal: 3})},
		{ field: 'mpConversions', headerName: 'MP Conversions', ...defaultProps, ...getNumberFormatter('mpConversions')},
		{ field: 'tsClicks', headerName: 'TS Clicks', ...defaultProps, ...getNumberFormatter('tsClicks')},
		{ field: 'cpa', headerName: 'CPA', ...defaultProps, ...getNumberFormatter('cpa')},
		{ field: 'payout', headerName: 'Payout', ...defaultProps, ...getNumberFormatter('payout', {decimal: 3})},
		{ field: 'epc', headerName: 'EPC', ...defaultProps, ...getNumberFormatter('epc', {decimal: 3})},
		{ field: 'tsImpressions', headerName: 'TS Impressions', ...defaultProps, ...getNumberFormatter('tsImpressions')},
		{ field: 'tsCTR', headerName: 'TS CTR', ...defaultProps, ...getNumberFormatter('tsCTR', {decimal: 3, isPercent: true})},
		{ field: 'uv', headerName: 'UV', ...defaultProps, ...getNumberFormatter('uv', {decimal: 3})},
	]
}


export function getCampaignLink(campaign: Campaign, trafficSource: TrafficSource, globalSettings) {
	const tsParams = trafficSource.config.params
	const allParams = [tsParams.clickId, tsParams.cost, ...tsParams.custom]
	const paramsStr = allParams.map((param) => `${param.tsName}=${param.tsToken}`).join('&')
	const host = globalSettings?.host?.trackingService || '<<missing>>';
	const url = `${host}/r/${campaign.id}/?${paramsStr}`
	return url;
}