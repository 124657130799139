import _ from 'lodash';
import { Box, Chip } from "@mui/material";
import { DataGridPro, GridColDef, GridOverlay, GridSortModel } from "@mui/x-data-grid-pro";
import ConfirmDialog from "components/ConfirmDialog"
import DateRangePicker from "components/Form/DateRangePicker";
import GridToolBar from "components/GridToolBar";
import { startOfDay, subDays } from "date-fns";
import { useCallback, useState } from "react";
import { useWrappedQuery } from "utils/reactQueryHooks";
import { getGridNumberFormatter } from "utils/transformUtils";
import config from 'config';
import { EmptyState } from "components/EmptyState";
import { useModalState } from 'utils/hooks';
import { fetchTrafficHourlyPerformance } from 'modules/trafficHourlyPerformance/api';

const numCol = getGridNumberFormatter({decimal: 1})
const columns = [
	'offer', 'date', 'campaigns', 'visits', 'revenue', 'defaultConversions', 'cost', 'profit', 'cvr', 'mttc',
]
function getColumns(): GridColDef[] {
	return [
		{ field: 'date', minWidth: 100, valueFormatter: (value: any) => value?.slice(0, 10)},
		{ field: 'offer', flex: 1, valueGetter: (value, row) => row.offer?.name},
		{ field: 'campaigns', headerName: 'Campaigns', minWidth: 80, flex: 4, sortable: false, renderCell: ({row}) => {
			const channels = _.uniq(row.campaigns?.map(f => f.name)) || [];
			return channels.map(channel => <Chip key={`${channel}`} label={`${channel}`} size="small" />)
		}},
		{ field: 'visits', headerName: 'Visits', minWidth: 75, flex: 1,  ...numCol},
		{ field: 'defaultConversions', headerName: 'Conversions', minWidth: 75, flex: 1,  ...numCol},
		// { field: 'reportedSearches', headerName: 'T Srch', description: 'Reported Searches', minWidth: 75, flex: 1,  ...numCol},
		// { field: 'reportedMonetizedSearches', headerName: 'M Srch', description: 'Reported Monetized Searches', minWidth: 75, flex: 1,  ...numCol},
		// { field: 'reportedClicks', headerName: 'Clicks', minWidth: 80, flex: 1, ...numCol},
		// { field: 'actualRPM', headerName: 'I\'RPM', description: 'Calc from G\'Srch', minWidth: 75, flex: 1, ...numCol},
		// { field: 'filter', headerName: 'Filter', minWidth: 75, flex: 1, ...perCol},
		// { field: 'coverage', headerName: 'Cov', minWidth: 75, flex: 1, ...perCol},
		// { field: 'ctr', headerName: 'CTR', minWidth: 75, flex: 1, ...perCol},
		{ field: 'revenue', headerName: 'Rev', minWidth: 75, flex: 1,  ...numCol},
		{ field: 'cost', headerName: 'Cost', minWidth: 75, flex: 1,  ...numCol},
		{ field: 'profit', headerName: 'Profit', minWidth: 75, flex: 1,  ...numCol},
		{ field: 'cvr', headerName: 'CVR', minWidth: 75, flex: 1,  ...numCol},
		{ field: 'mttc', headerName: 'MTTC', minWidth: 75, flex: 1,  ...numCol},
		// { field: 'reportedTQ', headerName: 'TQ', minWidth: 75, flex: 1,  ...numCol},
		// { field: 'followOnSearchesPercent', headerName: 'FoS', minWidth: 75, flex: 1,  ...perCol},
	]
}

const defaultSortModel: GridSortModel = [{field: 'date', sort: 'desc'}]
export function OfferRevenueStatsModal() {
	const now = new Date();
	const [pagination, setPagination] = useState({page: 0, pageSize: config.paginationDefaultValue})
	const [sortModel, setSortModel] = useState(defaultSortModel)
	const [filter, setFilter] = useState({
		dateRange: [subDays(startOfDay(now), 10), startOfDay(now)] as [Date, Date],
		countries: ['US'],
	})
	const {modalState, toggleModal} = useModalState('offerStats')
	const {isLoading, data: dailyStatsResp} = useWrappedQuery({
		enabled: !!(modalState?.open && modalState?.offerId),
		queryKey: ['offerRevenueStats', modalState?.offerId, modalState?.offerId, filter, pagination, sortModel],
		queryFn: () => fetchTrafficHourlyPerformance({
			columns, sortModel, pagination,
			filter: {
				...filter,
				trafficHourlyPerformance: {
					offerId: modalState?.offerId,
				},
			},
		}),
	});

	const closeModal = useCallback(function closeModal() {
		toggleModal(false);
	}, [toggleModal]);

	const title = modalState?.offerName ? `Offer ${modalState?.offerName} Stats` : `Offer Stats`;
	return (
		<ConfirmDialog
			open={modalState?.open}
			// alert={asSubmitError}
			maxWidth="xl"
			fullWidth
			confirmText="Close"
			title={title}
			onConfirm={closeModal}
			onCancel={closeModal}
			cancelText={null}
			customContent={(
				<Box sx={{height: 700}}>
					<DataGridPro
						slots={{
							toolbar: GridToolBar,
							noRowsOverlay: () => <EmptyState size="medium" isEmpty text="Stats not found" sx={{container: {height: '100%'}}} />,
							noResultsOverlay: () => <GridOverlay>No results. Try changing the filter</GridOverlay>,
						}}
						loading={isLoading}
						density='compact'
						getRowId={(row) => row.id || `${row.offer.id}_${row.date}`}
						rows={dailyStatsResp?.items || []}
						pinnedRows={{bottom: [{id: '_totals', ...dailyStatsResp?._meta?.totals}]}}
						columns={getColumns()}
						disableColumnMenu
						rowCount={dailyStatsResp?._meta.pagination.totalItems || 0}
						disableRowSelectionOnClick
						pagination
						paginationMode="server"
						paginationModel={pagination}
						onPaginationModelChange={newModel => setPagination(newModel)}
						sortingMode="server"
						sortModel={sortModel}
						onSortModelChange={setSortModel}
						pageSizeOptions={config.paginationOptions}
						sx={{
							'& .srWarn': {bgcolor: 'warning.main'},
						}}
						slotProps={{
							toolbar: {
								noSearch: true,
								filters: (
									<>
										<DateRangePicker
											name="dateRange"
											value={filter.dateRange}
											label="Date Range"
											textFieldProps={{sx: {minWidth: 260}, size: 'small'}}
											onChange={(newValue) => {setFilter({...filter, dateRange: newValue})}}
										/>
									</>
								),
							},
						}}
					/>
				</Box>
			)}
		/>
	)
}
