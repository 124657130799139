import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useWrappedQuery } from 'utils/reactQueryHooks';
import { fetchWorkspaces } from 'utils/autoComplete';
import _ from 'lodash';
import * as AuthSelectors from 'modules/auth/selectors';
import { useSelector } from 'react-redux';
import { Dictionary } from 'tr-typescript-library';

export interface Workspace {id: string; name: string; order: number, color: string}
interface WorkspacesContextProps {workspaces: Workspace[], workspacesMap: Dictionary<Workspace>, loading: boolean, selectedWorkspaces: {op: string, ids: string[]}, selectedSingleWS?: string, setSelectedWorkspaces: (workspaces: string) => void}


const selectedWorkspaces = JSON.parse(localStorage.getItem('vnSelectedWorkspaces') || '{"ids": []}');
const selectedSingleWS = selectedWorkspaces.ids.length === 1 ? selectedWorkspaces.ids[0] : undefined;
const WorkspacesContext = createContext<WorkspacesContextProps>({workspaces: [], workspacesMap: {}, loading: true, selectedWorkspaces, selectedSingleWS, setSelectedWorkspaces: _.noop});

export const WorkspacesProvider: React.FC<any> = ({ children }) => {
	const [workspacesProps, setWorkspaceProps] = useState<WorkspacesContextProps>({workspaces: [], workspacesMap: {}, loading: true, selectedWorkspaces, selectedSingleWS, setSelectedWorkspaces: _.noop});
	const isLoggedIn = useSelector(AuthSelectors.isLoggedIn);

	const {isLoading: loading, data: workspacesResp} = useWrappedQuery({
		enabled: !!isLoggedIn,
		queryKey: ['workspaces'],
		queryFn: () => fetchWorkspaces(),
	});

	const setSelectedWorkspaces = useCallback((workspaces) => {
		localStorage.setItem('vnSelectedWorkspaces', JSON.stringify(workspaces))
		const selectedSingleWorkspace = workspaces.ids.length === 1 ? workspaces.ids[0] : undefined;
		setWorkspaceProps({...workspacesProps, selectedWorkspaces: workspaces, selectedSingleWS: selectedSingleWorkspace});
	}, [workspacesProps])

	useEffect(() => {
		setWorkspaceProps({workspaces: workspacesResp, workspacesMap: _.keyBy(workspacesResp, 'id'), loading, selectedWorkspaces, selectedSingleWS, setSelectedWorkspaces: _.noop})
	}, [workspacesResp, loading]);
	
	// Provide the context value to children
	const contextValue: WorkspacesContextProps = {...workspacesProps, setSelectedWorkspaces};

	return <WorkspacesContext.Provider value={contextValue}>{children}</WorkspacesContext.Provider>;
};

// Custom hook to consume the context
export const useWorkspaces = () => {
	const context = useContext(WorkspacesContext);

	if (!context) {
		throw new Error('useApi must be used within an ApiProvider');
	}

	return context;
};
