import axios from 'services/axios';
import _ from 'lodash';
import { toUTC } from 'utils/transformUtils';
import queryString from 'query-string';
import { FetchWorkspaceWeeklyPerformancePayload, UpsertWorkspaceWeeklyPerformancePayload } from './api.interface';

export async function fetchWorkspaceWeeklyPerformance({filter}: FetchWorkspaceWeeklyPerformancePayload) {
	const payload = {
		query: {workspaceWeeklyPerformance: {...filter.workspaceWeeklyPerformance}},
	};


	const query = {
		fromDate: `${toUTC(filter.dateRange[0]).slice(0, 10)}`,
		toDate: `${toUTC(filter.dateRange[1]).slice(0, 10)}`,
		limit: -1,
		offset: 0,
	}
	const queryStr = queryString.stringify(query);
	const url = `/workspaceWeeklyPerformance/query?${queryStr}`;
	const tdpResp = await axios.post(url, payload);

	return tdpResp.data;
}

export async function upsertWorkspaceWeeklyPerformance({performance}: UpsertWorkspaceWeeklyPerformancePayload) {
	const method = performance.id ? axios.patch : axios.post;
	const url = performance.id ? `workspaceWeeklyPerformance/${performance.id}` : `workspaceWeeklyPerformance`;
	const {data} = await method(url, performance);   
	return data;
}

export const formatError = (error) => {
	switch (error?.response?.data?.key) {
	case 'performanceExists':
		return 'This time already exists';
	default:
		return 'Oops. Request failed';
	}
}
