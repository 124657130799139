import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from "react";
import {DialogActions, DialogContent, Button, Box, Alert, Divider, Typography} from '@mui/material';
import Yup from 'services/yup';

// components
import { useIsMobile, useModalState } from "utils/hooks";
import { Formik, Field, FormikProps } from 'formik';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import PromptIfDirty from 'components/PromptIfDirty/PromptIfDirty';
import {
	Add as AddIcon,
	BarChart as BarChartIcon,

} from '@mui/icons-material';
import DraggableDialog from "components/DraggableDialog";
import FormikTextField from 'components/Form/FormikTextField';
import { CopyId } from 'components/CopyId';
import { useMutateArrayItemQuery, useWrappedQuery } from 'utils/reactQueryHooks';
import { fetchSingleOffer, upsertOffer, formatError } from 'modules/offers/api';
import {AsyncAutoCompleteFormik} from 'components/Form/AsyncAutoCompleteFormik';
import { fetchNetworks, fetchSites, fetchTags, fetchWorkspaces, offerStages } from 'utils/autoComplete';
import CountriesSelectFormik from 'components/Form/CountriesSelect/CountriesSelectFormik';
import { publish, useSubscribe } from 'hooks/pubsub';
import { Network } from 'modules/networks/api.interface';
import { Offer } from 'modules/offers/api.interface';
import { useWorkspaces } from 'context/WorkspaceContext';
import { Flex } from 'components/Flex/Flex';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import { DatePickerFormik } from 'components/Form/DatePicker';
import AutoCompleteFormik from 'components/Form/AutoCompleteFormik';
import { CampaignsColumn } from 'pages/playground/campaignsColumn';
import {validations} from 'services/yup';

function getSchema() {
	let schema = Yup.object().shape({
		name: Yup.string().trim().required(),
		url: Yup.string().trim().required().concat(validations.offerUrl),
		networkId: Yup.string().trim().nullable().required(),
		workspaceId: Yup.string().trim().nullable().required(),
		siteId: Yup.string().trim().nullable().required(),
		geo: Yup.string().trim().nullable().required(),
		trafficCapLimit: Yup.number().min(0).required(),
		tagIds: Yup.array().of(Yup.string().trim()),
		date: Yup.date().nullable().required(),
		stage: Yup.string().nullable().required(),
	})
	return schema;
}

const initalValues = {
	name: '', networkId: null, siteId: null, geo: null, workspaceId: null,
	trafficCapLimit: 0, tagIds: [], date: new Date(), stage: 'test',
}


export function CreateEditOffer() {
	const {modalState: offerModal, toggleModal: toggleOfferModal} = useModalState('offer')
	const {setModal: setTagModal} = useModalState('tag')
	const [selectedNetwork, setSelectedNetwork] = useState<Network>();
	const formikRef = useRef<FormikProps<Offer>>(null);
	const {selectedSingleWS} = useWorkspaces();
	const [createTagCtx, setCreateTagCtx] = useState({enabled: true, promptEnabled: true});
	const isMobile = useIsMobile();
	const {setModal: setOfferStatsModal} = useModalState('offerStats')

	const offerQuery = useWrappedQuery({
		enabled: !!offerModal.id,
		queryFn: () => fetchSingleOffer(offerModal.id),
		queryKey: ['offer', offerModal.id],
	})

	const rawOffer = offerQuery.data;
	const offer = (rawOffer && offerModal.reason === 'Duplicate') ? _.omit(rawOffer, ['id']) : rawOffer;
	const isNew = !offer?.id;

	useSubscribe("tagCreated", useCallback((channel, data) => {
		if (createTagCtx.enabled) {
			const currentTags = formikRef.current?.values.tagIds || [];
			const newTags = [...currentTags, data.id]
			formikRef.current?.setFieldValue(`tagIds`, newTags);
			// setValue(v => v + 1);
		}
	}, [createTagCtx]));
	useSubscribe("tagModalClosed", useCallback(() => {
		setCreateTagCtx(val => ({...val, promptEnabled: true}))
	}, []))

	const closeModal = useCallback(function closeModal() {
		toggleOfferModal(false);
	}, [toggleOfferModal]);
	
	useEffect(() => {
		if (!offerModal.open) {
			setCreateTagCtx({enabled: false, promptEnabled: true})
		}
	}, [offerModal.open])

	const handleWorkspaceSelected = useCallback((workspace) => {
		if (offerModal.open && !offerModal.id && workspace) {
			const date = new Date().toISOString().slice(5, 10).replace('-', '.').replace('0', '.')
			fetchTags({tag: {workspaceId: workspace.id, source: "offer", name: {op: 'in', value: ['test', date]}}})
				.then(tags => {
					formikRef.current?.setFieldValue('tagIds', tags.map(t => t.id))
				})
		}
	}, [offerModal.open, offerModal.id])

	const saveOfferQuery = useMutateArrayItemQuery({
		kind: 'upsert',
		mutationFn: (data: any) => upsertOffer({offer: data}),
		queryKey: offer?.id && ['offer', offer.id],
		queryListKey: ['offersList'],
		formatError,
	})
	const resetQuery = saveOfferQuery.reset;
	const fieldsDisabled = offerQuery.isFetching || saveOfferQuery.isLoading
	const submitError = saveOfferQuery.formattedError;
		
	useEffect(() => {
		if (offerModal.open) {
			resetQuery();
		}
		if (!offerModal.open) {
			publish('offerModalClosed');
		}
	}, [offerModal, resetQuery])
    
	function handleFormSubmit(values) {
		const castValues = getSchema().cast({
			...values,
		});
		
		saveOfferQuery.mutateAsync(castValues)
			.then((newOffer) => {
				const channel = values.id ? 'offerUpdated' : 'offerCreated';
				publish(channel, {id: newOffer?.id})
				closeModal()
			})
			.catch(console.error)
	}

	const handleClose = (event, reason) => {
		if (reason === "backdropClick") 
			return;
		closeModal();
	}
	const title = 
		offerQuery.isFetching ?
			'Loading...' :
			offerModal.id ? `${offerModal.reason} Offer ${offer?.name || ''}` : "Create New Offer";
	return (
		<DraggableDialog
			open={offerModal.open}
			onClose={handleClose}
			fullWidth
			title={title}
			maxWidth="md"
			titleId="offer-dialog-title"
			titleSx={{bgcolor: 'primary.main', color: t => t.palette.primary.contrastText}}
		>
			<Formik
				initialValues={_.merge({}, initalValues, {workspaceId: selectedSingleWS}, offer)}
				enableReinitialize
				innerRef={formikRef}
				validationSchema={getSchema()}
				onSubmit={(values, { setSubmitting }) => {
					handleFormSubmit(values);
					setSubmitting(false);
				}}
			>
				{({ submitForm, values, setValues }) => (
					<>
						<DialogContent dividers>
							{!!offer?.id && (
								<Flex justify="space-between" align="center" sx={{mb: 1}}>
									<CopyId id={offer?.id} />
									<TooltipButton
										title="Open Stats"
										icon={BarChartIcon}
										size="small"
										color="primary"
										onClick={() => {
											setOfferStatsModal({open: true, offerId: offer.id, offerName: offer.name})
										}}
									/>
								</Flex>
							)}
							<Box sx={{display: 'flex', gap: 2, flexFlow: 'row wrap'}}>
								<Box component="form" sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 600, flex: 1}}>
									<PromptIfDirty />
									<Box sx={{display: 'flex', gap: 2, flexDirection: {xs: 'column', md: 'row'}}}>
										<Field
											component={FormikTextField}
											name="name"
											type="text"
											label="Offer name"
											disabled={fieldsDisabled}
											size="small"
											sxWrap={{flex: 1}}
										/>
										<CountriesSelectFormik
											noAll
											label="Geo"
											name="geo"
											disabled={fieldsDisabled}
											size="small"
											sx={{flex: 1, maxWidth: {md: 160}}}
										/>
									</Box>
									<Field
										component={FormikTextField}
										name="url"
										type="text"
										label="Offer url"
										disabled={fieldsDisabled}
										size="small"
									/>
									<DatePickerFormik
										name="date"
										label="Offer date"
										disabled={fieldsDisabled}
									/>
									<Field
										component={AutoCompleteFormik}
										name="stage"
										label="Stage"
										options={offerStages}
										disabled={fieldsDisabled}
										sx={{maxWidth: 600}}
									/>
									<AsyncAutoCompleteFormik
										entityName='workspace'
										limit={15}
										getItems={fetchWorkspaces}
										label="Workspace"
										name="workspaceId"
										onChange={(newValue) => {
											setValues({...values, workspaceId: newValue, networkId: null, siteId: null, tagIds: []})
										}}
										onOptionSelected={handleWorkspaceSelected}
										disabled={fieldsDisabled}
										sx={{flex: 1}}
										size="small"
									/>
									<AsyncAutoCompleteFormik
										entityName='network'
										limit={15}
										getItems={fetchNetworks}
										label="Network"
										name="networkId"
										missingDependency={!values.workspaceId}
										onOptionSelected={(network) => {
											setSelectedNetwork(network)
										}}
										where={{network: {workspaceId: values.workspaceId}}}
										disabled={fieldsDisabled || !values.workspaceId}
										sx={{flex: 1}}
										size="small"
									/>
									{selectedNetwork?.kind === 'global' && <Alert severity="warning">Danger! Global network will not go through the website. Don't use with advertiser url!</Alert>}
									<AsyncAutoCompleteFormik
										entityName='site'
										limit={15}
										getItems={fetchSites}
										label="Site"
										name="siteId"
										missingDependency={!values.workspaceId}
										where={{site: {workspaceId: values.workspaceId}}}
										disabled={fieldsDisabled || !values.workspaceId}
										sx={{flex: 1}}
										size="small"
									/>
									<Flex>
										<AsyncAutoCompleteFormik
											label="Tags"
											name="tagIds"
											limit={15}
											entityName='tag'
											getItems={fetchTags}
											missingDependency={!values.workspaceId}
											where={{tag: {workspaceId: values.workspaceId}}}
											disabled={fieldsDisabled || !values.workspaceId}
											size="small"
											fullWidth
											multiple
										/>
										<TooltipButton
											title="Create Tag"
											icon={AddIcon}
											size="medium"
											onClick={() => {
												setCreateTagCtx({enabled: true, promptEnabled: false})
												setTimeout(() => setTagModal({open: true, reason: 'Add'}), 50)
											}}
											sx={{p: 0.5}}
										/>
									</Flex>
									<Field
										component={FormikTextField}
										type="number"
										label="Cap Limit"
										name="trafficCapLimit"
										disabled={fieldsDisabled}
										InputProps={{inputProps: {min: 0}}}
										size="small"
									/>
									{submitError && <Alert severity="error">{submitError}</Alert>}
								</Box>
								{!isNew &&
									<>
										<Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem sx={{...(isMobile && {width: '100%'})}} />
										<Box sx={{width: '350px'}}>
											<Typography variant='h4'>Connected Campaigns</Typography>
											<Box sx={{overflow: 'auto', maxHeight: 600, display: 'flex', flexFlow: 'row wrap', gap: 2, mt: 2}}>
												<CampaignsColumn offerId={offer?.id} campaigns={offer?.connectedCampaigns} direction="column" />
											</Box>
										</Box>
									</>
								}
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={closeModal}>Cancel</Button>
							<ProgressButton
								variant="contained"
								color="primary"
								sxWrap={{alignSelf: 'flex-start'}}
								onClick={submitForm}
								disabled={fieldsDisabled}
								loading={saveOfferQuery.isLoading}
							>
								Save
							</ProgressButton>
						</DialogActions>
					</>
				)}
			</Formik>
		</DraggableDialog>
	)
}
