import { Flex } from "components/Flex/Flex";
import { Chip } from "@mui/material";
import { useModalState } from "utils/hooks";

export interface CampaignsColumnProps {
	campaigns: {id: string, name: string}[],
	direction ?: 'row' | 'column',
	offerId?: string,
}
export function CampaignsColumn({offerId, campaigns, direction = 'row'}: CampaignsColumnProps) {
	const {setModal} = useModalState("campaign");

	if (!campaigns?.length) {return null;}
	return (
		<Flex align="center" justify="flex-start" direction={direction} sx={{height: '100%'}}>
			{campaigns.map(campaign => (
				<Chip
					key={campaign.id}
					size="small"
					sx={{height: 20, px: 0}}
					onClick={() => setModal({
						open: true, id: campaign.id, name: campaign.name, reason: 'Edit',
						selectedOfferId: offerId,
					})}
					label={(
						<Flex gap={0} sx={{'&:not(:hover) button': {opacity: 0.2}}}>
							{campaign.name}
						</Flex>
					)}
				/>
			))}
		</Flex>
	)
}