import _ from 'lodash'
import { useField, useFormikContext } from 'formik';
import CountriesSelect from './CountriesSelect';

export default function CountriesSelectFormik(props) {
	const context = useFormikContext();
	const [field, meta] = useField(props.name);

	return (
		<CountriesSelect
			{...props}
			{...field}
			onChange={val => context.setFieldValue(props.name, val)}
			error={meta.touched && meta.error}
		/>
	);
}
