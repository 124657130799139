import axios from 'services/axios';
import {FetchSitesProps, UpsertSitePayload} from './api.interface';
import queryString from 'query-string';

export async function fetchSites({pagination, filter, sortModel, workspaceIds}: FetchSitesProps) {
	const pageSize = pagination?.pageSize ?? -1;
	const page = pagination?.page ?? 0;
	const offset = pageSize * page;
	const payload = {query: {site: {...filter.site}, search: filter.text}};

	if (workspaceIds?.length) {
		payload.query.site.workspaceId = {op: 'in', value: workspaceIds};
	}

	const orderBy = sortModel?.length ? `site.${sortModel[0].field}` : '';
	const order = sortModel?.length ? sortModel[0].sort : '';
	const query = {
		limit: pageSize,
		offset,
		orderBy,
		order,
	}
	const queryStr = queryString.stringify(query);
	const url = `/sites/query?${queryStr}`;
	const resp = await axios.post(url, payload);

	return resp.data;
}

export async function fetchSingleSite(id: string) {
	const url = `sites/${id}`;
	const {data} = await axios.get(url);   
	return data;
}

export async function upsertSite({site}: UpsertSitePayload) {
	const method = site.id ? axios.patch : axios.post;
	const url = site.id ? `sites/${site.id}` : `sites`;
	const {data} = await method(url, site);   
	return data;
}


export async function deleteSite({id}) {
	const url = `sites/${id}`;
	const {data} = await axios.delete(url);
	return data;
}

export const formatError = (error) => {
	switch (error?.response?.data?.key) {
	case 'nameExists':
		return 'Site name already exists';
	default:
		return 'Oops. Request failed';
	}
}
