import _ from 'lodash';
import { useWorkspaces } from "context/WorkspaceContext";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useWrappedQuery } from "utils/reactQueryHooks";
import { EnhancedDataGrid } from 'components/DataGrid/EnhancedDataGrid';
import { ResponsivePaper } from 'components/ResponsivePaper/ResponsivePaper';
import { GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { Flex } from 'components/Flex/Flex';
import { Box } from '@mui/material';
import { fetchOfferStats } from 'modules/offerStats/api';
import { WorkspaceWeeklyOverview } from './WorkspaceWeeklyOverview';

function getTestLives(data) {
	const {total, lives} = data || {};
	if (!total && !lives) {return ''}
	return `${total || ''} / ${lives || ''}`
}

function aggregateData(data: any[]) {
	const aggregated = data.reduce((acc, g) => {
		acc.tests += g.tests;
		acc.lives += g.lives;
		acc.total += g.total;
		return acc;
	}, {tests: 0, lives: 0, total: 0})
	return aggregated;
}
function getColumns(sites, networks): GridColDef[] {
	return [
		{ field: 'date', headerName: 'Date', width: 100, type: 'string', valueFormatter: (value: any) => value.slice(0, 10)},
		...sites.flatMap((site): GridColDef[] => [
			...networks.map(network => ({
				field: `${site.id}.${network.id}`, headerName: network.name, width: 100, type: 'number',
				renderCell: ({row}) =>  getTestLives(row.data[`${site.id}_${network.id}`]),
			})),
			// {
			// 	field: `${site.id}.totals`, baseField: 'siteTotals', headerName: 'Total Site', width: 100, type: 'number',
			// 	renderCell: ({row}) =>  getTestLives(row.siteDailyTotals?.[site.id]),
			// },
		]),
		{
			field: `totals`, baseField: 'totals', headerName: 'Totals', width: 100, type: 'number',
			renderCell: ({row}) =>  getTestLives(row.totals),
		},
	]
}
const emptyStats = {sites: [], networks: [], stats: [], totals: {date: 'Totals'}}
const sortModel: GridSortModel = [{field: 'date', sort: 'desc'}];

function SingleWorkspaceOfferStats({workspace}) {
	const [dateRange, setDateRange] = useState([subDays(new Date(), 20), subDays(new Date(), 1)] as [Date, Date])
	const [stats, setStats] = useState<any>(emptyStats)
	const {isFetching, data: rawStats, refetch} = useWrappedQuery({
		keepPreviousData: true,
		queryKey: ['fetchOfferStats', workspace.id],
		queryFn: () => fetchOfferStats({workspaceId: workspace.id, dateRange}),
		// initialData: {items: [], pagination: {total: 0}, totals: {}}
	});

	useEffect(() => {
		const items = rawStats?.items || [];
		if (!items.length) {
			setStats(emptyStats);
			return;
		};
		const sites = _.uniqBy(items, (d: any) => d.site.id).map((d: any) => d.site);
		const networks = _.uniqBy(items, (d: any) => d.network.id).map((d: any) => d.network);
		const statsByDate = _.groupBy(items, 'date');
		const totalsBySiteNetwork = _.mapValues(_.groupBy(items, d => `${d.site.id}_${d.network.id}`), aggregateData);
		const totalsBySite = _.mapValues(_.groupBy(items, d => d.site.id), aggregateData);
		const totals = aggregateData(items);
		const stats = Object.values(statsByDate).map(dateGroup => {
			const siteGroup = _.groupBy(dateGroup, 'site.id');
			const totals = aggregateData(dateGroup)
			const siteDailyTotals = _.mapValues(siteGroup, aggregateData);
			return {
				date: dateGroup[0].date,
				data: _.keyBy(dateGroup, g => `${g.site.id}_${g.network.id}`),
				totals,
				siteDailyTotals,
			}
		})
		setStats({stats, sites, networks, totals: {date: 'Totals', data: totalsBySiteNetwork, siteDailyTotals: totalsBySite, totals}});
	}, [rawStats])

	const finalColumns = getColumns(stats.sites, stats.networks);
	return (
		<ResponsivePaper sx={{width: '100%'}}>
			<Box sx={{fontSize: 24, mb: 1}}>{workspace.name}</Box>
			<EnhancedDataGrid
				items={stats.stats}
				pluralName='Stats'
				density='compact'
				rowHeight={34}
				getRowId={(row) => row.date}
				columns={finalColumns}
				hideFooter
				pagination={false}
				pinnedRows={{bottom: [stats.totals]}}
				sortModel={sortModel}
				getCellClassName={(params: any) => {
					const classes = [
						params.colDef?.field, params.colDef?.baseField,
					].filter(f => f)
					return classes.join(' ')
				}}
				columnGroupingModel={stats.sites?.map(site => ({
					groupId: site.name,
					children: [
						...stats.networks.map(network => ({field: `${site.id}.${network.id}`})),
						{field: `${site.id}.totals`},
					],
				}))}
				sx={{
					'& .siteTotals': {borderRight: '1px solid black'},
					'& .MuiDataGrid-pinnedRows': {fontWeight: 'bold'},

				}}
				noSearch
			/>
		</ResponsivePaper>
	);
}

export function WorkspaceOfferStats({filter}) {
	const {workspacesMap} = useWorkspaces();
	if (!filter.workspaces?.length) {
		return (<EmptyState size="medium" isEmpty text="Workspaces not found" sx={{container: {height: '100%'}}} />)
	}

	return (
		<Flex direction="column">
			{filter.workspaces.map(workspaceId => (
				<Flex key={workspaceId} direction="column" sx={{width: '100%'}}>
					<WorkspaceWeeklyOverview workspace={workspacesMap?.[workspaceId]}   />
					<SingleWorkspaceOfferStats key={workspaceId} workspace={workspacesMap?.[workspaceId]} />
				</Flex>
			))}
		</Flex>
	)
}