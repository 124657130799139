import _ from 'lodash'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Autocomplete } from 'formik-mui';
import { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import {
	ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';

export default function AutoCompleteFormik({options, optionKeyProp = 'id', optionLabelProp = 'name', valuesOnly = false, copyIcon = false, textFieldProps = {}, ...props}) {
	const {name} = props.field;
	const [optionsKeys, setOptionsKeys] = useState(valuesOnly ? options : _.map(options, optionKeyProp));
	const [optionsMap, setOptionsMap] = useState(valuesOnly ? options : _.keyBy(options, optionKeyProp));

	useEffect(() => {
		setOptionsKeys(valuesOnly ? options : _.map(options, optionKeyProp));
		setOptionsMap(valuesOnly ? options : _.keyBy(options, optionKeyProp));
	}, [valuesOnly, options, setOptionsKeys, optionKeyProp])

	const error = (_.get(props.form.touched, name) && _.get(props.form.errors, name));

	function onBlur(...args) {
		props.field.onBlur(...args)
		if (!_.get(props.form.touched, name)) {
			props.form.setFieldTouched(name, true)
		}
	}

	return (
		<Autocomplete
			{...props}
			options={optionsKeys}
			autoHighlight
			size="small"
			field={{...props.field, onBlur}}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => {
					const actualValue = valuesOnly ? option : _.get(optionsMap[option], optionLabelProp, '<Invalid>')
					return (
						<Chip
							icon={
								copyIcon
									?
									(<TooltipButton
										sx={{p: 0.2, ml: '10px !important', mr: '-8px !important'}}
										onClick={() => window.navigator.clipboard.writeText(actualValue)}
										title="Copy"
										icon={ContentCopyIcon}
										iconProps={{sx: {fontSize: 15}}}
									/>)
									: undefined
							}
							size={props.size}
							label={`${actualValue}`}
							{...getTagProps({ index })}
					  />
					)
				})
			  }
			getOptionLabel={(value) => valuesOnly ? value : _.get(optionsMap[value], optionLabelProp, '<Invalid>')}
			renderOption={(props, value) => {
				const option = optionsMap[value] || {[optionLabelProp]: '<Invalid>'};
				return (
					(
						<Box component="li"  {...props} key={option[optionKeyProp] || option[optionLabelProp]}>
							{valuesOnly ? value : option[optionLabelProp]}
						</Box>
					)
				)
			}}
			renderInput={(params) => (
				<TextField
					size='small'
					{...params}
					error={!!error}
					helperText={error || props.helperText}
					label={props.label}
					{...textFieldProps}
					inputProps={{
						...params.inputProps,
						autoComplete: 'off', // disable autocomplete and autofill
					}}
				/>
			)}
		/>
	);
}
