import tarkLogo from 'images/logo-tark.svg';
import tarkIcon from 'images/icon-tark.svg';

export function getFlavorConfig(tenant) {
	switch (tenant) {
	case 'tark':
		return {
			primaryColor: '#95054c',
			primaryOverrides: {shade: 65},
			secondaryColor: '#2F80FA',
			primaryContrast: '#fff',
			login: {
				media: {
					kind: 'video',
					src: 'https://tr-prod-public.storage.googleapis.com/client/venus.mp4',
					sx: {
						objectPosition: {xs: 'left', lg: 'initial'},
					},
					props: {
						autoPlay: true,
						muted: true,
						loop: true,
					},
				},
				logoWidthPercent: 80,
				formSx: {
					alignItems: {
						xs: 'center',
						lg: 'flex-end',
					},
					justifyContent: 'flex-end',
					height: '90dvh',
				},
				formWidth: '90%',
				particlesEnabled: false,
			},
			logo: tarkLogo,
			logoIcon: tarkIcon,
			particlesEnabled: true,
		}
	default:
		throw new Error(`Invalid tenant ${tenant}`)
	}
}

// Gabriela top 3
// primaryColor: '#95054c', // darker violet red
// primaryColor: '#950574', // violet red
// primaryColor: '#059560', // dark green


// Roi
// primaryColor: '#95054c', // darker violet red
// primaryColor: '#950574', // violet red
// primaryColor: '#950505', // dark red
