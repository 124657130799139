import React from "react";
import { Button, Box, Typography } from '@mui/material';
import { Helmet } from "react-helmet-async";
import config from "config/config";

// components

export default function PageTitle(props) {
	return (
		<Box sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
			<Helmet><title>{props.title} - {config.appName}</title></Helmet>
			<Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
				{props.icon && React.createElement(props.icon, {...props.iconProps, sx: {fontSize: 40, color: 'text.hint', alignSelf: 'flex-start', mt: 1, ...props.iconProps?.sx}})}
				<Box sx={{display: 'flex', flexDirection: 'column'}}>
					<Typography sx={{color: 'text.hint'}} variant="h1" size="sm">
						{props.title}
					</Typography>
					{props.subtitle && <Typography sx={{color: 'text.hint'}} size="sm">
						{props.subtitle}
					</Typography>}
				</Box>
				{props.titleEnding}
			</Box>
			{props.button && (
				<Button
					sx={{
						boxShadow: t => t.customShadows.widget, textTransform: 'none',
						'&:active': {boxShadow: t => t.customShadows.widgetWide},
					}}
					variant="contained"
					size="large"
					color="secondary"
				>
					{props.button}
				</Button>
			)}
		</Box>
	);
}
