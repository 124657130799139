import _ from 'lodash'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';


function getDate(value) {
	if (!value)	return null;
	if (typeof value === 'string') return new Date(value);
	return value;
}
export function DatePicker({onChange, error = '', value, ...props}) {
	const [date, setDate] = useState(getDate(value));

	useEffect(() => {
		setDate(getDate(value))
	}, [value])

	return (
		<MuiDatePicker
			onChange={onChange}
			value={date}
			slotProps={{textField: {size: 'small', helperText: error, error: !!error, ...props.textFieldProps}}}
			format="dd/MM/yyyy"
			{...props}
		/>
	);
}

export function DatePickerFormik(props) {
	const context = useFormikContext();
	const [field, meta] = useField(props.name);

	return (
		<DatePicker
			{...props}
			{...field}
			onChange={(val, ...args) => {
				if (props.onChange) {
					props.onChange(val, ...args)
				}
				context.setFieldValue(props.name, val)
			}}
			error={meta.touched && meta.error}
		/>
	);
}
