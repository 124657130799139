import {Tooltip, IconButton, Paper, Box, Typography} from '@mui/material';
import {
	Add as AddIcon,
	Edit as EditIcon,
	Language as LanguageIcon,
} from '@mui/icons-material';
import _ from 'lodash';

// components
import PageTitle from "../../components/PageTitle";
import { useAvailableWindowHeight, useModalState, useQueryableFilter } from "utils/hooks";
import config from "config/config";
import { useMutateArrayItemQuery, useWrappedQuery } from "utils/reactQueryHooks";
import { fetchSites, upsertSite, formatError } from "modules/sites/api";
import { EnhancedDataGrid } from "../../components/DataGrid/EnhancedDataGrid";
import { Site } from "modules/sites/api.interface";
import { useWorkspaces } from 'context/WorkspaceContext';
import { useState } from 'react';


function getColumns({renderActionsColumn}) {
	return [
		{ field: 'name', headerName: 'Name', filterable: false, minWidth: 350, flex: 1, renderCell: ({row}) => (
			<Box sx={{display: 'flex', height: '100%', alignItems: 'center', gap: 0.5, whiteSpace: 'nowrap', overflow: 'hidden'}}>
				<Typography variant="body1">{row.name}</Typography>
				<Typography variant="body1" sx={{fontSize: 10}} color="text.subtitle">{row.url}</Typography>
			</Box>
		)},
		{ field: 'comments', headerName: 'Comments', minWidth: 350, flex: 1, editable: true},
		{ field: 'actions', headerName: 'Actions', sortable: false, filterable: false, renderCell: renderActionsColumn, width: 80},
	]
}

function ActionsColumn({row, onEditClick}) {
	return (
		<>
			<Tooltip arrow title="Edit Site">
				<IconButton
					aria-label="Edit Site"
					sx={{p: 0.5}}
					onClick={() => onEditClick(row)}
					size="medium">
					<EditIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
		</>
	)
}

const defaultFilter = {text: ''};
const defaultSortModel = [{field: 'name', sort: 'asc'}]
export function SitesPage() {
	const {filter, pagination, sortModel, setFilter, setPagination, setSortModel} = useQueryableFilter({defaultFilter, defaultPageSize: config.paginationDefaultValue, defaultSortModel});
	const gridHeight = useAvailableWindowHeight()
	const {setModal: setSiteModal} = useModalState('site')
	const {selectedWorkspaces} = useWorkspaces();
	const [inlineSiteId, setInlineSiteId] = useState<string>();

	const {isLoading: isSitesLoading, data: sitesResponse} = useWrappedQuery({
		queryKey: ['sitesList', filter, pagination, sortModel, selectedWorkspaces.ids],
		queryFn: () => fetchSites({
			pagination,
			sortModel: sortModel,
			filter: {text: filter.text},
			workspaceIds: selectedWorkspaces.ids,
		}),
	});

	const saveSiteQuery = useMutateArrayItemQuery({
		kind: 'upsert',
		mutationFn: (data: any) => upsertSite({site: data}),
		queryKey: inlineSiteId ? ['site', inlineSiteId] : [],
		queryListKey: ['sitesList'],
		formatError,
	})


	function openSiteModal(id?: string, reason?: string) {
		setSiteModal({id, open: true, reason})
	}

	function renderActionsColumn(params) {
		return (
			<ActionsColumn row={params.row}
				onEditClick={() => openSiteModal(params.row.id, 'Update')}
			/>
		);
	}

	function handleDoubleCellClick(params) {
		if (!['actions', 'comments'].includes(params.field)) {
			openSiteModal(params.row.id, 'Update')
		}
	}

	function handleUpdatedInline(offer) {
		setInlineSiteId(offer.id);
		saveSiteQuery.mutateAsync(offer)
			.catch(console.error)
	}

	return (
		<>
			<PageTitle title="Sites" icon={LanguageIcon} />
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
				<Paper sx={{height: gridHeight}}>
					<EnhancedDataGrid<Site>
						items={sitesResponse?.items}
						loading={isSitesLoading}
						columns={getColumns({renderActionsColumn})}
						rowCount={sitesResponse?._meta?.total}
						pagination
						paginationMode="server"
						paginationModel={pagination}
						density='compact'
						onPaginationModelChange={newModel => setPagination(newModel)}
						sortingMode="server"
						sortModel={sortModel}
						onSortModelChange={setSortModel}
						pageSizeOptions={config.paginationOptions}
						editMode='cell'
						onProcessRowUpdateError={console.log}
						processRowUpdate={(newRow, oldRow) => {
							const validFields = ['comments'];
							const toUpdate = _.pickBy(newRow, (val, key) => validFields.includes(key) && newRow[key] !== oldRow[key])
							if (!_.isEmpty(toUpdate)) {
								handleUpdatedInline({...toUpdate, id: newRow.id});
							}
							return newRow
						}}
						pluralName="Sites"
						filter={filter}
						setFilter={setFilter}
						showHiddenToggle
						// actionSubmitted={siteJustSubmitted}
						// actionSubmitting={siteSubmitting}
						actions={[{
							icon: AddIcon,
							primary: true,
							hint: 'Add new site',
							onClick: () => openSiteModal(),
						}]}
						onCellDoubleClick={handleDoubleCellClick}
					/>
				</Paper>
			</Box>
		</>
	)
}
