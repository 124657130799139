import React, { useCallback, useState } from "react";
import {
	Grid,
	CircularProgress,
	Typography,
	Button,
	Box,
	TextField,
	Divider,
	Paper,
} from '@mui/material';
import { Alert } from '@mui/material';

import styles from "../styles";

import googleLogo from "images/google-logo.svg";
import { Helmet } from "react-helmet-async";
import config from "config/config";
import Particles from "react-tsparticles";
import { loadFull } from 'tsparticles';
import { fireworksParticles } from "./birthdayParticles";
import { BirthdayParticles } from "./birthdayParticlesComponent";



const ParticlesBackground = () => {
	const particlesInit = useCallback(async(engine) => {
		// you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
		// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
		// starting from v2 you can add only the features you need reducing the bundle size
		await loadFull(engine);
	}, []);

	return (

		<Particles
			init={particlesInit}
			options={fireworksParticles}
		/>
	);
};

export function BirthdayLogin({loading, error, onSubmit, birthdays}) {
	const [username, setUsernameValue] = useState("");
	const [password, setPasswordValue] = useState("");

	return (
		<Grid
			container
			sx={{
				height: '100vh',
				width: '100vw',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				overflow: 'hidden',
				position: 'relative',
			}}
			// onMouseMove={handleMouseMove}
		>
			<BirthdayParticles />
			<Box sx={{background: '#000'}} />
			<Helmet><title>Login - {config.appName}</title></Helmet>
			<Box sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: config.flavorConfig.login.formAlignment,
				width: {
					xs: '100%',
					lg: '60%',
				},
			}}>
				<Paper sx={styles.form} elevation={5}>
					<Box component="img" src={config.flavorConfig.logo} sx={{
						width: `${config.flavorConfig.login.logoWidthPercent}%`,
						margin: 'auto',
						display: 'block',
					}} />
					<Box sx={{mt: 4}}>
						{birthdays.map(b => (
							<Typography variant="h2" sx={{
								fontWeight: 500,
								fontSize: 32,
								textAlign: "center",
								color: '#f651b3',
							}}>
								{b.reason || 'Happy Birthday'} {b.name}!
							</Typography>
						))}
					</Box>
					<Box component="form" onSubmit={(e) => {
						e.preventDefault();
						onSubmit({username, password});
					}}>
						<TextField
							id="username"
							value={username}
							onChange={e => setUsernameValue(e.target.value)}
							margin="normal"
							label="Username"
							fullWidth
						/>
						<TextField
							id="password"
							value={password}
							onChange={e => setPasswordValue(e.target.value)}
							margin="normal"
							label="Password"
							type="password"
							fullWidth
						/>
						<div>
							{loading ? (
								<CircularProgress size={26} sx={styles.loginLoader} />
							) : (
								<Button
									disabled={
										username.length === 0 || password.length === 0
									}
									type="submit"
									variant="contained"
									color="primary"
									size="large"
									fullWidth
									sx={{mt: 2}}
									onTouchStart={e => e.stopPropagation()}
									onTouchEnd={e => e.stopPropagation()}
									onTouchMove={e => e.stopPropagation()}
								>
									Login
								</Button>
							)}
						</div>
					</Box>
					<Divider sx={{mt: 2}}>OR</Divider>
					<Box component="form" method="post" action="/api/auth/login/oidc-google/start?app=tr" sx={{mt: 2}}>
						<Button type="submit" fullWidth size="large" variant="outlined" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2}}>
							<Box component="img" src={googleLogo} />
							Sign In With Google
						</Button>
					</Box>
					{error && (<Alert sx={{mt: 4}} severity="error">{error}</Alert>)}
				</Paper>
			</Box>
		</Grid>
	);
}
