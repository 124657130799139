import { fetchTrafficHourlyPerformance } from "modules/trafficHourlyPerformance/api";
import { useWrappedQuery } from "utils/reactQueryHooks";
import {startOfDay, startOfMonth} from 'date-fns';
import _ from 'lodash'
import { Box, Paper } from "@mui/material";
import { Flex } from "components/Flex/Flex";
import { formatNum } from "utils/transformUtils";
import {GridSortModel} from '@mui/x-data-grid-pro';

const columns = ['workspace', 'profit'];
const now = new Date();
const date = [startOfMonth(now), startOfDay(now)]
const sortModel: GridSortModel = [{field: 'profit', sort: 'desc'}]
export function MonthlyProfitPanel({filter}) {

	const {isLoading, data: statsResp} = useWrappedQuery({
		queryKey: ['MonthlyProfitPanel', filter.workspaces],
		queryFn: () => fetchTrafficHourlyPerformance({
			columns,
			sortModel,
			filter: {
				dateRange: date,
			},
		}),
	});
	
	return (
		<Flex direction="column" align="flex-start">
			{statsResp?.items?.map(stat => {
				return (
					<Paper key={stat.workspace.id} sx={{px: 2, py: 1, display: 'inline-block'}}>
						<Box component="div" sx={{color: stat.workspace.color, fontWeight: 'bold', mr: 1, fontSize: 20, display: 'inline-block', width: 50}}>{stat.workspace.name}</Box>
						<Box component="span" sx={{fontWeight: 'bold'}}>${formatNum((stat?.profit || 0) * 0.6, 0)}</Box>
					</Paper>
				)
			})}
		</Flex>
	)
}