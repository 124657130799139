import {put, takeLatest, call} from 'redux-saga/effects';
import {Actions} from 'modules/ads/actions';
import axios from 'services/axios';
import {ActionTypes as PagesActions} from 'pages/actions';
import _ from 'lodash';
import { toUTC } from 'utils/transformUtils';
import { endOfDay, startOfDay } from 'date-fns';
import {stringify as qsStringify} from 'query-string';


function* fetchAdsPerformance(action) {
	yield put(Actions.FETCH_ADS_PERFORMANCE_REQUEST());
	try {
		const {pagination, filter, sortModel, campaignId} = action.payload;
		const {text, dateRange} = filter;
		
		const offset = pagination.pageSize * pagination.page;
		const dateFrom = toUTC(startOfDay(new Date(dateRange[0])));
		const dateTo = toUTC(endOfDay(new Date(dateRange[1])));
		const orderBy = sortModel.length ? sortModel[0].field : '';
		const order = sortModel.length ? sortModel[0].sort : '';
		const params = {
			orderBy, order,
			search: text,
			dateFrom,
			dateTo,
			offset, limit: pagination.pageSize,
			trafficSourceId: filter.trafficSourceId,
			tsAccountIds: filter.tsAccountId?.length ? filter.tsAccountId : null,
		}

		const query = qsStringify(params, {arrayFormat: 'bracket', skipEmptyString: true, skipNull: true, sort: (a, b) => order.indexOf(a) - order.indexOf(b)})
		const {data} = yield call(axios.get, `campaigns/${campaignId}/ads/performance?${query}`);
        
		yield put(Actions.FETCH_ADS_PERFORMANCE_SUCCESS(data));
	} catch (err) {
		console.error(err);
		yield put(Actions.FETCH_ADS_PERFORMANCE_FAILURE(err));
	}
}

function* submitAdForm(action) {
	yield put(Actions.AD_FORM_SUBMIT_REQUEST());
	try {
		const {payload} = action;
		const adId = payload.id;
		
		const fields = _.pick(payload, ['name', 'slug', 'fallbackFeedId', 'protectedMediaSampleRate', 'apiKey', 'allowedHostIds', 'color', 'overrides']);
		const method = adId ? axios.patch : axios.post;
		const url = adId ? `ads/${adId}` : `ads`;
		const result = yield call(method, url, fields);

		yield put(Actions.AD_FORM_SUBMIT_SUCCESS(result.data, {isNew: !adId}));
	} catch (err) {
		yield put(Actions.AD_FORM_SUBMIT_FAILURE(err));
	}
}

const sagas = [
	takeLatest(PagesActions.ADS_FILTERING_CHANGED, fetchAdsPerformance),
	takeLatest(PagesActions.AD_FORM_SUBMITTED, submitAdForm),
]
export default sagas;
