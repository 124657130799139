import {connect} from 'utils/reduxUtils';
import Component from './Header';
import * as AuthSelectors from 'modules/auth/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
	onLogout: Actions.HEADER_LOGOUT_REQUESTED,
};

const mapStateToProps = state => {
	return {
		user: AuthSelectors.user(state),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
