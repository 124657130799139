import {
	WarningAmber as WarningAmberIcon,
} from '@mui/icons-material';
import _ from 'lodash'

// components

import { useWrappedQuery } from 'utils/reactQueryHooks';
import { getNonProfitableLiveOffers, getNonActiveLive, getEmptyLiveCampaigns, getTestToLiveOffers } from 'modules/alerts/api';
import Grid from '@mui/material/Unstable_Grid2';
import PageTitle from 'components/PageTitle/PageTitle';
import { AlertGrid } from './components/alertGrid';
import { Box, Chip, Paper, Skeleton } from '@mui/material';
import AutoComplete from 'components/Form/AutoComplete';
import { useWorkspaces } from 'context/WorkspaceContext';
import { useEffect, useState } from 'react';
import { formatNum } from 'utils/transformUtils';
import { useModalState } from 'utils/hooks';

function AlertsFilter({filter, setFilter}) {
	const {workspaces, workspacesMap} = useWorkspaces();

	return (
		<Box sx={{display: 'flex', alignSelf: 'flex-start', gap: 2, mt: 1}}>
			<Paper>
				<AutoComplete
					label="Workspaces"
					options={workspaces}
					value={filter.workspaces}
					onChange={(_e, newVal) => setFilter({...filter, workspaces: _.orderBy(newVal, i => workspacesMap[i]?.order)})}
					size="small"
					multiple
					textFieldProps={{sx: {width: 260}, size: 'small'}}
				/>
			</Paper>
		</Box>
	)
}

export function AlertsPage() {
	const {workspaces, workspacesMap, selectedWorkspaces} = useWorkspaces();
	const {setModal: setOfferModal} = useModalState('offer')
	const {setModal: setCampaignModal} = useModalState('campaign')

	const [filter, setFilter] = useState({
		workspaces: [] as string[],
	})
	const nonProfitableLiveOffersQuery = useWrappedQuery({
		queryKey: ['nonProfitableLiveOffers', filter],
		queryFn: () => getNonProfitableLiveOffers({workspaceIds: filter.workspaces}),
	})
	const nonActiveLiveQuery = useWrappedQuery({
		queryKey: ['nonActiveLive', filter],
		queryFn: () => getNonActiveLive({workspaceIds: filter.workspaces}),
	})
	const emptyLiveCampaignsQuery = useWrappedQuery({
		queryKey: ['emptyLiveCampaigns', filter],
		queryFn: () => getEmptyLiveCampaigns({workspaceIds: filter.workspaces}),
	})
	const testToLiveOffersQuery = useWrappedQuery({
		queryKey: ['testToLiveOffers', filter],
		queryFn: () => getTestToLiveOffers({workspaceIds: filter.workspaces}),
	})

	
	useEffect(() => {
		const updatedSelected = selectedWorkspaces?.ids?.length ? selectedWorkspaces.ids : workspaces?.map(w => w.id)
		setFilter(filter => ({...filter, workspaces: updatedSelected || []}))
	}, [selectedWorkspaces, workspaces])
	
	if (!workspaces?.length) {return (
		<Skeleton sx={{height: 120, transform: 'none'}} />
	)}

	return (
		<>	
			<PageTitle title="Alerts" icon={WarningAmberIcon} />
			<AlertsFilter filter={filter} setFilter={setFilter} />
			<Grid container spacing={2} sx={{mt: 1}}>
				<Grid xs={12} md={6}>
					<AlertGrid
						title="Non Profitable Live Offers"
						getRowId={(row) => row.offer.id}
						columns={[
							{field: 'workspace', headerName: 'Workspace', valueGetter: (_, row) => workspacesMap?.[row.offer?.workspaceId]?.name},
							{field: 'offerName', headerName: 'Offer', flex: 2, valueGetter: (_, row) => row.offer.name, renderCell: ({row}) => <Chip size="small" label={row.offer.name} onClick={() => setOfferModal({open: true, id: row.offer.id, reason: 'Edit'})} />},
							{field: 'roi', headerName: 'ROI'},
							{field: 'roi3Days', headerName: 'ROI 3 Days'},
							{field: 'dailyVisits', headerName: 'Today Visits', valueGetter: (_, row) => row.offer?.activityStatus?.today?.visits},
						]}
						query={nonProfitableLiveOffersQuery}
					/>
				</Grid>
				<Grid xs={12} md={6}>
					<AlertGrid
						title="Non Active Live Offers"
						getRowId={(row) => row.offer.id}
						columns={[
							{field: 'workspace', headerName: 'Workspace', valueGetter: (_, row) => workspacesMap?.[row.offer?.workspaceId]?.name},
							{field: 'offerName', headerName: 'Offer', flex: 2, valueGetter: (_, row) => row.offer.name, renderCell: ({row}) => <Chip size="small" label={row.offer.name} onClick={() => setOfferModal({open: true, id: row.offer.id, reason: 'Edit'})} />},
						]}
						query={nonActiveLiveQuery}
					/>
				</Grid>
				<Grid xs={12} md={6}>
					<AlertGrid
						title="Empty Active Campaigns"
						getRowId={(row) => row.campaign.id}
						columns={[
							{field: 'workspace', headerName: 'Workspace', valueGetter: (_, row) => workspacesMap?.[row.campaign?.workspaceId]?.name},
							{field: 'campaign', headerName: 'Campaign', flex: 2, valueGetter: (_, row) => row.campaign.name, renderCell: ({row}) => <Chip size="small" label={row.campaign.name} onClick={() => setCampaignModal({open: true, id: row.campaign.id, reason: 'Edit'})} />},
							{field: 'dailyVisits', headerName: 'Today Visits', valueGetter: (_, row) => row.campaign.activityStatus?.today?.visits},
						]}
						query={emptyLiveCampaignsQuery}
					/>
				</Grid>
				<Grid xs={12} md={6}>
					<AlertGrid
						title="Tests to live offers"
						getRowId={(row) => row.offer.id}
						columns={[
							{field: 'workspace', headerName: 'Workspace', valueGetter: (_, row) => workspacesMap?.[row.offer?.workspaceId]?.name},
							{field: 'offerName', headerName: 'Offer', flex: 2, valueGetter: (_, row) => row.offer.name, renderCell: ({row}) => <Chip size="small" label={row.offer.name} onClick={() => setOfferModal({open: true, id: row.offer.id, reason: 'Edit'})} />},							{field: 'dailyVisits', headerName: 'Today Visits', valueGetter: (_, row) => row.offer.activityStatus?.today?.visits},
							{field: 'roi', headerName: 'ROI', valueFormatter: (value) => `${formatNum(value, 1)}%`},
						]}
						query={testToLiveOffersQuery}
					/>
				</Grid>
			</Grid>
		</>
	)
}
