import _ from 'lodash';
import {
	Button, DialogActions, DialogContent,
	DialogContentText, Alert, DialogProps,
} from '@mui/material';

import styles from './styles';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import DraggableDialog from 'components/DraggableDialog';

export interface ConfirmDialogProps {
	open?: boolean,
	variant?: 'primary' | 'error',
	onClose?: () => void,
	onCancel?: () => void,
	onConfirm?: () => void,
	loading?: boolean,
	confirmDisabled?: boolean
	title?: string
	subtitle?: string
	confirmText?: string
	cancelText?: string | null
	customContent?: any,
	alert?: any
}
export default function ConfirmDialog(props: ConfirmDialogProps & Omit<DialogProps, 'open'>) {
	const {open = false, variant = 'primary', onClose = null, loading, title, subtitle = "", customContent, onConfirm, onCancel = _.noop, confirmText, confirmDisabled = false, cancelText = 'Cancel', alert, ...restProps} = props
	const isError = variant === 'error';
	const titleStyle = {...styles.dialogTitle, ...(isError ? styles.bgError : {})}
	// const draggableHandle = useRef(null);
	const handleClose = (event, reason) => {
		if (reason === "backdropClick") 
			return;
		if (onClose) {onClose()}
		else if (onCancel) {onCancel()}
	}
	return (
		<DraggableDialog
			{...restProps}
			open={open}
			onClose={handleClose}
			disableEscapeKeyDown={loading}
			title={title}
			titleId="cofirm-dialog-title"
			titleSx={titleStyle}
		>
			<DialogContent sx={styles.dialogContent}>
				{subtitle && <DialogContentText id="cofirm-dialog-description">
					{subtitle}
				</DialogContentText>}
				{customContent}
				{alert && <Alert sx={{mt: 2, whiteSpace: 'pre-wrap'}} severity="error">{alert}</Alert>}
			</DialogContent>
			<DialogActions>
				{cancelText && <Button onClick={onCancel} autoFocus color={isError ? 'error' : 'primary'}>
					{cancelText}
				</Button>}
				<ProgressButton
					type="submit"
					variant="contained"
					color={isError ? 'error' : 'primary'}
					autoFocus
					sxWrap={{alignSelf: 'flex-start'}}
					onClick={onConfirm}
					disabled={!!confirmDisabled}
					loading={loading}
				>
					{confirmText}
				</ProgressButton>
			</DialogActions>
		</DraggableDialog>
	);
}
