import axios from 'services/axios';
import {FetchNetworksProps, UpsertNetworkPayload} from './api.interface';
import queryString from 'query-string';

export async function fetchNetworks({pagination, filter, sortModel, workspaceIds}: FetchNetworksProps) {
	const pageSize = pagination?.pageSize ?? -1;
	const page = pagination?.page ?? 0;
	const offset = pageSize * page;
	const payload = {query: {network: {...filter.network}, search: filter.text}};

	if (workspaceIds?.length) {
		payload.query.network.workspaceId = {op: 'in', value: workspaceIds};
	}

	const orderBy = sortModel?.length ? `network.${sortModel[0].field}` : '';
	const order = sortModel?.length ? sortModel[0].sort : '';
	const query = {
		limit: pageSize,
		offset,
		orderBy,
		order,
	}
	const queryStr = queryString.stringify(query);
	const url = `/networks/query?${queryStr}`;
	const resp = await axios.post(url, payload);

	return resp.data;
}

export async function fetchSingleNetwork(id: string) {
	const url = `networks/${id}`;
	const {data} = await axios.get(url);   
	return data;
}

export async function upsertNetwork({network}: UpsertNetworkPayload) {
	const method = network.id ? axios.patch : axios.post;
	const url = network.id ? `networks/${network.id}` : `networks`;
	const {data} = await method(url, network);   
	return data;
}


export async function deleteNetwork({id}) {
	const url = `networks/${id}`;
	const {data} = await axios.delete(url);
	return data;
}

export const formatError = (error) => {
	switch (error?.response?.data?.key) {
	default:
		return 'Oops. Request failed';
	}
}
