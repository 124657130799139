import {Box} from '@mui/material';

export function CountryFlag({country}) {
	if (!country) {return null;}
	return (
		<Box
			sx={{mr: 1}}
			component="img"
			loading="lazy"
			width="20"
			src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`}
			srcSet={`https://flagcdn.com/w40/${country.toLowerCase()}.png 2x`}
			alt={country}
			title={country}
		/>
	);
}
