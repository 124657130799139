import _ from 'lodash';
import { Box, Checkbox } from "@mui/material"
import ConfirmDialog from "components/ConfirmDialog"
import { Field, Formik, FormikProps } from "formik"
import { useEffect, useRef, useState } from "react"
import FormikTextField from 'components/Form/FormikTextField';
import Yup, { validations } from 'services/yup';
import AutoCompleteFormik from './Form/AutoCompleteFormik';
import { offerStages } from 'utils/autoComplete';
import { Offer } from 'modules/offers/api.interface';

interface BulkUpdateOffersModalProps {
    open: boolean, loading: boolean,
    onSubmit: (updates: Partial<Offer>) => void
    onClose: () => void
    error?: string
}
const defaultEnabledValues = {trafficCapLimit: false, stage: false}

const schemaValidation = {
	stage: Yup.string().nullable().required(),
	trafficCapLimit: validations.nullableNum.required(),
}
export function BulkUpdateOffersModal(props: BulkUpdateOffersModalProps) {
	const [enabledValues, setEnabledValues] = useState(defaultEnabledValues)
	const [activeSchema, setActiveSchema] = useState(Yup.object())

	const formikRef = useRef<FormikProps<Partial<Offer>>>(null);
	useEffect(() => {
		setEnabledValues(defaultEnabledValues)
		if (formikRef.current) {formikRef.current.resetForm()}
	}, [props.open])

	useEffect(() => {
		const activeKeys = Object.keys(enabledValues).filter(key => enabledValues[key])
		setActiveSchema(Yup.object().shape(_.pick(schemaValidation, activeKeys)))
	}, [enabledValues])
	return (
		<Formik<Partial<Offer>>
			initialValues={{trafficCapLimit: null, stage: null}}
			enableReinitialize
			validationSchema={activeSchema}
			innerRef={formikRef}
			onSubmit={(values, { setSubmitting }) => {
				const updates = {
					...(enabledValues.trafficCapLimit && _.pick(values, ['trafficCapLimit'])),
					...(enabledValues.stage && _.pick(values, ['stage'])),
				}
				props.onSubmit(updates);
				setSubmitting(false);
			}}
		>
			{({ submitForm, setFieldValue }) => (
				<ConfirmDialog
					open={props.open}
					title="Bulk update offers"
					confirmText="Update"
					onConfirm={submitForm}
					onCancel={props.onClose}
					loading={props.loading}
					alert={props.error}
					customContent={(
						<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
							<Box sx={{display: 'flex', gap: 2}}>
								<Checkbox
									disabled={props.loading}
									value={enabledValues.trafficCapLimit}
									onChange={(e, checked) => {
										setEnabledValues({...enabledValues, trafficCapLimit: checked})
										setFieldValue('trafficCapLimit', null)
									}}
								/>
								<Field
									component={FormikTextField}
									name="trafficCapLimit"
									type="number"
									label="Traffic Cap Limit"
									size="small"
									sxWrap={{flex: 1}}
									fullWidth
									disabled={props.loading || !enabledValues.trafficCapLimit}
								/>
							</Box>
							<Box sx={{display: 'flex', gap: 2}}>
								<Checkbox
									disabled={props.loading}
									value={enabledValues.stage}
									onChange={(e, checked) => {
										setEnabledValues({...enabledValues, stage: checked})
										setFieldValue('stage', null)
									}}
								/>
								<Field
									component={AutoCompleteFormik}
									name="stage"
									label="Stage"
									options={offerStages}
									sx={{flex: 1, minWidth: 160}}
									disabled={props.loading || !enabledValues.stage}
								/>
							</Box>
						</Box>
					)}
				/>
			)}
		</Formik>
	)
}
