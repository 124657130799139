import {createSelector} from 'reselect';
import queryString from 'query-string';

export const pathnameSelector = state => {
	const statePathname = state.router.location && state.router.location.pathname;
	return statePathname || window.location.pathname;
}

export const fullPathSelector = createSelector(pathnameSelector,
	(path) => {
		return `${window.location.origin}${path}`;
	})


export const querySelector = state => {
	const {search} = state.router.location || {};
	const parsedQuery = queryString.parse(search);
	return parsedQuery;
}

export const baseSelector = () => {
	return window.location.origin;
}
