import React, { useState } from "react";
import {
	AppBar as MuiAppBar,
	Toolbar,
	IconButton,
	Menu,
	MenuItem,
	Box,
	Typography,
	Paper,
	CircularProgress,
} from '@mui/material';
import {
	Menu as MenuIcon,
	Person as AccountIcon,
	MenuOpen as MenuOpenIcon,
	ExitToApp as ExitToAppIcon,
	Refresh as RefreshIcon,
	PublishedWithChanges as PublishedWithChangesIcon,
} from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import TooltipButton from 'components/TooltipButton';
import {useDispatch, useSelector} from 'react-redux';
import config from 'config/config';
import { GlobalSearch } from "components/GlobalSearch/GlobalSearch";

// styles
import styles from "./styles";

// context
import {
	useLayoutState,
	useLayoutDispatch,
	toggleSidebar,
	drawerWidth,
} from "../../context/LayoutContext";
import {Actions} from 'pages/actions';
import * as SettingsSelectors from 'modules/settings/selectors'
import { headerHeight } from "utils/hooks";



const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

export default function Header(props) {
	// global
	const layoutState = useLayoutState();
	const layoutDispatch = useLayoutDispatch();
	const [profileMenu, setProfileMenu] = useState(null);
	const globalSettings = useSelector(SettingsSelectors.globalSettings)
	// const isMobile = useIsMobile();
	// const navigate = useNavigate();
	const dispatch = useDispatch();
	const shouldWarn = config.isDebug && globalSettings?.env?.isProd;

	function handleMenuArrowClick() {
		toggleSidebar(layoutDispatch)
		// if (isMobile) {
		// } else {
		// 	navigate(-1);
		// }
	}

	function handleRefreshActivityClicked(consolidateEnabled, consolidateDateRange) {
		dispatch(Actions.HEADER_REFRESH_ACTIVITY_STAT_REQUESTED({consolidateEnabled, consolidateDateRange}))
	}
	return (
		<AppBar position="fixed" sx={{height: headerHeight}} variant="dense">
			<Toolbar sx={{height: headerHeight, minHeight: `${headerHeight}px!important`}}>
				<IconButton
					color="inherit"
					onClick={handleMenuArrowClick}
					sx={{
						padding: 0.5,
						...styles.headerMenuButtonCollapse,
					}}
					size="large">
					{layoutState.isSidebarOpened ? (
						<MenuOpenIcon
							sx={{
								...styles.headerIcon,
								...styles.headerIconCollapse,
							}}
						/>
					) : (
						<MenuIcon
							sx={{
								...styles.headerIcon,
								...styles.headerIconCollapse,
							}}
						/>
					)}
				</IconButton>
				<Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', alignItems: 'center', gap: 1}}>
					{shouldWarn && <Typography fontWeight="bold" fontSize={24}>PROD</Typography>}
					<Paper sx={{maxWidth: 400, flex: 1}}>
						<GlobalSearch />
					</Paper>
					{shouldWarn && <Typography fontWeight="bold" fontSize={24}>PROD</Typography>}
				</Box>
				<Box sx={{display: 'flex', gap: {xs: 0, md: 1}}}>
					<IconButton
						aria-haspopup="true"
						sx={{color: 'primary.contrastText', p: 0.5}}
						aria-controls="profile-menu"
						onClick={e => setProfileMenu(e.currentTarget)}
						size="large">
						<AccountIcon sx={styles.headerIcon} />
					</IconButton>
				</Box>
				<Menu
					id="profile-menu"
					open={Boolean(profileMenu)}
					anchorEl={profileMenu}
					onClose={() => setProfileMenu(null)}
					sx={styles.headerMenu}
					// classes={{ paper: classes.profileMenu }}
					disableAutoFocusItem
				>
					<Box sx={styles.profileMenuUser}>
						<Typography variant="h4" weight="medium">
							{props.user.name}
						</Typography>
						{/* <Typography
							sx={styles.profileMenuLink}
							component="a"
							color="primary"
							href="https://example.com"
							target="_blank"
						>
							example.com
						</Typography> */}
					</Box>
					<MenuItem
						onClick={props.onLogout}
						sx={{
							...styles.headerMenuItem,
							...styles.logoutMenuItem,
						}}
					>
						<ExitToAppIcon sx={styles.profileMenuIcon} /> Log Out
					</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
}
