import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
	'FETCH_AD_GROUPS_REQUEST',
	'FETCH_AD_GROUPS_SUCCESS',
	'FETCH_AD_GROUPS_FAILURE',

	'FETCH_AD_GROUPS_PERFORMANCE_REQUEST',
	'FETCH_AD_GROUPS_PERFORMANCE_SUCCESS',
	'FETCH_AD_GROUPS_PERFORMANCE_FAILURE',

	'FETCH_SINGLE_AD_GROUP_REQUEST',
	'FETCH_SINGLE_AD_GROUP_SUCCESS',
	'FETCH_SINGLE_AD_GROUP_FAILURE',
    
	'AD_GROUP_FORM_SUBMIT_REQUEST',
	'AD_GROUP_FORM_SUBMIT_SUCCESS',
	'AD_GROUP_FORM_SUBMIT_FAILURE',
    
	'FETCH_AD_GROUPS_TOTALS_REQUEST',
	'FETCH_AD_GROUPS_TOTALS_SUCCESS',
	'FETCH_AD_GROUPS_TOTALS_FAILURE',
    
	'AD_GROUPS_UPDATE_STATUS_REQUEST',
	'AD_GROUPS_UPDATE_STATUS_SUCCESS',
	'AD_GROUPS_UPDATE_STATUS_FAILURE',
    
	'AD_GROUPS_DOWNLOAD_REPORT_REQUEST',
	'AD_GROUPS_DOWNLOAD_REPORT_SUCCESS',
	'AD_GROUPS_DOWNLOAD_REPORT_FAILURE',

	'FETCH_SINGLE_AD_GROUP_PERFORMANCE_REQUEST',
	'FETCH_SINGLE_AD_GROUP_PERFORMANCE_SUCCESS',
	'FETCH_SINGLE_AD_GROUP_PERFORMANCE_FAILURE',

	'FETCH_AD_GROUPS_PERFORMANCE_COMPARE_REQUEST',
	'FETCH_AD_GROUPS_PERFORMANCE_COMPARE_SUCCESS',
	'FETCH_AD_GROUPS_PERFORMANCE_COMPARE_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
