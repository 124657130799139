import {put, takeLatest, call} from 'redux-saga/effects';
import {Actions} from 'modules/settings/actions';
import axios from 'services/axios';
import {ActionTypes as AuthActionTypes} from 'modules/auth/actions';
import _ from 'lodash';


export function* fetchGlobalSettings() {
	try {
		yield put(Actions.FETCH_GLOBAL_SETTINGS_REQUEST());
		const resp = yield call(axios.get, 'settings/global');
		
		yield put(Actions.FETCH_GLOBAL_SETTINGS_SUCCESS(resp.data));
	} catch (err) {
		console.error(err)
		yield put(Actions.FETCH_GLOBAL_SETTINGS_FAILURE(err));
	}
}


const sagas = [
	takeLatest(AuthActionTypes.LOGIN_SUCCESS, fetchGlobalSettings),

]
export default sagas;
