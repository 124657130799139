import {
	Route,
	Routes,
} from "react-router-dom";
import {Box} from '@mui/material';

// styles
import style from "./styles";

// components
import Header from "components/Header";
import Sidebar from "components/Sidebar";

// pages
import {OffersPage} from "pages/offers";
import { CampaignsPage } from "pages/campaigns/CampaignsPage";
import { NetworksPage } from "pages/networks/NetworksPage";
import { TrafficSourcesPage } from "pages/trafficSources/TrafficSourcesPage";
import { PlaygroundPage } from "pages/playground/PlaygroundPage";
import { ConversionsPage } from "pages/conversions/ConversionsPage";
import { SitesPage } from "pages/sites/SitesPage";
import { WorkspacesPage } from "pages/workspaces/WorkspacesPage";
import { TagsPage } from "pages/tags/TagsPage";
import { DashboardPage } from "pages/dashboard/DashboardPage";
import { AlertsPage } from "pages/alerts/AlertsPage";
import Error from "pages/error";
import * as AuthSelectors from 'modules/auth/selectors';

// context
import { drawerWidth, useLayoutState } from "../../context/LayoutContext";
import { AutoModals } from "./AutoModals";
import { useHeaderHeight } from "utils/hooks";
import { useSelector } from "react-redux";

const adminRoles = ['superAdmin'];
const routes = [
	{path: "dashboard", element: <DashboardPage />, roles: adminRoles},
	{path: "alerts", element: <AlertsPage />, roles: adminRoles},
	{path: "reports", element: <PlaygroundPage />, roles: adminRoles},
	{path: "conversions", element: <ConversionsPage />, roles: adminRoles},
	{path: "trafficSources", element: <TrafficSourcesPage />, roles: adminRoles},
	{path: "campaigns", element: <CampaignsPage />},
	{path: "offers", element: <OffersPage />},
	{path: "sites", element: <SitesPage />, roles: adminRoles},
	{path: "networks", element: <NetworksPage />, roles: adminRoles},
	{path: "workspaces", element: <WorkspacesPage />, roles: adminRoles},
	{path: "tags", element: <TagsPage />, roles: adminRoles},
]
function Layout() {
	// global
	var layoutState = useLayoutState();
	const {nonHeaderHeight, headerHeight} = useHeaderHeight();
	const user = useSelector(AuthSelectors.user);
	
	return (
		<Box sx={{
			display: "flex",
			maxWidth: "100vw",
			overflowX: "hidden",
			pt: `${headerHeight}px`,
		}}>
			<>
				<Header />
				<Sidebar />
				<Box
					id="main-root"
					sx={{
						flexGrow: 1,
						p: {xs: 0.5, md: 1},
						width: `calc(100vw - ${drawerWidth}px)`,
						height: nonHeaderHeight,
						overflow: 'auto',
						...(layoutState.isSidebarOpened ? style.contentShift : {}),
					}}
				>
					<AutoModals />
					<Routes>
						{routes.filter(r => !r.roles || r.roles.includes(user?.primaryRole)).map(route => (
							<Route key={route.path} path={route.path} element={route.element} />
						))}
						<Route path="*" element={<Error />} />
					</Routes>
				</Box>
			</>
		</Box>
	);
}

export default Layout;
