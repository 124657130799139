import {stringify as csvStringify} from 'csv-stringify/browser/esm';

export const fileToBase64 = async(file) => {
	if (!file) {throw new Error(`Missing file`)};

	return new Promise(res => {
		const reader = new FileReader();
		reader.onloadend = () => {
			res(reader.result)
		};
		reader.readAsDataURL(file);
	});
}

export const createCSV = (data, options) => {
	return new Promise((resolve, reject) => {
		csvStringify(data, {...options, header: true, cast: {
			string: str => {
				if ([`=`, `+`, `-`, `@`, `\t`, `\r`].includes(str[0])) {
					return `'${str}`;
				}
				return str;
			},
		}}, (err, csvStr) => {
			if (err) {
				return reject(err);
			}
			resolve(csvStr);
		});
	})
}
