import _ from 'lodash'
import Box from '@mui/material/Box';
import {TextField, Autocomplete, Tooltip} from '@mui/material';
import { countriesMap, countriesCodes, countriesCodesNoAll } from 'utils/autoComplete';

export default function CountriesSelect({name, label, error = null, onChange, textFieldProps = {}, noAll = false, ...props}) {
	return (
		<Autocomplete
			{...props}
			options={noAll ? countriesCodesNoAll : countriesCodes}
			autoHighlight
			disableCloseOnSelect={!!props.multiple}
			getOptionLabel={(value) => _.get(countriesMap[value], 'code', '<Invalid>')}
			onChange={(e, val) => onChange(val)}
			renderOption={(props, value) => {
				const option = countriesMap[value] || {label: '<Invalid>'};
				return (
					(
						<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
							{!option.noFlag && 
								<img
									loading="lazy"
									width="20"
									src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
									srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
									alt=""
								/>}
							<Tooltip title={option.label} sx={{}} placement='right'>
								<Box>{option.code}</Box>
							</Tooltip>
						</Box>
					)
				)
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					{...textFieldProps}
					name={name}
					error={!!error}
					helperText={error || props.helperText}
					label={label || "Choose a country"}
					inputProps={{
						...params.inputProps,
						autoComplete: 'off', // disable autocomplete and autofill
					}}
				/>
			)}
		/>
	);
}
